<template v-if="isLoggedIn">
    <div class="container-fluid page">

        <div class="card card-default" style="min-width:420px">
            <div class="card-header">
                <div class="row">
                    <div class="col-sm-6 font-weight-bold">
                        <h5>Award Nominatons Report</h5>
                    </div>

                    <div class="col-sm-3">

                    </div>
                    <div class="col-sm-3 text-right">
                        <b-dropdown text="Export to CSV" class="mr-3">
                            <b-dropdown-item href="#" @click.prevent="exportToCSV('utf', false)">Standard</b-dropdown-item>
                            <b-dropdown-item href="#" @click.prevent="exportToCSV('utf', true)">Alternate</b-dropdown-item>
                            <b-dropdown-item href="#" @click.prevent="exportToCSV('ascii', false)">ASCII</b-dropdown-item>
                            <b-dropdown-item href="#" @click.prevent="exportToCSV('ascii', true)">ASCII Alternate</b-dropdown-item>
                            <b-dropdown-item href="#" @click.prevent="exportToCSV('compat', false)">Compatibility</b-dropdown-item>
                            <b-dropdown-item href="#" @click.prevent="exportToCSV('compat', true)">Compatibility Alternate</b-dropdown-item>
                        </b-dropdown>
                        <!--<b-button class="d-print-none" size="sm" @click.prevent="exportToCSV" :disabled="report.length==0">Export to CSV</b-button>-->
                        <b-button class="d-print-none mr-5" size="sm" @click.prevent="windowPrint" :disabled="report.length==0">Print</b-button>
                    </div>
                </div>

            </div>
            <div class="card-body">
                <div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-md-2 col-lg-1 control-label">From Date:</label>
                        <div class="col-sm-3 col-md-4 col-lg-3">
                            <b-input-group size="sm">
                                <b-input-group-prepend is-text>
                                    <b-icon icon="calendar"></b-icon>
                                </b-input-group-prepend>
                                <b-form-select :options="monthList" v-model="selMonthFrom">
                                    <!--<template v-slot:first>
                        <b-form-select-option :value="null">-- All Periods --</b-form-select-option>
                    </template>-->
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <label class="col-sm-3 col-md-2 col-lg-1 control-label">To Date:</label>
                        <div class="col-sm-3 col-md-4 col-lg-3">
                            <b-input-group size="sm">
                                <b-input-group-prepend is-text>
                                    <b-icon icon="calendar"></b-icon>
                                </b-input-group-prepend>
                                <b-form-select :options="monthList" v-model="selMonthTo">
                                    <!--<template v-slot:first>
                        <b-form-select-option :value="null">-- All Periods --</b-form-select-option>
                    </template>-->
                                </b-form-select>
                            </b-input-group>
                        </div>
                    </div>




                    <!--<div class="form-group row">
                        <label class="col-sm-3 col-md-2 col-lg-1 control-label">Cluster:</label>
                        <div class="col-sm-7 col-md-7">
                            <b-input-group size="sm">
                                <b-input-group-prepend is-text>
                                    <b-icon icon="award"></b-icon>
                                </b-input-group-prepend>
                                <b-form-select :options="countryList" v-model="filterClusterCode" value-field="countryCode" text-field="country">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null">-- All Clusters --</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-input-group>
                        </div>
                    </div>-->


                    <!--<div class="form-group row">
                        <label class="col-sm-3 col-md-2 col-lg-1 control-label">Award Category:</label>
                        <div class="col-sm-7 col-md-7">
                            <b-input-group size="sm">
                                <b-input-group-prepend is-text>
                                    <b-icon icon="award"></b-icon>
                                </b-input-group-prepend>
                                <b-form-select :options="nomCats" v-model="filterNomCat">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null">-- All Award Categories --</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-input-group>
                        </div>
                    </div>-->

                    <div class="form-group row">
                        <label class="col-sm-3 col-md-2 col-lg-1 control-label">Status:</label>
                        <div class="col-sm-7 col-md-7">
                            <b-form-radio-group id="selAwardStatus" v-model="filterConcludeDecision">
                                <b-form-radio value="w">Winners</b-form-radio>
                                <b-form-radio value="r">Runner-Ups</b-form-radio>
                                <b-form-radio value="0">Concluded</b-form-radio>

                                <b-form-radio value="N">Deciding</b-form-radio>
                            </b-form-radio-group>
                        </div>
                        <div class="col-sm-2 col-md-3">

                        </div>
                    </div>

                    <div class="row d-print-none">
                        <div class="col-sm-3 col-md-2 col-lg-1"></div>
                        <div class="col-sm-7 col-md-7">
                            <b-button @click="loadReport()" variant="primary">Load Report</b-button>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <table v-if="hasRunOnce && !isLoading" class="table table-hover table-condensed" style="font-size:0.8rem">
            <tr>
                <th>Nomination</th>
                <th>Nominees</th>
                <th>Motivation/Actions/Impact</th>
                <th>Review By</th>
                <th>Ratings</th>
            </tr>

            <tr v-for="f in report" :key="f._rowNum">
                <td>
                    <div><b>Ref: {{ f.nomId }}</b></div>
                    <div>{{ f.nomCat }}</div>
                    <div>{{ f.dateCreated.sjToDate('d MMM yyyy') }}</div>
                    <div>{{ f.nominatedByMember }}</div>
                    <div class="mt-2"><b>{{ f.concludeDecisionDesc }}</b></div><!--//-- w:winner, r:runner up, 0:conclude (no winner)-->
                </td>
                <td class="preline">{{ f.nominees !== null ? f.nominees.sjReplaceAll(';','\r\n') : '' }}</td>
                <td class="preline" style="width:50%;max-width:50%">
                    <div><b>Motivation:</b></div>
                    <div>{{ f.motivation }}</div>
                    <div><b>Actions:</b></div>
                    <div>{{ f.action }}</div>
                    <div><b>Impact:</b></div>
                    <div>{{ f.impact}}</div>
                </td>
                <td>
                    <div><b>{{ f.reviewByMemberName }}</b></div>
                    <div>Disciplinary: <b>{{ f.reviewIsDisciplinary }}</b></div>
                    <div>Supported: <b>{{ f.reviewIsSupported }}</b></div>
                    <div><b>Comments:</b><br /> {{ f.reviewComment }}</div>
                    <div>{{ f.reviewDate ? f.reviewDate.sjToDate('d MMM yyyy') : '' }}</div>
                </td>
                <td>
                    <div><b>Avg Rating:</b> {{ f.ratingAvg }}</div>
                    <div class="preline">{{ f.ratings ? f.ratings.sjReplaceAll(';','\r\n') : '' }}</div>
                </td>
            </tr>
        </table>
        <div v-if="isLoading"><i>Loading...</i></div>
        <div v-if="hasRunOnce && !isLoading && report.length==0"><i>No Results</i></div>

    </div>

   
</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    import sjData from '@/sjUtilData'


    export default {
        name: 'ReportAwardNominations',
        data() {
            return {
                pageReady: false,
                monthList: [],
                selMonthFrom: null,
                selMonthTo: null,
                filterClusterCode: null,
                filterNomCat: null,
                filterConcludeDecision: 'w',
                report: [],
                nomCats: [],
                isLoading: false,
                hasRunOnce: false
            }
        },
        computed: {
            memberClusterCode() {
                return this.$store.state.memberStore.member.clusterCode
            },
            countryList() {
                return this.$store.state.countryList;
            },
            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
        },

        created() {
            let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const result = [];
            let curYear = new Date().getFullYear();
            let curMonth = new Date().getMonth();

            for (let y = curYear; y >= curYear - 3; y--) {
                let mStart = y == curYear ? curMonth : 11;
                for (let m = mStart; m >= 0; m--) {
                    let t = months[m] + ' ' + y;
                    if (result.length === 0) this.selMonthFrom = t;
                    if (result.length === 0) this.selMonthTo = t;
                    result.push({ value: t, text: t });
                }
            }
            this.monthList = result;

            this.$store
                .dispatch('getDivisionList', {}) //To retrieve Country and Division lists
                .then(() => {
                    this.pageReady = true;
                })
                .catch((error) => {
                    this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error)));
                });

            //this.filterClusterCode = this.memberClusterCode;
            //this.loadReport();
        },
        mounted() {
            //axios.post('/api/member/memberget', {})
            //	.then(({ data }) => {
            //		//this.roles = data.roles;
            //		//this.noms = new sjData(data.noms).data;
            //		//this.displayPart = 1;
            //	})
            //	.catch((error) => {
            //		if (error) {
            //			this.msgBox(error.response.data.message);
            //		}
            //	})
        },
        watch: {
            //selMonth: function () {
            //    if (this.selMonth !== null) this.loadReport();
            //},
            //awardStatus: function () {
            //    if (this.awardStatus !== null) this.loadReport();
            //}
        },

        methods: {
            loadReport() {
                this.isLoading = true;
                this.report = [];
                axios.post('/api/nom/report', { selMonthFrom: this.selMonthFrom, selMonthTo: this.selMonthTo, clusterCode: this.memberClusterCode, concludeDecision: this.filterConcludeDecision })
                    .then(({ data }) => {
                        this.isLoading = false;
                        this.hasRunOnce = true;
                        this.report = new sjData(data.report).data;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        if (error) {
                            this.msgBox(error.response.data.message);
                        }
                    })
            },
            exportToCSV(mode, isAlt) {
                //All: line breaks in cell are only \n 0A

                //isAlt False (Windows) - end of row: \r\n 0D 0A
                //isAlt True (Mac) - end of row: \r 0D

                //mode: utf - file prefix: EF BB BF
                //mode: ascii - file prefix: none
                //mode: compat - replace all line breaks in cell with - (dash)

                const cellLineBreak = mode === "compat" ? "-" : "\n";
                const rowLineBreak = isAlt ? "\r" : "\r\n";
                const filePrefix = mode === "utf" ? "\uFEFF" : "";  // nb: "\uFEFF" generates byte order mark "\xEF\xBB\xBF"
                const isAscii = mode === "ascii";
                const asciiReplaceChar = " "; //"\u00B7";

                let fx = function (val) {
                    try {
                        let s = (val + "").trim(); //https://blog.zsec.uk/csv-dangers-mitigations/
                        while (s.length > 1 && s.indexOf("=") == 0 || s.indexOf("+") == 0 || s.indexOf("@") == 0 || (!isNaN(s) && s.indexOf("-") == 0)) {
                            s = s.substr(1).trim();
                        }

                        if (s.indexOf("@") > -1 || s.indexOf("+") > -1 || s.indexOf("-") > -1 || s.indexOf("=") > -1 || s.indexOf("|") > -1 || s.indexOf("%") > -1) {
                            s = "'" + s.sjReplaceAll("|", "\\|") + "'";
                        }

                        if (s.indexOf("\"") > -1 || s.indexOf("\r") > -1 || s.indexOf("\n") > -1 || s.indexOf(",") > -1) {
                            if (isAscii) s = s
                                .sjReplaceAll("U+201C", '"')
                                .sjReplaceAll("U+201D", '"')
								.sjReplaceAll("U+2018", "'").sjReplaceAll("�","'")
                                .sjReplaceAll("U+2019", "'")
                                .replace(/[\u{00FF}-\u{FFFF}]/gu, asciiReplaceChar);
                            s = "\"" + s.sjReplaceAll("\r\n", cellLineBreak).sjReplaceAll("\r", cellLineBreak).sjReplaceAll("\n", cellLineBreak) + "\"";
                        }
                        return s;
                    } catch {
                        return 'Error';
                    }
                }

                let csv = filePrefix + 'Ref,Date,NominatedBy,Motivation,Actions,Impact,ReviewDate,ReviewBy,IsDisciplinary,IsSupported,Comment,AvgRating,Rating,Nominees\r\n';
                //nomId, dateCreated, nominatedByMember, motivation, action, impact, achievements, reviewDate, reviewByMemberName, reviewIsDisciplinary, reviewIsSupported, reviewComment, ratingAvg, ratings, nominees
                for (var n = 0; n < this.report.length; n++) {
                    let f = this.report[n];
                    //debugger;
                    csv += `${f.nomId},${fx(f.dateCreated.sjToDate('d MMM yyyy HH:mm'))},${fx(f.nominatedByMember)},${fx(f.motivation)},${fx(f.action)},${fx(f.impact)},${fx(f.reviewDate ? f.reviewDate.sjToDate('d MMM yyyy HH:mm') : '')},${fx(f.reviewByMemberName)},${fx(f.reviewIsDisciplinary)},${fx(f.reviewIsSupported)},${fx(f.reviewComment)},${fx(f.ratingAvg ? (f.ratingAvg + '') : '')},${fx(f.ratings ? f.ratings : '')},${fx(f.nominees)}${rowLineBreak}`;
                }


                var pom = document.createElement('a');
                var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                var url = URL.createObjectURL(blob);
                pom.href = url;
                pom.setAttribute('download', `report_${mode}_${isAlt ? 'alt' : ''}.csv`);
                pom.click();
            },
            windowPrint() {
                window.print();
            },
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            }


        }

    }
</script>
