import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
//import Loading from '../views/Loading.vue'
import Logout from '../views/Logout.vue'
import Login from '../views/Login.vue'
import LoginSuccess from '../components/LoginSuccess.vue'
import ContactUs from '../views/ContactUs.vue'
import MyProfile from '../views/MyProfile.vue'
import AwardNomination from '../views/AwardNomination.vue'
import AwardNominationsAdmin from '../views/AwardNominationsAdmin.vue'
import AwardNominations from '../views/AwardNominations.vue'
import InnovationIdea from '../views/InnovationIdea.vue'
import CEOaward from '../views/CEOaward.vue'
import CEOawardVote from '../views/CEOawardVote.vue'
import GroupCEOaward from '../views/GroupCEOaward.vue'
import HumanCapitalAward from '../views/HumanCapitalAward.vue'
import ReportHumanCapitalAward from '../views/ReportHumanCapitalAward.vue'
import SantamHumanCapitalAward from '../views/SantamHumanCapitalAward.vue'
import ReportSantamHumanCapitalAward from '../views/ReportSantamHumanCapitalAward.vue'
import HumanCapitalAwardEndorse from '../views/HumanCapitalAwardEndorse.vue'
import SBSAward from '../views/SBSAward.vue'
import SBSAwardEndorse from '../views/SBSAwardEndorse.vue'
import ReportSBSAward from '../views/ReportSBSAward.vue'

import SSSAward from '../views/SSSAward.vue'
import SSSAwardEndorse from '../views/SSSAwardEndorse.vue'
import ReportSSSAward from '../views/ReportSSSAward.vue'


import GroupCEOawardAdmin from '../views/GroupCEOawardAdmin.vue'
import AdhocRecognition from '../views/AdhocRecognition.vue'
import AdhocSpecial from '../views/AdhocSpecial.vue' //** Used for list of MemberIds hardcoded
import AdhocBulk from '../views/AdhocBulk.vue' //**
import Rewards from '../views/Rewards.vue'
import Reward from '../views/Reward.vue'
import RewardPayItForward from '../views/RewardPayItForward.vue'
import RewardPeerToPeer from '../views/RewardPeerToPeer.vue'
import Recognise from '../views/Recognise.vue'
//import GetToKnow from '../views/GetToKnow.vue'
import RecentRecognition from '../views/RecentRecognition.vue'

import ReportManagement from '../views/ReportManagement.vue'
import ManagementStatement from '../views/ManagementStatement.vue'
import ManagementStatementAdhoc from '../views/ManagementStatementAdhoc.vue'
import Statement from '../views/Statement.vue'
import Orders from '../views/Orders.vue'

import ContentPage from '../views/ContentPage.vue'

import ReportCEOAward from '../views/ReportCEOAward.vue'
import ReportGroupCEOAward from '../views/ReportGroupCEOAward.vue'
import ReportAwardNominations from '../views/ReportAwardNominations.vue'
import PayItForwardStatement from '../views/PayItForwardStatement.vue'
import ReportOffBoardingBalances from '../views/ReportOffBoardingBalances.vue'
import ReportNoParticipationManagers from '../views/ReportNoParticipationManagers.vue'




import About from '../views/About.vue'


//import store from '../store/store'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((error) => {
    });
};


const routes = [
    { path: '/', name: 'Home', component: Home },
    { path: '/loginsuccess', name: 'LoginSuccess', component: LoginSuccess, meta: { allowAnonymous: true } },
    //{ path: '/ssoaccess/authenticating', name: 'ssoAuthProcessing', component: AuthSSO, meta: { allowAnonymous: true } },
    //{ path: '/ssoaccess/:siteCode/:ssoKey', name: 'ssoAuth', component: AuthSSO, meta: { allowAnonymous: true } },
    { path: '/logout', name: 'Logout', component: Logout, meta: { allowAnonymous: true } },
    { path: '/login', name: 'Login', component: Login, meta: { allowAnonymous: true } },
    //{ path: '/loading', name: 'Loading', component: Loading, meta: { allowAnonymous: true } }

    { path: '/contactus', name: 'ContactUs', component: ContactUs, meta: { allowAnonymous: true } },
    { path: '/myprofile', name: 'MyProfile', component: MyProfile, meta: { allowAnonymous: false } },
    { path: '/myprofile/:urlParam', name: 'MyProfileUpdate', component: MyProfile, props: true, meta: { allowAnonymous: false } },


    { path: '/about/:urlParam', component: About, meta: { allowAnonymous: false } },
    { path: '/about', component: About, meta: { allowAnonymous: false } },

    //{
    //    path: '/about', name: 'About',
    //    // route level code-splitting
    //    // this generates a separate chunk (about.[hash].js) for this route
    //    // which is lazy-loaded when the route is visited.
    //    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    //},

    { path: '/awardnomination', name: 'AwardNomination', component: AwardNomination, meta: { allowAnonymous: false } },

    { path: '/awardnominations', name: 'AwardNominations', component: AwardNominations, meta: { allowAnonymous: false } },
    { path: '/awardnominationsadmin', name: 'AwardNominationsAdmin', component: AwardNominationsAdmin, meta: { allowAnonymous: false } },

    { path: '/innovationidea', name: 'InnovationIdea', component: InnovationIdea, meta: { allowAnonymous: false } },
    { path: '/ceoaward', name: 'CEOaward', component: CEOaward, meta: { allowAnonymous: false } },
    { path: '/ceoawardvote', name: 'CEOawardVote', component: CEOawardVote, meta: { allowAnonymous: false } },

    { path: '/humancapitalaward', name: 'HumanCapitalAward', component: HumanCapitalAward, meta: { allowAnonymous: false } },
    { path: '/humancapitalawardendorse', name: 'HumanCapitalAwardEndorse', component: HumanCapitalAwardEndorse, meta: { allowAnonymous: false } },

    { path: '/santamhumancapitalaward', name: 'SantamHumanCapitalAward', component: SantamHumanCapitalAward, meta: { allowAnonymous: false } },
    //{ path: '/santamhumancapitalawardendorse', name: 'SantamHumanCapitalAwardEndorse', component: SantamHumanCapitalAwardEndorse, meta: { allowAnonymous: false } },

    { path: '/sbsaward', name: 'SBSAward', component: SBSAward, meta: { allowAnonymous: false } },
    { path: '/sbsawardendorse', name: 'SBSAwardEndorse', component: SBSAwardEndorse, meta: { allowAnonymous: false } },

    { path: '/sssaward', name: 'SSSAward', component: SSSAward, meta: { allowAnonymous: false } },
    { path: '/sssawardendorse', name: 'SSSAwardEndorse', component: SSSAwardEndorse, meta: { allowAnonymous: false } },

    { path: '/groupceoaward', name: 'GroupCEOaward', component: GroupCEOaward, meta: { allowAnonymous: false } },
    { path: '/groupceoawardadmin', name: 'GroupCEOawardAdmin', component: GroupCEOawardAdmin, meta: { allowAnonymous: false } },
    { path: '/adhocrecognition', name: 'AdhocRecognition', component: AdhocRecognition, meta: { allowAnonymous: false } },
    { path: '/adhocspecial', name: 'AdhocSpecial', component: AdhocSpecial, meta: { allowAnonymous: false } },
    { path: '/adhocbulk', name: 'AdhocBulk', component: AdhocBulk, meta: { allowAnonymous: false } },

    { path: '/reward', name: 'Reward', component: Reward, meta: { allowAnonymous: false } },
    { path: '/reward/:selectedMemberId', name: 'Reward_SelectedMemberId', component: Reward, meta: { allowAnonymous: false } },

    { path: '/rewards/:urlParam', name: 'Rewards_UrlParam',  component: Rewards, meta: { allowAnonymous: false } },
    { path: '/rewards', name: 'Rewards', component: Rewards, meta: { allowAnonymous: false } },
    { path: '/orders', name: 'Orders', component: Orders, meta: { allowAnonymous: false } },
    //{ path: '/rewards', redirect: '/rewards/' },

    { path: '/rewardpayitforward', name: 'RewardPayItForward', component: RewardPayItForward, meta: { allowAnonymous: false } },
    { path: '/rewardpeertopeer', name: 'RewardPeerToPeer', component: RewardPeerToPeer, meta: { allowAnonymous: false } },

    { path: '/recognise', name: 'Recognise', component: Recognise, meta: { allowAnonymous: false } },
    { path: '/recognise/:selectedMemberId', name: 'Recognise2', component: Recognise, meta: { allowAnonymous: false } },

    { path: '/gettoknow', name: 'GetToKnow', component: () => import('../views/GetToKnow.vue'), meta: { allowAnonymous: false } },
    { path: '/gettoknow/:selectedMemberId', name: 'GetToKnow2', component: () => import('../views/GetToKnow.vue'), meta: { allowAnonymous: false } },

    { path: '/recentrecognition', name: 'RecentRecognition', component: RecentRecognition, meta: { allowAnonymous: false } },
    
    { path: '/reportmanagement', name: 'ReportManagement', component: ReportManagement, meta: { allowAnonymous: false } },
    { path: '/reportoffboardingbalances', name: 'ReportOffBoardingBalances', component: ReportOffBoardingBalances, meta: { allowAnonymous: false } },
    { path: '/managementstatement', name: 'ManagementStatement', component: ManagementStatement, meta: { allowAnonymous: false } },
    { path: '/managementstatementadhoc', name: 'ManagementStatementAdhoc', component: ManagementStatementAdhoc, meta: { allowAnonymous: false } },
    { path: '/statement', name: 'Statement', component: Statement, meta: { allowAnonymous: false } },

    { path: '/reportnoparticipationmanagers', name: 'ReportNoParticipationManagers', component: ReportNoParticipationManagers, meta: { allowAnonymous: false } },

    { path: '/reporthumancapitalaward', name: 'ReportHumanCapitalAward', component: ReportHumanCapitalAward, meta: { allowAnonymous: false } },
    { path: '/reportsantamhumancapitalaward', name: 'ReportSantamHumanCapitalAward', component: ReportSantamHumanCapitalAward, meta: { allowAnonymous: false } },

    { path: '/reportsbsaward', name: 'ReportSBSAward', component: ReportSBSAward, meta: { allowAnonymous: false } },
    { path: '/reportsssaward', name: 'ReportSSSAward', component: ReportSSSAward, meta: { allowAnonymous: false } },
    { path: '/reportceoaward', name: 'ReportCEOAward', component: ReportCEOAward, meta: { allowAnonymous: false } },
    { path: '/reportgroupceoaward', name: 'ReportGroupCEOAward', component: ReportGroupCEOAward, meta: { allowAnonymous: false } },
    { path: '/reportawardnominations', name: 'ReportAwardNominations', component: ReportAwardNominations, meta: { allowAnonymous: false } },
    { path: '/payitforwardstatement', name: 'PayItForwardStatement', component: PayItForwardStatement, meta: { allowAnonymous: false } },

    { path: '/sendbulkpushmessage', name: 'SendBulkPushMessage', component: () => import('../views/SendBulkPushMessage.vue'), meta: { allowAnonymous: false } },

    { path: '/:contentKey', name: 'ContentPage', component: ContentPage, props: true, meta: { allowAnonymous: false } },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

//Router Guards
router.beforeEach((to, from, next) => {
    //console.log('routerguard: beforeEach: ' + to.path);

    let isAllowAnonymous = to.matched.some(m => m.meta.allowAnonymous);
    if (isAllowAnonymous) {
        next();
        return;
    }

    let routeName = to.name;
    if (routeName) {
        if (routeName === 'Logout') {
            sessionStorage.removeItem('lastpath');
        } else if (routeName !== 'Login' && routeName !== 'LoginSuccess' && router !== 'ContentPage') {
            sessionStorage.setItem('lastpath', to.path);
        }
    } else {
        sessionStorage.setItem('lastpath', to.path);
    }

    //console.log('Route to: ' + to.path);
    if (routeName && routeName === 'Logout') {
        sessionStorage.removeItem('lastpath');
    } else if (routeName && routeName !== 'Login' && routeName !== 'LoginSuccess') {
        sessionStorage.setItem('lastpath', to.path);
    }



    let bearerToken = sessionStorage.getItem('SecBr');
    if (!bearerToken) {
        //console.log('routerguard: request navigation to: ' + to.name + ', not logged in - redirect to Login');
        
        next({ name: 'Login' }); //Redirect to login
    } else {
        next()
    }
    // if (to.name !== 'Login') 

})

export default router
