<template v-if="isLoggedIn">
    <div class="container-fluid page">

        <div class="card card-default" style="min-width:420px">
            <div class="card-header">
                <div class="row">
                    <div class="col-sm-6 font-weight-bold">
                        <h5>Off Boarding Member Balances Report</h5>
                    </div>

                    <div class="col-sm-3">

                    </div>
                    <div class="col-sm-3 text-right">
                        <b-button class="d-print-none mr-2" size="sm" @click.prevent="windowPrint" :disabled="report.length==0">Print</b-button>
                        <b-button class="d-print-none" size="sm" @click.prevent="exportToCSV" :disabled="report.length==0">Export to CSV</b-button>
                    </div>
                </div>

            </div>
            <div class="card-body">
                <div>
                    <!--<div class="form-group row">
                        <label class="col-sm-3 col-md-2 control-label">Date:</label>
                        <div class="col-sm-7 col-md-5 col-lg-3">
                            <b-input-group size="sm">
                                <b-input-group-prepend is-text>
                                    <b-icon icon="calendar"></b-icon>
                                </b-input-group-prepend>
                                <b-form-select :options="monthList" v-model="selMonth">-->
                                    <!--<template v-slot:first>
                                        <b-form-select-option :value="null">-- All Periods --</b-form-select-option>
                                    </template>-->
                                <!--</b-form-select>
                            </b-input-group>
                        </div>
                    </div>-->
                    <div v-if="member.isAdminReports" class="form-group row">
                        <label class="col-sm-3 col-md-2 control-label">Cluster:</label>
                        <div class="col-sm-7 col-md-7">
                            <b-form-radio-group id="selClusterGrp" v-model="isAll">
                                <b-form-radio :value="false">Current</b-form-radio>
                                <b-form-radio :value="true">All Clusters</b-form-radio>
                            </b-form-radio-group>
                        </div>
                        <div class="col-sm-2 col-md-3">

                        </div>
                    </div>
                </div>

            </div>
        </div>

        <table class="table table-hover table-condensed" style="font-size:0.8rem">
            <tr>
                <th v-for="f in reportFields" :key="f[1]" :class="f[3]">{{ f[0] }}</th>
            </tr>
            <tr v-for="r in report" :key="r._rowNum">
                <td v-for="f in reportFields" :class="f[3]" :key="f[1]">{{ getFieldValue(f,r) }}</td>
            </tr>
        </table>
        <div v-if="isLoading"><i>Loading...</i></div>
        <div v-if="!isLoading && report.length==0"><i>No Results</i></div>

    </div>

   
</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    import sjData from '@/sjUtilData'


    export default {
        name: 'ReportMemberBalances',
        data() {
            return {
                monthList: [],
                selMonth: null,
                isAll: false,
                report: [],
                isLoading: false,
                reportFields: [ //0:Text, 1:FieldName, 2:type, 3:class
                    ['Member No', 'memberNumber', '', ''],
                    ['First name', 'firstName', '', ''],
                    ['Surname', 'surname', '', ''],
                    ['Cluster', 'cluster', '', ''],
                    ['Division', 'division', '', ''],
                    ['Registered', 'dateCreated', 'datetime', ''],
                    ['Status', 'memberStatus', '', ''],
                    ['Suspended', 'dateSuspended', 'datetime', ''],
                    ['Balance', 'pointsBalance', 'number-blank', 'text-right']
                ]

            }
        },
        computed: {
            member() {
                return this.$store.state.memberStore.member
            },

            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },




        },

        created() {
            let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const result = [];
            let curYear = new Date().getFullYear();
            let curMonth = new Date().getMonth();

            for (let y = curYear; y >= curYear - 3; y--) {
                let mStart = y == curYear ? curMonth : 11;
                for (let m = mStart; m >= 0; m--) {
                    let t = months[m] + ' ' + y;
                    if (result.length === 0) this.selMonth = t;
                    result.push({ value: t, text: t });
                }
            }
            this.monthList = result;
            //this.loadReport();
        },
        mounted() {
            //axios.post('/api/member/memberget', {})
            //	.then(({ data }) => {
            //		//this.roles = data.roles;
            //		//this.noms = new sjData(data.noms).data;
            //		//this.displayPart = 1;
            //	})
            //	.catch((error) => {
            //		if (error) {
            //			this.msgBox(error.response.data.message);
            //		}
            //	})
        },
        watch: {
            selMonth: function () {
                if (this.selMonth !== null) {
                    this.loadReport();
                }
            },
            isAll: function () {
                this.loadReport();
            }
        },

        methods: {
            loadReport() {
                this.isLoading = true;
                this.report = [];
                axios.post('/api/report/reportmemberbalancesoffboarding', { isAll: this.isAll })
                    .then(({ data }) => {
                        this.isLoading = false;

                        this.report = new sjData(data.report).data;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        if (error) {
                            this.msgBox(error.response.data.message);
                        }
                    })
            },
            exportToCSV() {
                let fx = function (val) {
                    try {
                        let s = val.toString().trim(); //https://blog.zsec.uk/csv-dangers-mitigations/
                        while (s.length > 1 && s.indexOf("=") == 0 || s.indexOf("+") == 0 || s.indexOf("@") == 0 || (!isNaN(s) && s.indexOf("-") == 0)) {
                            s = s.substr(1).trim();
                        }

                        if (s.indexOf("@") > -1 || s.indexOf("+") > -1 || s.indexOf("-") > -1 || s.indexOf("=") > -1 || s.indexOf("|") > -1 || s.indexOf("%") > -1) {
                            s = "'" + s.sjReplaceAll("|", "\\|") + "'";
                        }

                        if (s.indexOf("\"") > -1 || s.indexOf("\x0A") > -1 || s.indexOf("\x0D") > -1 || s.indexOf(",") > -1) {
                            s = "\"" + s.sjReplaceAll("\"", "\"\"") + "\"";
                        }
                        return s;
                    } catch {
                        return 'Error';
                    }
                }

                let csv = '';
                for (let f = 0; f < this.reportFields.length; f++) {
                    csv += (f == 0 ? '' : ',') + this.reportFields[f][0];
                }
                csv += '\r\n';

                for (var n = 0; n < this.report.length; n++) {
                    let r = this.report[n];
                    let line = '';
                    for (let f = 0; f < this.reportFields.length; f++) {
						line += (f == 0 ? '' : ',') + `${fx(this.getFieldValue(this.reportFields[f], r))}`;
                    }
					csv += line + '\r\n';
                }

                var pom = document.createElement('a');
                var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                var url = URL.createObjectURL(blob);
                pom.href = url;
                pom.setAttribute('download', 'report.csv');
                pom.click();

            },
            windowPrint() {
                window.print();
            },
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },
            getFieldValue(f, r) {
                let fieldName = f[1];
                let fieldType = f[2];
                if (typeof r[fieldName] === 'undefined') {
                    return '[Err:Unknown ' + fieldName + ']';
                }
                if (r[fieldName] == null) return '';
                let val = r[fieldName];
                if (fieldType == 'number-blank') {
                    return val != 0 ? val : ''
                } else if (fieldType == 'datetime') {
                    return val.sjToDate('d MMM yyyy HH:mm');
                } else {
                    return val;
                }
            }


        }

    }
</script>
