<template>
    <!--<div v-if="isLoggedIn && memberClusterCode=='sky'" class="container page">
        <h1>This service is not available to your cluster</h1>
    </div>-->
    <div if="isLoggedIn" class="container page">

        <div>
            <sitecode-image src="topRecognition.jpg" class="img-fluid w-100" />
        </div>
        <b-alert v-if="!isManager" show>Only a manager may access this module.</b-alert>
        <section v-if="displayPart==0 && isManager" class="card card-default">
            <div class="card-header">
                <h5>Recognise</h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-6"><strong>Recipients:</strong></div>
                    <div class="col-sm-6 text-right"></div>
                </div>
                <div v-for="r in selectedMems" :key="r._rowNum" class="row">
                    <template v-if="r.isEntireDivision">
                        <div class="col-1 text-right"><b-icon icon="collection" style="color:#9f34a0"></b-icon></div>
                        <div class="col" style="color:#9f34a0">Entire division: {{ r.division }}</div>
                    </template>
                    <template v-else>
                        <div class="col-1 text-right"><b-icon icon="person" style="color:#ccc"></b-icon></div>
                        <div class="col">{{ r.firstname + ' ' + r.surname }}</div>
                        <div class="col">{{ r.division }}</div>
                    </template>
                </div>
                <div v-if="selectedMems.length===0" class="text-danger">Please select a recipient.</div>
                <div class="mt-1">
                    <b-button type="button" pill @click="$bvModal.show('modalChooseNominees')" variant="primary" size="sm" class="mt-2">{{selectedMems.length===0 ? 'Select' : 'Change'}}  Recipient</b-button>
                    <!--<a href="#" @click.prevent="$bvModal.show('modalChooseNominees')">Select Nominees</a>-->
                </div>

                <hr />
                <b-form @submit.prevent="onSubmitReward" novalidate>
                    <b-form-group id="groupReason" label-for="selectreason" label="Value:" :invalid-feedback="fReward.errors.reason" description="">
                        <b-form-select id="selectreason" v-model="fReward.form.reason" :state="fReward.state.reason" :options="valueOptions"></b-form-select>
                    </b-form-group>

                    <b-form-group id="groupPoints" label-for="selectPoints" label="Points Amount:" :invalid-feedback="fReward.errors.points" description="" :state="fReward.state.points">
                        <b-form-select id="selectPoints" v-model="fReward.form.points" :state="fReward.state.points" :options="pointsOptions"></b-form-select>
                    </b-form-group>


                    <!--<b-form-group id="groupPoints" label-for="points" label="Points Amount:" description="" :invalid-feedback="fReward.errors.points" :state="fReward.state.points">
                        <b-form-radio-group id="radiogroupPoints" v-model="fReward.form.points" name="points" :state="fReward.state.points">
                            <div class="row">
                                <div v-for="o in pointsOptions" :key="o.value" class="col-sm-3 col-lg-2 mb-2">
                                    <b-form-radio :value="o.value">{{o.text}}</b-form-radio>
                                </div>
                            </div>
                        </b-form-radio-group>
                    </b-form-group>-->

                    <b-form-group id="groupSendBy" label-for="sendBy" description="" :invalid-feedback="fReward.errors.sendBy" :state="fReward.state.sendBy" class="mt-4">
                        <b-form-radio-group id="radiogroupSendBy" v-model="fReward.form.sendBy" name="sendBy" :state="fReward.state.sendBy">
                            <div class="row">
                                <div class="col-sm-3 col-lg-2">
                                    <b-form-radio value="email">Email</b-form-radio>
                                </div>
                                <div class="col-sm-3 col-lg-2">
                                    <b-form-radio value="sms">SMS</b-form-radio>
                                </div>
                            </div>
                        </b-form-radio-group>
                    </b-form-group>

                    <b-form-group id="groupRewardMessage" label-for="txtRewardMessage" label="Message to Recipients" :invalid-feedback="fReward.errors.message" :description="(isSendBySMS?150:350)-fReward.form.message.length + ' characters remaining'">
                        <b-form-textarea id="txtRewardMessage" :disabled="fReward.form.sendBy===null" v-model="fReward.form.message" :state="fReward.state.message" :placeholder="isSendBySMS ? 'Short SMS message to recipients up to 150 characters' : 'Short email message to recipients up to 350 characters'" rows="3" max-rows="5" :maxlength="(isSendBySMS?150:350)" />
                    </b-form-group>

                    <hr />

                    <div class="text-right"><b-button type="submit" variant="primary">Submit</b-button></div>
                </b-form>
            </div>
        </section>

        <section v-if="displayPart==1" class="card card-default">
            <div class="card-header">
                <h5>Recognise</h5>
            </div>
            <div class="card-body">
                <b-alert variant="success" show>
                    <h4 class="alert-heading">Recognise</h4>
                    <p>
                        Successfully submitted recognition.
                    </p>
                </b-alert>

            </div>
            <div class="card-footer">
                <div class="text-right"><button type="button" @click="displayPart=0" class="btn btn-primary">Send Another</button></div>
            </div>
        </section>



        <!--
        **************************************************************************************************************************************************
                                                                    M O D A L S
        **************************************************************************************************************************************************
        -->
        <!-- Modal - Nominees -->
        <b-modal id="modalChooseNominees" title="RECOGNISE" hide-footer size="xl" no-close-on-backdrop>
            <member-select title="Select recipients"
                           selectedTitle="Recipients"
                           :defaultCountryCode="memberClusterCode"
                           :defaultDivisionCode="memberDivisionCode"
                           :selectedMems="selectedMems"
                           :isAllowWholeDivision="false"
                           :isSingle="false"
                           :showContinue="false"
                           excludeCountryCode="sky"
                           excludeDivisionCode="sra_2104,sra_4181"
                           class="mb-3" />

            <hr />
            <div class="text-right"><b-button type="submit" variant="primary" @click="$bvModal.hide('modalChooseNominees')">Done</b-button></div>
        </b-modal>

    </div>

</template>

<script>
    // @ is an alias to /src
    import SitecodeImage from '@/components/SitecodeImage';
    import axios from 'axios'
    //import sjData from '@/sjUtilData'
    import MemberSelect from '@/components/MemberSelect';
    //import { forEach } from 'core-js/library/fn/array'; //This appeared and caused all sorts of issues!


    export default {
        name: 'Reward',
        components: {
            MemberSelect,
			'sitecode-image': SitecodeImage},
        data() {
            return {
                displayPart: 0,

				

                fReward: {
					form: { what:'reward', reason: null, points: null, message: '', sendBy: null, recipients: [] }
                },

                selectedMems: [],

                roles: { ca: false, cc: false, idea: false, inv: false },

                reportTitle: '',

                testr: '',

            }
        },
        computed: {
            pointsOptions() {
                const pointsList = [5, 10, 20, 25, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 1000, 2000, 3000, 4000, 5000];
				let p = [{ text: '--- Select Amount to Award---', value: null, disabled:true }];
                pointsList.forEach(n => p.push({ text: n + '  e-points', value: n }));
                return p;
			},
            isLoggedIn() {
				return this.$store.state.isLoggedIn && this.memberDivisionCode != 'sra_2104' //Disable SanPay division from access
            },
			isManager() {
				return this.$store.state.memberStore.member.isManager
			},
            countryList() {
                if (this.isCountryFixed) { //only show the default cluster.
                    return this.$store.state.countryList.filter(country => country.countryCode === this.defaultCountryCode);
                } else {
                    return [{ countryCode: null, country: '-- Please select a Cluster--', disabled: true }]
                        .concat(this.$store.state.countryList);
                }
            },
            memberClusterCode() {
                return this.$store.state.memberStore.member.clusterCode
            },
            memberDivisionCode() {
                return this.$store.state.memberStore.member.divisionCode
            },
            isSendBySMS() {
                return this.fReward.form.sendBy === 'sms';
            },
			valueOptions() {
				const santamOptions = [
					{ html: '<b>--- Santam Options ---</b>', value: null, disabled: true },
					{ text: 'EXCELLENCE', value: 'EXCELLENCE' },
					{ text: 'HUMANITY', value: 'HUMANITY' },
					{ text: 'INTEGRITY', value: 'INTEGRITY' },
					{ text: 'INNOVATION', value: 'INNOVATION' },
					{ text: 'PASSION', value: 'PASSION' },
					{ html: '<b>--- Group Options ---</b>', disabled: true }
				];

				const groupOptions = [
                    { text: 'Care', value: 'Care' },
                    { text: 'Collaboration', value: 'Collaboration' },
                    { text: 'Integrity', value: 'Integrity' },
                    { text: 'Innovation', value: 'Innovation' },
				];
				return this.memberClusterCode === 'stm' ? [...santamOptions, ...groupOptions] : [{ text: '--- Select an Option ---', value: null }, ...groupOptions];
			},
        },
        created() {
            //formSetState must be called on creation, to ensure all fields are setup
            this.formSetState(this.fReward, true, true) //formObj, resetState, resetForm

            this.preSelectMemberLinkIn();

            this.$store
                .dispatch('getDivisionList', {})
                .then(() => {
                    this.displayPart = 0;

                })
                .catch((error) => {
                    this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error)));
                });


        },
        mounted() {

        },
        methods: {

            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },

            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },

			preSelectMemberLinkIn() {
				//Add member to selected list, if passed in on sessionStorage (tloid)
				let tloidMemberId = sessionStorage.getItem('tloid'); //tloid - temp link out id - i.e. MemberId
				if (tloidMemberId) {
					sessionStorage.removeItem('tloid');
					axios.post('/api/site/getmemberget', { memberId: tloidMemberId })
						.then(({ data }) => {
							this.selectedMems.push({
								_serialNo: -1,
								division: data.division,
								firstname: data.firstname,
								surname: data.surname,
								isManager: false,
								memberId: data.memberId,
								memberNumber: data.memberNumber,
								isEntireDivision: false,
								entireDivisionCode: ''
							});
						})
						.catch((error) => {
							if (error) {
								this.msgBox('Error retrieving member data');
							}
						})
				}

				//let tlowhat = sessionStorage.getItem('tlowhat'); //tloid - temp link out id - i.e. MemberId
				//if (tlowhat) {
				//	sessionStorage.removeItem('tlowhat');

				//	if (tlowhat == "BDAY") {
				//		this.fReward.form.reason = 'Happy Birthday';
				//	} else if (tlowhat == "ANNIV") {
				//		this.fReward.form.reason = 'Happy Work Anniversary';
				//	}
				//}
			},

            onSubmitReward() {
                this.formSetState(this.fReward, true);//formObj, resetState, resetForm
				this.fReward.form.recipients = this.selectedMems.map(item => item.isEntireDivision ? 'div:' + item.entireDivisionCode : item.memberId);
				if (this.selectedMems.length === 0) {
					this.msgBox('Please select at least one recipient.','Recipients required');
					return;
				} 

                //this.displayPart = 1;
                if (this.displayPart == 0) {
                    return axios
                        .post(
                            '/api/reward/doreward',
                            this.fReward.form
                        )
                        .then(({ data }) => {
                            console.log(data);
                            this.displayPart = 1;
							this.selectedMems = [];
							this.formSetState(this.fReward, true, true);

                            //this.msgBox('Successfully submitted nomination');
                        })
                        .catch((error) => {
                            this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
                            if (error.response.data.formErrors) {
                                this.fReward.errors = error.response.data.formErrors;
                                this.formSetState(this.fReward);
                            }
                        })
                        ;
                }
            },

        }




    }
</script>
