<template>
    <div v-if="isLoggedIn" class="container page">
        <section v-if="displayPart==-1" class="card card-default">
            <div class="col-sm-8"><h5>Santam Specialised Solutions Awards is Closed</h5></div>

        </section>

        <section v-if="displayPart==10" class="card card-default">
            <div class="card-header">
                <div class="row">
                    <div class="col-sm-8"><h5>Santam Specialised Solutions Awards</h5></div>
                    <div class="col-sm-4">   
                    </div>
                </div>
            </div>
            <div class="card-body" >
                <div class="nomMessage">
                    <b-card no-body>
                        <b-tabs pills vertical lazy card no-nav-style nav-wrapper-class="col-12 col-md-4" content-class="col-12 col-md-8">
                            <b-tab>
                                <template v-slot:title>
                                    <div class="text-center"><b-img thumbnail fluid :src="require('../assets/image/ceosss.jpg')" alt=""></b-img></div>
                                    <div class="mb-1 text-center ceo">Gareth Beaver</div>
                                </template>
                                <b-card-text class="text-left">
                                    <p class="mb-3">Dear Specialised Solutions colleagues.</p>
                                    <p>The strength of our business lies in our people, who are at the core of everything we do. This program is our way of honouring those who exemplify leadership excellence, shine as specialists in our field, and contribute to the power of teamwork. By recognizing these outstanding individuals, we not only reward their incredible efforts but also reinforce the values that make our organization thrive. Celebrate their achievements with us and let’s continue to inspire excellence together.</p>

                                    <p>The Specialist Rewards and Recognition Program celebrate those individuals and teams in the form of these award categories:</p>
                                    <ul>
                                        <li>The Ultimate Specialist</li>
                                        <li>Specialist Solutions Team Award</li>
                                        <li>Excellence in Specialist Leadership Award</li>
                                        <li>Specialist Service Champion Awards</li>
                                    </ul>
                                </b-card-text>
                            </b-tab>

                            <hr />
                            <div class="text-right mt-3 mb-3">
                                <a class="btn btn-primary" href="#" @click.prevent="displayPart=0" v-b-tooltip.hover.left title="Create a new SSS Award nomination">New SSS Award nomination</a>
                            </div>
                        </b-tabs>
                    </b-card>
                </div>
            </div>
        </section>


        <template v-if="displayPart==0">
            <div class="row mb-2">
                <div class="col-sm-8">
                    <h5>Santam Specialised Solutions Awards</h5>
                    Select an Award below, then complete your nomination.
                </div>
                <div class="col-sm-4 text-right">
                    <a href="#" @click.prevent="displayPart=10" class="btn btn-secondary">Back</a>
                </div>
            </div>
            <!--
    { value: '', text: '--- Please select an option ---' },
    { value: 'Individual', text: 'Individual' },
    { value: 'Team', text: 'Team' },
    { value: 'Simple Innovative Solutions', text: 'Simple Innovative Solutions' },
    { value: 'Exceptional Service', text: 'Exceptional Service < 35' },
    { value: 'General employee', text: 'General employee (non-management)' },
        -->
            <section class="card mb-4 nomAwardCategory3">
                

                <b-tabs v-model="tabIndex" lazy card nav-class="selcatNav">
                    <b-tab title="The Ultimate Specialist" @click="awardCatSelected('The Ultimate Specialist: Emerging')">
                        <b-card-text class="text-left">
                            <h3>The Ultimate Specialist</h3>
                            <hr />
                            <b-form-group id="groupSelectValue" label-for="selectValue" label="Category:" description="">
                                <b-form-radio-group v-model="fNomination.form.awardCat">
                                    <b-form-radio value="The Ultimate Specialist: Emerging">Emerging</b-form-radio>
                                    <b-form-radio value="The Ultimate Specialist: Established">Established</b-form-radio>
                                    <b-form-radio value="The Ultimate Specialist: Seasoned">Seasoned</b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>

                            <p>
                                <b>Definition:</b><br />
                                This award is the highest form of recognition in our business, reserved for individuals who demonstrate unmatched expertise and brilliance in their field. Recipients make significant contributions to advancing the business unit through exceptional skill, knowledge, and innovation. They are not only masters in their speciality but also leaders who inspire and uplift others, setting a standard of excellence and driving our mission forward. This individual embodies the highest standards of our organisation, showcasing dedication, visionary thinking, and an unwavering pursuit of perfection.
                            </p>
                        </b-card-text>
                    </b-tab>
                    <b-tab title="Specialist Solutions Team Awards" @click="awardCatSelected('Specialist Solutions Team Awards: Internal')">
                        <b-card-text class="text-left">
                            <h3>Specialist Solutions Team Awards</h3>
                            <hr />
                            <b-form-group id="groupSelectValue" label-for="selectValue" label="Category:" description="">
                                <b-form-radio-group v-model="fNomination.form.awardCat">
                                    <b-form-radio value="Specialist Solutions Team Awards: Internal">Internal</b-form-radio>
                                    <b-form-radio value="Specialist Solutions Team Awards: Shared Services">Shared Services</b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                            <p>
                                <b>Definition:</b><br />
                                This award recognises the collective achievements of a team that has shown outstanding collaboration, innovation, and effectiveness in delivering exceptional solutions. It honours not just the results, but the process of working together harmoniously and effectively, demonstrating the power of teamwork in achieving remarkable goals.
                            </p>
                        </b-card-text>
                    </b-tab>
                    <b-tab title="Excellence in Specialist Leadership Award" @click="awardCatSelected('Excellence in Specialist Leadership Award: Established')">
                        <b-card-text class="text-left">
                            <h3>Excellence in Specialist Leadership Award</h3>
                            <hr />
                            <b-form-group id="groupSelectValue" label-for="selectValue" label="Category:" description="">
                                <b-form-radio-group v-model="fNomination.form.awardCat">
                                    <b-form-radio value="Excellence in Specialist Leadership Award: Established">Established</b-form-radio>
                                    <b-form-radio value="Excellence in Specialist Leadership Award: Seasoned">Seasoned</b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                            <p>
                                <b>Definition:</b><br />
                                This award honours a Specialist Leader who has shown exemplary leadership abilities, recognising potential and creating an environment of empowerment and support. It recognises a professional who goes above and beyond to understand and address the needs of their employees, providing leadership that is impactful, effective, motivational, and consistent.
                            </p>
                        </b-card-text>
                    </b-tab>
                    <b-tab title="Specialist Service Champion Award" @click="awardCatSelected('Specialist Service Champion Award: Broker')">
                        <b-card-text class="text-left">
                            <h3>Specialist Service Champion Award</h3>
                            <hr />
                            <b-form-group id="groupSelectValue" label-for="selectValue" label="Category:" description="">
                                <b-form-radio-group v-model="fNomination.form.awardCat">
                                    <b-form-radio value="Specialist Service Champion Award: Broker">Broker</b-form-radio>
                                    <b-form-radio value="Specialist Service Champion Award: Client">Client</b-form-radio>
                                    <b-form-radio value="Specialist Service Champion Award: Claims">Claims</b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                            <p>
                                <b>Definition:</b><br />
                                <div v-if="fNomination.form.awardCat === 'Specialist Service Champion Award: Broker'">
                                    <strong>Brokers:</strong> This award recognises an individual who consistently delivers exceptional service to brokers, going above and beyond to understand and meet their needs, providing valuable guidance and ensuring smooth interactions.
                                </div>
                                <div v-else-if="fNomination.form.awardCat === 'Specialist Service Champion Award: Client'">
                                    <strong>Client:</strong> This award honours an individual who consistently delivers exceptional service to clients, going above and beyond to understand and meet their needs, providing valuable guidance and ensuring smooth interactions. Their dedication to service excellence, proactive problem-solving, and ability to build trust make them an invaluable partner to our clients.
                                </div>
                                <div v-else-if="fNomination.form.awardCat === 'Specialist Service Champion Award: Claims'">
                                    <strong>Claims:</strong> This award recognises an individual who consistently provides exceptional service in the claims department, offering outstanding support to both brokers and clients.
                                </div>
                            </p>
                        </b-card-text>
                    </b-tab>
                </b-tabs>
            </section>

            <section class="card card-default mt-3" v-if="fNomination.form.awardCat !== null">
                <div class="card-header">
                    <h5>Nomination for: <b class="darkShade">{{ fNomination.form.awardCat }}</b></h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6"><strong>Nominee{{isNomSingleOnly ? "" : "s"}}:</strong></div>
                        <div class="col-sm-6 text-right"></div>
                    </div>
                    <div v-for="r in selectedNominees" :key="r._rowNum" class="row">
                        <template v-if="r.isEntireDivision">
                            <div class="col-1 text-right"><b-icon icon="collection" style="color:#9f34a0"></b-icon></div>
                            <div class="col" style="color:#9f34a0">Entire division: {{ r.division }}</div>
                        </template>
                        <template v-else>
                            <div class="col-1 text-right"><b-icon icon="person" style="color:#ccc"></b-icon></div>
                            <div class="col">{{ r.firstname + ' ' + r.surname }}</div>
                            <div class="col">{{ r.division }}</div>
                        </template>
                    </div>
                    <div v-if="selectedNominees.length===0" class="text-danger">Please select nominee{{isNomSingleOnly ? "" : "s"}}.</div>
                    <div class="mt-1">
                        <b-button type="button" pill @click="$bvModal.show('modalChooseNominees')" variant="primary" size="sm">{{selectedNominees.length===0 ? 'Select' : 'Update'}}  Nominee{{isNomSingleOnly ? "" : "s"}}</b-button>
                        <!--<a href="#" @click.prevent="$bvModal.show('modalChooseNominees')">Select Nominees</a>-->
                    </div>

                    <hr />
                    <b-form @submit.prevent="onSubmitAward" novalidate>
                        <!--
        <b-form-group id="groupAwardCat" label-for="selectAwardCat" label="Award Category:" :invalid-feedback="fNomination.errors.awardCat" description="">
            <b-form-select id="selectAwardCat" v-model="fNomination.form.awardCat" :options="awardCatList" value-field="value" text-field="text" :state="fNomination.state.awardCat" />
        </b-form-group>
           -->
                        <b-form-group id="groupMotivation" label-for="txtMotivation" label="Motivation:" :invalid-feedback="fNomination.errors.motivation" :description="1500-fNomination.form.motivation.length + ' characters remaining'">
                            <b-form-textarea id="txtMotivation" v-model="fNomination.form.motivation" :state="fNomination.state.motivation" placeholder="Provide a short motivation" rows="3" max-rows="5" maxlength="1500" />
                        </b-form-group>

                        <b-form-group id="groupaction" label-for="txtaction" label="Actions Taken:" :invalid-feedback="fNomination.errors.action" :description="1500-fNomination.form.action.length + ' characters remaining'">
                            <b-form-textarea id="txtaction" v-model="fNomination.form.action" :state="fNomination.state.action" placeholder="Describe action taken" rows="3" max-rows="5" maxlength="1500" />
                        </b-form-group>

                        <b-form-group id="groupimpact" label-for="txtImpact" label="Impact:" :invalid-feedback="fNomination.errors.impact" :description="1500-fNomination.form.impact.length + ' characters remaining'">
                            <b-form-textarea id="txtImpact" v-model="fNomination.form.impact" :state="fNomination.state.impact" placeholder="Impact thereof" rows="3" max-rows="5" maxlength="1500" />
                        </b-form-group>

                        <hr />
                        <div class="row">
                            <div class="col-sm-6"><strong>Endorser for your nomination:</strong></div>
                            <div class="col-sm-6 text-right"></div>
                        </div>
                        <div v-for="r in selectedNominators" :key="r._rowNum" class="row">
                            <template v-if="r.isEntireDivision">
                                <div class="col-1 text-right"><b-icon icon="collection" style="color:#9f34a0"></b-icon></div>
                                <div class="col" style="color:#9f34a0">Entire division: {{ r.division }}</div>
                            </template>
                            <template v-else>
                                <div class="col-1 text-right"><b-icon icon="person" style="color:#ccc"></b-icon></div>
                                <div class="col">{{ r.firstname + ' ' + r.surname }}</div>
                                <div class="col">{{ r.division }}</div>
                            </template>
                        </div>
                        <div v-if="selectedNominators.length!==2" class="text-danger">Select an endorser to support your nomination (Optional).</div>
                        <div class="mt-1"><b-button type="button" pill @click="$bvModal.show('modalChooseNominators')" variant="primary" size="sm">{{selectedNominators.length===0 ? 'Select' : 'Update'}} Endorser</b-button></div>
                        <!--<div class="text-center"><b-button type="button" @click="$bvModal.show('modalChooseNominators')" variant="primary">Select Nominators</b-button></div>-->
                        <hr />

                        <div class="text-right"><b-button type="submit" variant="primary">Submit</b-button></div>
                    </b-form>
                </div>
            </section>
        </template>

        <section v-if="displayPart==1" class="card card-default">
            <div class="card-header">
                <h5>Santam Specialised Solutions Awards</h5>
            </div>
            <div class="card-body">
                <b-alert variant="success" show>
                    <h4 class="alert-heading">SSS Awards Nomination</h4>
                    <p>Successfully submitted the SSS Award Nomination.</p>
                    <p>Your reference for this nomination is: <b>{{awardId}}</b></p>
                </b-alert>

                <b-button type="button" pill @click="displayPart=10" variant="primary">Ok</b-button>

            </div>
        </section>



        <!--
    **************************************************************************************************************************************************
                                                                M O D A L S
    **************************************************************************************************************************************************
    -->
        <!-- Modal - Nominees -->
        <b-modal id="modalChooseNominees" :title="'NOMINEE' + (isNomSingleOnly ? '' : 'S')" hide-footer size="xl" no-close-on-backdrop>
            <member-select :title="'Select Nominee' + (isNomSingleOnly ? '' : 's') + ' for the SSS Award'"
                           :selectedTitle="isNomSingleOnly ? 'Selected nominee' : 'Selected nominees'"
                           :defaultCountryCode="memberClusterCode"
                           :defaultDivisionCode="memberDivisionCode"
                           :selectedMems="selectedNominees"
                           :isCountryFixed="true"
                           :isAllowWholeDivision="false"
                           :showContinue="false"
                           excludeDivisionCode=""
                           memberType="santam-specialised-solutions"
                           :isSingle ="isNomSingleOnly"
                           class="mb-3" />
            <hr />
            <div class="text-right"><b-button type="submit" variant="primary" @click="$bvModal.hide('modalChooseNominees')">Done</b-button></div>
        </b-modal>

        <!-- Modal - Nominators -->
        <b-modal id="modalChooseNominators" title="NOMINATORS" hide-footer size="xl" no-close-on-backdrop>
            <member-select title="Select an endorser to support your nomination"
                           selectedTitle="Selected endorser to support your nomination"
                           :defaultCountryCode="memberClusterCode"
                           :defaultDivisionCode="memberDivisionCode"
                           :selectedMems="selectedNominators"
                           :isCountryFixed="true"
                           :isAllowWholeDivision="false"
                           :showContinue="false"
                           :isSingle="true"
                           excludeDivisionCode="sra_2104,sra_4181"
                           class="mb-3" />
            <hr />
            <div class="text-right"><b-button type="submit" variant="primary" @click="$bvModal.hide('modalChooseNominators')">Done</b-button></div>
        </b-modal>


    </div>

</template>

<script>
    // @ is an alias to /src
    //import HelloWorld from '@/components/HelloWorld.vue'
    //import SitecodeImage from '@/components/SitecodeImage';
    import axios from 'axios'
    //import sjData from '@/sjUtilData'
    import MemberSelect from '@/components/MemberSelect';
	//import ContentDb from '@/components/ContentDb';



    export default {
        name: 'SanlamAward',
        components: { MemberSelect },//, ContentDb },
        data() {
            return {
                
                displayPart: 10, //Santam Specialised Solutions Awards:   -1: Closed  10:Open
                fNomination: { 
                    form: { awardCat: 'The Ultimate Specialist: Emerging', motivation: '', action: '', impact: '' }
                },
                tabIndex: 0,

                selectedNominees: [],
                selectedNominators: [],

                roles: { ca: false, cc: false, idea: false, inv: false },

                reportTitle: '',
                currentIdeaRowNum: null,
                modalPart: 0,

                //awardCatList: [
                //    { value: '', text: '--- Please select an option ---' },
                //    { value: 'The Ultimate Specialist: Emerging', text: 'The Ultimate Specialist: Emerging' },
                //    { value: 'Innovation', text: 'Innovation' },
                //    { value: 'Santam Experience', text: 'Santam Experience' },
                //    { value: 'Collaboration', text: 'Collaboration' },
                //    { value: 'Everyday Hero', text: 'Everyday Hero' },
                //    { value: 'Rising Star', text: 'Rising Star' }
                //],

                awardId: 0

            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.state.isLoggedIn && this.memberDivisionCode == 'stm_3869' //Only Santam: Specialist Solutions
            },
            countryList() {
                if (this.isCountryFixed) { //only show the default cluster.
                    return this.$store.state.countryList.filter(country => country.countryCode === this.defaultCountryCode);
                } else {
                    return [{ countryCode: null, country: '-- Please select a Cluster--', disabled: true }]
                        .concat(this.$store.state.countryList);
                }
            },
            memberClusterCode() {
                return this.$store.state.memberStore.member.clusterCode
            },
            memberDivisionCode() {
                return this.$store.state.memberStore.member.divisionCode
            },
            currentIdea() {
                if (this.currentIdeaRowNum === null) return null;
                return this.ideas[this.currentIdeaRowNum];
            },
            isNomSingleOnly() {
                return this.fNomination.form.awardCat == 'Leadership with Excellence'
                    || this.fNomination.form.awardCat == 'Rising Star'
                    || this.fNomination.form.awardCat == 'Everyday Hero';
            }


        },
        created() {
            //formSetState must be called on creation, to ensure all fields are setup
            this.formSetState(this.fNomination, true, true) //formObj, resetState, resetForm

            this.$store
                .dispatch('getDivisionList', {})
                .then(() => { 

                })
                .catch((error) => {
                    this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error)));
                });


        },
        mounted() {

        },
        methods: {

            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },

            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};

                    this.$nextTick(() => {
                        this.awardCatSelected('The Ultimate Specialist: Emerging');
                    });
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },

            awardCatSelected(awardCat) {
                this.fNomination.form.awardCat = awardCat;
            },

            onSubmitAward() {
                try {

                    this.formSetState(this.fNomination, true);//formObj, resetState, resetForm
                    this.fNomination.form.nominees = this.selectedNominees.map(item => item.isEntireDivision ? 'div:' + item.entireDivisionCode : item.memberId);
                    let nominators = this.selectedNominators.filter(item => !item.isEntireDivision);
                    if (this.selectedNominees.length === 0) {
                        this.msgBox('Please select at least one nominee.', 'Nominees are required');
                        return;
                    }

                    if (this.isNomSingleOnly && this.selectedNominees.length !== 1) {
                        this.msgBox('Only a single nominee may be selected.', 'Single Nominees');
                        return;
                    }


                    //if (nominators.length !== 2) {
                    //    this.msgBox('Please select two seconders to support your nomination.', 'Two seconders are required');
                    //    return;
                    //}

                    var payload = { ...this.fNomination.form, awardType: "santam-specialised-solutions" }
                    
                    if (nominators.length !== 0) {
                        payload.endorserMemberId = nominators[0].memberId;
                    }

                    return axios
                        .post(
                            '/api/SanlamAward/newnomination',
                            payload
                        )
                        .then(({ data }) => {
                            console.log(data);

                            const er = data.errorMessage;
                            this.awardId = data.awardId;

                            if (data.awardId == 0) {
                                this.msgBox('An unexpected error occured: ' + er)
                            } else {
                                this.formSetState(this.fNomination, true, true);//formObj, resetState, resetForm
                                this.displayPart = 1;
                                //this.msgBox('Successfully submitted nomination');
                            }
                        })
                        .catch((error) => {
                            this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
                            if (error.response.data.formErrors) {
                                this.fNomination.errors = error.response.data.formErrors;
                                this.formSetState(this.fNomination);
                            }
                        })
                        ;
                } catch (err) {
                    this.msgBox(err.message, 'An unexpected error occured submitting the nomination');
                }
            },

        }




    }
</script>
