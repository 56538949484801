<template>
    <div>
        <h1>Logout</h1>
        <router-view class="page" />
        <h3>Please wait, logging out</h3>
        
        <!--<b-button v-on:click="login" type="button" name="button">
        Login
    </b-button>-->
        <hr />
        <!--
        <iframe :src="'https://auth.sanlam.co.za/pkmslogout?tim=' + (new Date().getTime())" style="position: absolute !important; top: -9999px !important; left: -9999px !important;" height="1" width="1"></iframe>
        <img :src="'https://auth.sanlam.co.za/pkmslogout?tim=' + (new Date().getTime())" style="position: absolute !important; top: -9999px !important; left: -9999px !important;" height="1" width="1" />
    -->
    </div>
</template>

<script>
    export default {
        data() {
            return {
            }
        },
        created() {
            this.$store.dispatch('memberStore/doLogout');
			//window.open('https://www-ppe.applauserewards.co.za', "_blank");
			setTimeout(function () {
				window.location.href = "https://auth.sanlam.co.za/pkmslogout";
			}, 2000)
        },
        methods: {
            login() {
				window.location.href = "/sso/idplogin";
                return;
            }
        }
    }
</script>
