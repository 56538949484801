<template>
    <div v-if="isLoggedIn && memberClusterCode == 'stm'" class="container page">
        <!--
    **************************************************************************************************************************************************
                                                                N E W     I D E A
    **************************************************************************************************************************************************
    -->
        <section v-if="displayPart==10" class="card card-default">
            <div class="card-header">
                <div class="row">
                    <div class="col-sm-8"><h5>CEO Award Winners</h5></div>
                    <!--<div class="col-sm-4 text-right">
                        <a class="btn btn-primary" href="#" @click.prevent="displayPart=0">New CEO Award nomination</a>
                    </div>-->
                </div>
            </div>
            <div class="card-body">
                <div><content-db contentKey="ceowinners" :isCache="true"></content-db></div>


                <!--<h3 class="text-center"><b>2020 WINNERS</b></h3>
                <div>&nbsp;</div>

                <div class="row text-center" style="font-size:smaller">
                    <div class="col-sm-4 mb-3">
                        <div class="shadow-sm p-3 bg-white rounded text-center">
                            <img src="https://cdn.rewardedco.com/content/sanlam/ceoaward/ceowin2020-01.jpg" class="w-100" />
                        </div>-->
                        <!-- names to go here -->
                    <!--</div>
                    <div class="col-sm-4 mb-3">
                        <div class="shadow-sm p-3 bg-white rounded text-center">
                            <img src="https://cdn.rewardedco.com/content/sanlam/ceoaward/ceowin2020-02.jpg" class="w-100" />
                        </div>-->
                        <!-- names to go here -->
                    <!--</div>
                    <div class="col-sm-4 mb-3">
                        <div class="shadow-sm p-3 bg-white rounded text-center">
                            <img src="https://cdn.rewardedco.com/content/sanlam/ceoaward/ceowin2020-03.jpg" class="w-100" />
                        </div>-->
                        <!-- names to go here -->
                    <!--</div>
                    <div class="col-sm-4 mb-3">
                        <div class="shadow-sm p-3 bg-white rounded text-center">
                            <img src="https://cdn.rewardedco.com/content/sanlam/ceoaward/ceowin2020-04.jpg" class="w-100" />
                        </div>-->
                        <!-- names to go here -->
                    <!--</div>
                    <div class="col-sm-4 mb-3">
                        <div class="shadow-sm p-3 bg-white rounded text-center">
                            <img src="https://cdn.rewardedco.com/content/sanlam/ceoaward/ceowin2020-05.jpg" class="w-100" />
                        </div>-->
                        <!-- names to go here -->
                    <!--</div>
                    <div class="col-sm-4 mb-3">
                        <div class="shadow-sm p-3 bg-white rounded text-center">
                            <img src="https://cdn.rewardedco.com/content/sanlam/ceoaward/ceowin2020-06.jpg" class="w-100" />
                        </div>-->
                        <!-- names to go here -->
                    <!--</div>
                    <div class="col-sm-4 mb-3">
                        <div class="shadow-sm p-3 bg-white rounded text-center">
                            <img src="https://cdn.rewardedco.com/content/sanlam/ceoaward/ceowin2020-07.jpg" class="w-100" />
                        </div>-->
                        <!-- names to go here -->
                    <!--</div>
                    <div class="col-sm-4 mb-3">
                        <div class="shadow-sm p-3 bg-white rounded text-center">
                            <img src="https://cdn.rewardedco.com/content/sanlam/ceoaward/ceowin2020-08.jpg" class="w-100" />
                        </div>-->
                        <!-- names to go here -->
                    <!--</div>
                    <div class="col-sm-4 mb-3">
                        <div class="shadow-sm p-3 bg-white rounded text-center">
                            <img src="https://cdn.rewardedco.com/content/sanlam/ceoaward/ceowin2020-09.jpg" class="w-100" />
                        </div>-->
                        <!-- names to go here -->
                    <!--</div>


                </div>-->


                <!--<div><i>(There are currently no winners - watch this space!)</i></div>-->
                <hr />
                <!--<div class="text-center mt-5">
                    <a class="btn btn-primary" href="#" @click.prevent="displayPart=0">New CEO Award nomination</a>
                </div>-->
            </div>
        </section>


        <template v-if="displayPart==0">
            <div class="row mb-2">
                <div class="col-sm-8">
                    <h5>CEO Award</h5>
                    Select an Award below, then complete your nomination.
                </div>
                <!--<div class="col-sm-4 text-right">
                    <a href="#" @click.prevent="" class="btn btn-secondary">Back</a>
                </div>-->
            </div>
            <!--
    { value: '', text: '--- Please select an option ---' },
    { value: 'Individual', text: 'Individual' },
    { value: 'Team', text: 'Team' },
    { value: 'Innovation', text: 'Innovation' },
    { value: 'Young achiever', text: 'Young achiever < 35' },
    { value: 'General employee', text: 'General employee (non-management)' },
        -->
            <section class="card mb-4 nomAwardCategory3">
                <b-tabs v-model="tabIndex" lazy card nav-class="selcatNav">
                    <b-tab title="Leadership Award" @click="awardCatSelected('Leadership Award')">
                        <b-card-text class="text-left">
                            <h3>Leadership Award</h3>
                            <hr />
                            <p>This individual award acknowledges exemplary leadership that has had a significant and positive impact on profit and growth or delivered solutions in line with our business strategy.</p>
                            <p>Leading with clarity in uncertain times is worthy of being recognised and celebrated.</p>
                        </b-card-text>
                    </b-tab>
                    <b-tab title="Innovative Award" @click="awardCatSelected('Innovative Award')">
                        <b-card-text class="text-left">
                            <h3>Innovative Award</h3>
                            <hr />
                            <p>This award celebrates an individual (or team) making change work for them and for their business.</p>
                            <p>The achievement of improving or replacing a product, service or business process whilst adding value to the lives of others should be recognised and celebrated.</p>
                        </b-card-text>
                    </b-tab>
                    <b-tab title="Client Centric Award" @click="awardCatSelected('Client Centric Award')">
                        <b-card-text class="text-left">
                            <h3>Client Centric Award</h3>
                            <hr />
                            <p>Celebrating an individual (or team) who embody our promise of "Insurance Good and Proper" to our clients and other stakeholders.</p>
                            <p>Working to deliver on our promise, whilst creating value and growth for the Group is worthy of recognition.</p>
                        </b-card-text>
                    </b-tab>
                    <b-tab title="Everyday Hero Award" @click="awardCatSelected('Everyday Hero Award')">
                        <b-card-text class="text-left">
                            <h3>Everyday Hero Award</h3>
                            <hr />
                            <p>Recognising an employee who may not be in a senior or formal leadership position, but who leads from within. </p>
                            <p>This employee sees opportunity where others may see obstacles.</p>
                            <p>Spotting opportunity and inspiring others to work with you to turn opportunity into growth is worthy of being recognised and celebrated.</p>
                        </b-card-text>
                    </b-tab>
                    <b-tab title="Living Purposeful Award" @click="awardCatSelected('Living Purposeful Award')">
                        <b-card-text class="text-left">
                            <h3>Living Purposeful Award</h3>
                            <hr />
                            <p>This award celebrates and individual whose contribution has had a significant and positive impact on their community and society.</p>
                            <p>Their state of social conscience and selflessness is worthy of being recognised.</p>
                        </b-card-text>
                    </b-tab>
                </b-tabs>
            </section>

            <section class="card card-default mt-3" v-if="fNomination.form.awardCat !== null">
                <div class="card-header">
                    <h5>Nomination for: <b class="darkShade">{{ fNomination.form.awardCat }}</b></h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6"><strong>Nominees:</strong></div>
                        <div class="col-sm-6 text-right"></div>
                    </div>
                    <div v-for="r in selectedNominees" :key="r._rowNum" class="row">
                        <template v-if="r.isEntireDivision">
                            <div class="col-1 text-right"><b-icon icon="collection" style="color:#9f34a0"></b-icon></div>
                            <div class="col" style="color:#9f34a0">Entire division: {{ r.division }}</div>
                        </template>
                        <template v-else>
                            <div class="col-1 text-right"><b-icon icon="person" style="color:#ccc"></b-icon></div>
                            <div class="col">{{ r.firstname + ' ' + r.surname }}</div>
                            <div class="col">{{ r.division }}</div>
                        </template>
                    </div>
                    <div v-if="selectedNominees.length===0" class="text-danger">Please select nominees.</div>
                    <div class="mt-1">
                        <b-button type="button" pill @click="$bvModal.show('modalChooseNominees')" variant="primary" size="sm">{{selectedNominees.length===0 ? 'Select' : 'Update'}}  Nominees</b-button>
                        <!--<a href="#" @click.prevent="$bvModal.show('modalChooseNominees')">Select Nominees</a>-->
                    </div>

                    <hr />
                    <b-form @submit.prevent="onSubmitCEOaward" novalidate>
                        <!--
    <b-form-group id="groupAwardCat" label-for="selectAwardCat" label="Award Category:" :invalid-feedback="fNomination.errors.awardCat" description="">
        <b-form-select id="selectAwardCat" v-model="fNomination.form.awardCat" :options="awardCatList" value-field="value" text-field="text" :state="fNomination.state.awardCat" />
    </b-form-group>
       -->
                        <b-form-group id="groupMotivation" label-for="txtMotivation" label="Motivation:" :invalid-feedback="fNomination.errors.motivation" :description="1500-fNomination.form.motivation.length + ' characters remaining'">
                            <b-form-textarea id="txtMotivation" v-model="fNomination.form.motivation" :state="fNomination.state.motivation" placeholder="Provide a short motivation" rows="3" max-rows="5" maxlength="1500" />
                        </b-form-group>

                        <b-form-group id="groupActions" label-for="txtActions" label="Actions Taken" :invalid-feedback="fNomination.errors.actions" :description="1500-fNomination.form.actions.length + ' characters remaining'">
                            <b-form-textarea id="txtActions" v-model="fNomination.form.actions" :state="fNomination.state.actions" placeholder="Describe actions taken" rows="3" max-rows="5" maxlength="1500" />
                        </b-form-group>

                        <b-form-group id="groupMeasures" label-for="txtMeasures" label="Impact" :invalid-feedback="fNomination.errors.measures" :description="1500-fNomination.form.measures.length + ' characters remaining'">
                            <b-form-textarea id="txtMeasures" v-model="fNomination.form.measures" :state="fNomination.state.measures" placeholder="Impact thereof" rows="3" max-rows="5" maxlength="1500" />
                        </b-form-group>

                        <!--<b-form-group id="groupAchievements" label-for="txtAchievements" label="How does the nominated individual (and/or team) achievement/s support the Group business strategy?" :invalid-feedback="fNomination.errors.achievements" :description="1500-fNomination.form.achievements.length + ' characters remaining'">
                            <b-form-textarea id="txtAchievements" v-model="fNomination.form.achievements" :state="fNomination.state.achievements" placeholder="" rows="3" max-rows="5" maxlength="1500" />
                        </b-form-group>-->

                        <hr />
                        <!--                        <div class="row">
        <div class="col-sm-6"><strong>Seconders to support your nomination:</strong></div>
        <div class="col-sm-6 text-right"></div>
    </div>
    <div v-for="r in selectedNominators" :key="r._rowNum" class="row">
        <template v-if="r.isEntireDivision">
            <div class="col-1 text-right"><b-icon icon="collection" style="color:#9f34a0"></b-icon></div>
            <div class="col" style="color:#9f34a0">Entire division: {{ r.division }}</div>
        </template>
        <template v-else>
            <div class="col-1 text-right"><b-icon icon="person" style="color:#ccc"></b-icon></div>
            <div class="col">{{ r.firstname + ' ' + r.surname }}</div>
            <div class="col">{{ r.division }}</div>
        </template>
    </div>
    <div v-if="selectedNominators.length!==2" class="text-danger">Please select two seconders to support your nomination.</div>
    <div class="mt-1"><b-button type="button" pill @click="$bvModal.show('modalChooseNominators')" variant="primary" size="sm">{{selectedNominators.length===0 ? 'Select' : 'Update'}} Seconders</b-button></div>
                        <hr />
                        -->
                        <div class="text-right"><b-button type="submit" variant="primary">Submit</b-button></div>
                    </b-form>
                </div>
            </section>
        </template>

        <section v-if="displayPart==1" class="card card-default">
            <div class="card-header">
                <h5>CEO Award</h5>
            </div>
            <div class="card-body">
                <b-alert variant="success" show>
                    <h4 class="alert-heading">CEO Award Nomination</h4>
                    <p>
                        Successfully submitted the CEO Award Nomination.
                    </p>
                </b-alert>

                <!--<b-button type="button" pill @click="displayPart=10" variant="primary">Ok</b-button>-->

            </div>
        </section>



        <!--
    **************************************************************************************************************************************************
                                                                M O D A L S
    **************************************************************************************************************************************************
    -->
        <!-- Modal - Nominees -->
        <b-modal id="modalChooseNominees" title="NOMINEES" hide-footer size="xl" no-close-on-backdrop>
            <member-select title="Select Nominees for the CEO Award"
                           :isCountryFixed="true"
                           selectedTitle="Selected Nominees"
                           :defaultCountryCode="memberClusterCode"
                           :defaultDivisionCode="memberDivisionCode"
                           :selectedMems="selectedNominees"
                           :isAllowWholeDivision="false"
                           :showContinue="false"
                           excludeDivisionCode="sra_2104,sra_4181"
                           class="mb-3" />
            <hr />
            <div class="text-right"><b-button type="submit" variant="primary" @click="$bvModal.hide('modalChooseNominees')">Done</b-button></div>
        </b-modal>

        <!-- Modal - Nominators -->
        <b-modal id="modalChooseNominators" title="NOMINATORS" hide-footer size="xl" no-close-on-backdrop>
            <member-select title="Select two seconders to support your nomination"
                           selectedTitle="Selected seconders to support your nomination"
                           :defaultCountryCode="memberClusterCode"
                           :defaultDivisionCode="memberDivisionCode"
                           :selectedMems="selectedNominators"
                           :isAllowWholeDivision="false"
                           :showContinue="false"
                           excludeDivisionCode="sra_2104,sra_4181"
                           class="mb-3" />
            <hr />
            <div class="text-right"><b-button type="submit" variant="primary" @click="$bvModal.hide('modalChooseNominators')">Done</b-button></div>
        </b-modal>


    </div>

</template>

<script>
    // @ is an alias to /src
    //import HelloWorld from '@/components/HelloWorld.vue'
    //import SitecodeImage from '@/components/SitecodeImage';
    import axios from 'axios'
    //import sjData from '@/sjUtilData'
    import MemberSelect from '@/components/MemberSelect';
	import ContentDb from '@/components/ContentDb';



    export default {
        name: 'CEOaward',
        components: { MemberSelect, ContentDb },
        data() {
            return {
                displayPart: 0, //10

				fNomination: { //*** NB: isGroupCeoAward is false if Cluster CEO Award
					form: { isGroupCeoAward: false, awardCat: '', achievements: 'n/a', motivation: '', actions: '', measures: '' }
                },
                tabIndex: 0,

                selectedNominees: [],
                selectedNominators: [],

                roles: { ca: false, cc: false, idea: false, inv: false },

                reportTitle: '',
                currentIdeaRowNum: null,
                modalPart: 0,

                awardCatList: [
                    { value: '', text: '--- Please select an option ---' },
					{ value: 'Leadership Award', text: 'Leadership Award' },
					{ value: 'Innovative Award', text: 'Innovative Award' },
					{ value: 'Client Centric Award', text: 'Client Centric Award' },
					{ value: 'Everyday Hero Award', text: 'Everyday Hero Award' },
					{ value: 'Living Purposeful Award', text: 'Living Purposeful Award' },
					//{ value: 'Individual', text: 'Individual' },
					//{ value: 'Team', text: 'Team' },
                ]

            }
        },
        computed: {
            isLoggedIn() {
				return this.$store.state.isLoggedIn && this.memberDivisionCode != 'sra_2104' //Disable SanPay division from access
            },
            countryList() {
                if (this.isCountryFixed) { //only show the default cluster.
                    return this.$store.state.countryList.filter(country => country.countryCode === this.defaultCountryCode);
                } else {
                    return [{ countryCode: null, country: '-- Please select a Cluster--', disabled: true }]
                        .concat(this.$store.state.countryList);
                }
            },
            memberClusterCode() {
                return this.$store.state.memberStore.member.clusterCode
            },
            memberDivisionCode() {
                return this.$store.state.memberStore.member.divisionCode
            },
            currentIdea() {
                if (this.currentIdeaRowNum === null) return null;
                return this.ideas[this.currentIdeaRowNum];
            }
        },
        created() {
            //formSetState must be called on creation, to ensure all fields are setup
            this.formSetState(this.fNomination, true, true) //formObj, resetState, resetForm

            this.$store
                .dispatch('getDivisionList', {})
                .then(() => {
                    this.displayPart = 0;

                })
                .catch((error) => {
                    this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error)));
                });


        },
        mounted() {

        },
        methods: {

            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },

            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};

                    this.$nextTick(() => {
						this.awardCatSelected('Leadership Award');
                    });
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },

            awardCatSelected(awardCat) {
                let awardCats = this.awardCatList.map(e => { return e.value; });

                if (!awardCats.includes(awardCat)) {
                    this.msgBox('Cannot find "' + awardCat + '" in [' + awardCats.join(', ') + ']!');
                    return;
                }

                this.fNomination.form.awardCat = awardCat;
                //this.tabSelected = true;
            },

            onSubmitCEOaward() {
                this.formSetState(this.fNomination, true);//formObj, resetState, resetForm
                this.fNomination.form.nominees = this.selectedNominees.map(item => item.isEntireDivision ? 'div:' + item.entireDivisionCode : item.memberId);

                //let nominators = this.selectedNominators.filter(item => !item.isEntireDivision);
                //if (this.selectedNominees.length === 0) {
                //    this.msgBox('Please select at least one nominee.', 'Nominees are required');
                //    return;
                //}
                //if (nominators.length !== 2) {
                //    this.msgBox('Please select two seconders to support your nomination.', 'Two seconders are required');
                //    return;
                //}

                //this.fNomination.form.nominatorMemberId1 = nominators[0].memberId;
                //this.fNomination.form.nominatorMemberId2 = nominators[1].memberId;

                return axios
                    .post(
                        '/api/groupceoaward/newnomination',
                        this.fNomination.form
                    )
                    .then(({ data }) => {
                        console.log(data);
                        this.displayPart = 1;
                        //this.msgBox('Successfully submitted nomination');
                    })
                    .catch((error) => {
                        this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
                        if (error.response.data.formErrors) {
                            this.fNomination.errors = error.response.data.formErrors;
                            this.formSetState(this.fNomination);
                        }
                    })
                    ;
            },

        }




    }
</script>
