<template>
	<div v-if="adData && rotatorType==='adup'">
		<span v-html="JSON.stringify(adData)"></span>
	</div>
	<div v-else>
		<b-carousel :id="theRotatorId" :interval="3700"
					img-width="1024"
					img-height="480"
					:controls="controls"
					:indicators="indicators"
					>
			<!--
		controls
		indicators
		v-model="slide"
		:interval="3700"
		background="#ababab"
		img-width="1024"
		img-height="480"
		style="text-shadow: 1px 1px 2px #333;"
		@sliding-start="onSlideStart"
		@sliding-end="onSlideEnd"
		-->
			<template>
				<b-carousel-slide v-for="item in rotatorData" :key="item._rowNum">
					<template v-slot:img>
						<a v-if="item.href !== ''" :href="item.href" :target="item.href.startsWith('http:') ? '_blank' : '_self'">
							<img class="w-100" :src="imgFolder + item.img" />
						</a>
						<img v-else class="w-100" :src="imgFolder + item.img" /><!-- + profileNum >-->
					</template>

				</b-carousel-slide>
			</template>
		</b-carousel>
	</div>

</template>

<script>
	import axios from 'axios'
	import sjData from '@/sjUtilData'

	export default {
		name: 'adRotator',
		props: {
			rotatorType: String, //banner, topad, sidead, adup, adleft, adright, mobi
			indicators: { type: Boolean, default: false },
			controls: { type: Boolean, default: false },
			rotatorId: { type: String },
		},
		data() {
			return {
				adData: null
			}
		},
		computed: {
			isLoggedIn() {
				return this.$store.state.isLoggedIn
			},
			rotatorData() {
				if (!this.adData) return null;
				return this.adData.adverts.filter(d => d.what === this.rotatorType);
			},
			theRotatorId() {
				return this.rotatorId + '_' + this.rotatorType + '_rotator';
			},
			imgFolder() {
				return this.adData.imageBase + this.rotatorType + '/';
			}
		},
		mounted() {
			this.loadData()
		},
		watch: {
			//rotatorType(toKey) { //, from
			//	this.loadData(toKey)
			//}
		},
		methods: {
			loadData() {
				const isUseCache = true; //Change to true when not debugging
				const now = new Date();
				let dataStr = sessionStorage.getItem("adData");
				if (dataStr) {
					let data = JSON.parse(dataStr);
					if (isUseCache && data && new Date(data.exp) > now) {
						this.adData = {
							adverts: new sjData(data.advertData).data,
							imageBase: data.imageBase,
							isCacheHit: true
						}
						return;
					}
				}

				const expMins = 5;

				axios.post('/api/site/advertdata', {})
					.then(({ data }) => {
						data.exp = new Date(now.getTime() + expMins * 60000) //set expiry
						sessionStorage.setItem('adData', JSON.stringify(data));
						this.adData = {
							adverts: new sjData(data.advertData).data,
							imageBase: data.imageBase,
							isCacheHit: false
						}
						return;
					});
			}
		}
	}
</script>

