<template>
	<header v-if="isLoggedIn" class="topHead container-fluid d-print-none">
		<div v-if="isPPE" class="alert alert-danger" role="alert">
			<b>WARNING</b><br />
			You are accessing the STAGING / TEST environment.<br />
			This is using a dummy database, and no transactions will be committed.
		</div>

		<div class="row">
			<div class="col-md-9 topHeadLeft">

				<!-- Santam cluster -->
				<!--<div class="row" v-if="$store.state.siteTemplate == 'santamXXX'">
				<div class="col-lg-1"></div>
				<div class="col-lg-3 my-1 my-lg-2 px-4 text-left text-lg-right">
					<div style="position:relative">
						<img id="imgProfilePic" ref="imgProfilePic" :src="profilePicSrc" class="" style="position:absolute; left:0; top:0; z-index:10" />
						<span ref="topHeadLogoLeft"><sitecode-image src="topHeadLogoLeft.png" class="headprofilepic img-fluid" style="z-index:100; position:relative" /></span>
					</div>
				</div>

				<div class="col-lg-4 my-1 my-lg-5">
					<div class="mt-2 text-lg-left text-center"><span class="headHelloName" id="headHelloName">Hello {{ firstname }}</span></div>
					<div class="headPoints mt-4 text-lg-left text-center" id="headHelloPointsRedeem"><strong>{{ awardsPointsAvailable }}</strong> e-points to redeem</div>
					<div v-if="isManager" class="headPoints mt-1 text-lg-left text-center" id="headHelloPointsRecognise"><strong>{{ awardsPointsAvailableMng }}</strong> Manager e-points to award</div>
					<div  class="mb-2 headPoints mt-1 text-lg-left text-center" id="headHelloPointsPayItForward"><strong>{{ awardsPointsAvailable1  }}</strong> Peer to Peer e-points to award</div>
				</div>
				<div class="col-lg-4 mt-lg-5" style="font-size:2rem; color:#fff; font-weight:bold;">
					<div class="mt-lg-5 pt-lg-3">
						Realise your worth
					</div>
				</div>

			</div>-->
				<!-- All other clusters -->
				<div class="row">
					<div class="col-lg-2"></div>
					<div class="col-lg-3 my-1 my-lg-2 text-center text-lg-right pt-lg-5">
						<!-- All other clusters -->
						<a href="myprofile" title="Change profile picture or update details">
							<img id="imgProfilePic" :src="profilePicSrc" class="headprofilepic img-fluid" />
						</a>
					</div>

					<div class="col-lg-7 my-1 my-lg-5 headHelloName">
						<div class="mt-2 text-lg-left text-center"><span class="" id="headHelloName">Hello {{ firstname }}</span></div>
						<div v-if="isShowRedeem" class="headPoints mt-4 text-lg-left text-center" id="headHelloPointsRedeem"><strong>{{ awardsPointsAvailable }}</strong> e-points to redeem</div>
						<div v-if="isManager" class="headPoints mt-1 text-lg-left text-center" id="headHelloPointsRecognise"><strong>{{ awardsPointsAvailableMng }}</strong> Manager e-points to award</div>
						<div v-if="isRole_adh" class="headPoints mt-1 text-lg-left text-center" id="headHelloPointsRecognise"><strong>{{ awardsPointsAvailable1Mng }}</strong> Adhoc e-points to award</div>
						<div v-if="isShowPeerToPeer" class="mb-2 headPoints mt-1 text-lg-left text-center" id="headHelloPointsPayItForward"><strong>{{ awardsPointsAvailable1  }}</strong> Peer to Peer e-points to award</div>
					</div>

				</div>


			</div>
			<div class="col-md-3 topHeadRight text-center order-first order-md-last d-flex align-items-center justify-content-center py-3 px-3">
				<!--<img :src="imgUrl" class="img-fluid w-100" />-->
				<sitecode-image src="topHeadLogoRt.png" class="img-fluid" />
			</div>
		</div>
	</header>
    <header v-else class="topHead container-fluid">
        <div class="topHeadLeft">
            <div class="py-5" style="color:white; font-weight:bold;"></div>
        </div>
    </header>
</template>

<script>
	//
	//import { mapState } from 'vuex'
	import SitecodeImage from '@/components/SitecodeImage';
	export default {
		name: 'AppHeader',
		components: {
			'sitecode-image': SitecodeImage
		},
		computed: {
            isPPE() {
                return this.$store.state.memberStore.isPPE;
            },
			windowWidth() {
				return this.$store.state.windowWidth
			},
			isLoggedIn() {
				return this.$store.state.isLoggedIn
			},
			rootFolder() {
				return process.env.BASE_URL
			},
			//        imgUrl() {
			////return '../assets/image/topHeadLogo_' + this.$store.state.siteTemplate + '.png';
			//return require('../assets/image/topHeadLogo_' + this.$store.state.siteTemplate + '.png');
			//        },
			firstname() {
				return this.$store.state.memberStore.member.firstname
			},
            clusterCode() {
                return this.$store.state.memberStore.member.clusterCode
            },
            divisionCode() {
                return this.$store.state.memberStore.member.divisionCode
            },
			profilePicSrc() {
				return '/profilepic/' + this.$store.state.memberStore.member.picId + this.$store.state.memberStore.profilepicSfx
			},//,
			//...mapState({
			//    member: state => state.memberStore.member
			//})
			awardsPointsAvailable() {
				return this.$store.state.memberStore.member.awardsPointsAvailable
			},
			awardsPointsAvailable1() {
				return this.$store.state.memberStore.member.awardsPointsAvailable1
			},
			awardsPointsAvailableMng() {
				return this.$store.state.memberStore.member.awardsPointsAvailableMng
			},
            awardsPointsAvailable1Mng() {
                return this.$store.state.memberStore.member.awardsPointsAvailable1Mng
            },
			isManager() {
				return this.$store.state.memberStore.member.isManager
			},
			isClusterAdmin() {
				return this.$store.state.memberStore.member.role_ca
			},
			isRole_adh() {
				return this.$store.state.memberStore.member.role_adh
			},
			isShowRedeem() {
				let divisionCode = this.divisionCode;
                let clusterCode = this.clusterCode;
                if ((clusterCode === "sky" && this.isManager) || divisionCode === 'sra_2104' || divisionCode === 'sra_4181') {
                    return false; //All except Sky non managers, SanPay and Sanpay Managers
                }
                return true;
			},
            isShowPeerToPeer() {
                let divisionCode = this.divisionCode;
                let clusterCode = this.clusterCode;
                if ((clusterCode === "sky" && this.isManager) || divisionCode === 'sra_2104' || divisionCode === 'sra_4181') {
                    return false; //All except Sky non managers, SanPay and Sanpay Managers
                }
                return true;
            },
		},
		mounted() {
			this.$nextTick(() => {
				this.handleResize();
			});
		},
		updated() {
			this.$nextTick(() => {
				this.handleResize();
			});
		},
		watch: {
			windowWidth() { //newWidth, oldWidth
				//console.log(`width changed to ${newWidth} from ${oldWidth}`);
				this.handleResize();
			}
		},
		methods: {
			handleResize: function () {
				if (this.$store.state.siteTemplate != 'santamXXX') return; //Only Santam needs sizing
				if (!this.$refs.topHeadLogoLeft) {
					console.log('handleResize: cannot find topHeadLogoLeft');
					setTimeout(this.handleResize, 250)
					return;
				}

				let h = this.$refs.topHeadLogoLeft.firstElementChild.offsetHeight; //$('.homeicon1').innerHeight();
				let w = this.$refs.topHeadLogoLeft.firstElementChild.offsetWidth; //$('.homeicon1').innerWidth();

				if (w < 3 || h < 3) {
					//console.log('handleResize: topHeadLogoLeft w or h = 0');
					setTimeout(this.handleResize, 250)
					return;
				}

				let sw = w / 354;
				let sh = h / 298;

				console.log('handleResize: topHeadLogoLeft w: ' + sw + ', h: ' + sh);
				this.$refs.imgProfilePic.style.left = (sw * 122) + "px";
				this.$refs.imgProfilePic.style.top = (sh * 68) + "px";
				this.$refs.imgProfilePic.style.width = (sw * 222) + "px";
				this.$refs.imgProfilePic.style.height = (sh * 222) + "px";
			}
		}
	}
</script>

