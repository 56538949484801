<template>
	<div v-if="isLoggedIn" class="container page">


		<div class="row">
			<div class="col-md-8">
				<div class="row">


					<div v-for="c in contacts" :key="c.name" class="col-md-6">
						<section class="card mb-3">
							<div class="card-header font-weight-bold text-center">
								{{c.cluster}}
							</div>
							<div class="card-body">
								<div class="row no-gutters">
									<div class="col-2"><b-icon icon="person-fill" class="rounded-circle" style="width:2rem; height:2rem; padding:4px; color:#999; border: #999 solid 2px;" /></div>
									<div class="col-10">
										<div><b>{{c.name}}</b></div>
										<div><small>{{c.title}}</small></div>
									</div>
								</div>
								<hr />
								<div class="row no-gutters">
									<div class="col-2"><b-icon icon="telephone-fill" class="rounded-circle" style="width:2rem; height:2rem; padding:4px; color:#999; border: #999 solid 2px;" /></div>
									<div class="col-10">
										<div><b>Call:</b></div>
										<div><small>{{c.phone}}</small></div>
									</div>
								</div>
								<hr />
								<div class="row no-gutters">
									<div class="col-2"><b-icon icon="envelope-fill" class="rounded-circle" style="width:2rem; height:2rem; padding:4px; color:#999; border: #999 solid 2px;" /></div>
									<div class="col-10">
										<div><b>Email:</b></div>
										<div><small><a :href="('mailto:' + c.email)">{{c.email}}</a></small></div>
									</div>
								</div>
							</div>
						</section>
					</div>



				</div>


			</div>
			<div class="col-md-4" style="border-left:solid 1px #ccc">

				<section v-if="!isDone" class="card card-default">
					<div class="card-header">
						<h4>Contact Us</h4>
					</div>
					<div class="card-body">
						<div class="alert alert-info" role="alert">
							For voucher queries (eg. Lost voucher numbers etc), please use this contact us form.
						</div>
						<hr />
						<!--<div>
							<svg width="31" height="30" class="_96z7 _brandPortalIcon__whatsappLogo" fill="none"><title id="whatsapp-logo">WhatsApp logo</title><path d="M15.565 0C7.057 0 .133 6.669.13 14.865c-.002 2.621.71 5.179 2.06 7.432L0 30l8.183-2.067a15.89 15.89 0 007.376 1.81h.006c8.508 0 15.432-6.67 15.435-14.866.002-3.97-1.602-7.707-4.517-10.516C23.569 1.551 19.694.001 15.565 0zm0 27.232h-.005c-2.302 0-4.56-.596-6.53-1.722l-.47-.268-4.854 1.226 1.296-4.56-.305-.467a11.983 11.983 0 01-1.962-6.576C2.738 8.052 8.494 2.511 15.57 2.511c3.426.001 6.647 1.288 9.07 3.623s3.756 5.44 3.754 8.742c-.003 6.813-5.758 12.356-12.83 12.356zm7.037-9.255c-.386-.185-2.282-1.084-2.636-1.209-.353-.123-.61-.187-.867.185-.256.372-.996 1.209-1.22 1.456-.226.248-.451.278-.837.093-.386-.186-1.629-.578-3.101-1.844-1.147-.984-1.921-2.2-2.146-2.573-.225-.371-.024-.572.169-.757.173-.165.386-.433.578-.65.192-.217.256-.372.386-.62.128-.247.064-.465-.033-.65-.097-.187-.867-2.015-1.19-2.758-.312-.724-.63-.627-.867-.639-.225-.01-.481-.013-.74-.013-.255 0-.674.093-1.028.465-.353.372-1.35 1.27-1.35 3.098 0 1.829 1.382 3.595 1.575 3.843.193.247 2.72 4 6.589 5.61.92.381 1.638.61 2.199.782.924.283 1.765.242 2.429.147.74-.107 2.282-.898 2.602-1.765.322-.867.322-1.611.226-1.766-.094-.155-.352-.248-.738-.435z" fill="currentColor"></path></svg>
							<a href="https://wa.me/27609413669?text=Hi+Applause!+Can+you+help+me+with+" target="_blank"> Contact us on WhatApp</a>
						</div>-->
						<hr />
						<b-form @submit.prevent="onSubmit" novalidate>
							<div style="margin-bottom: 15px"><b>Send us a message:</b></div>

							<b-alert v-model="alertMessageVisible" :variant="alertVariant" dismissible>{{ alertMessage }}</b-alert>

							<b-form-group id="groupContactName" label-for="txtContactName" label="Your Name:" :invalid-feedback="formErrors.ContactName" description="">
								<b-form-input id="txtContactName" v-model="form.ContactName" :state="formState.ContactName" type="text" placeholder="Enter your name" />
							</b-form-group>

							<b-form-group id="groupEmail" label-for="txtEmail" label="Email Address:" :invalid-feedback="formErrors.EmailAddress" description="">
								<b-form-input id="txtEmail" v-model="form.EmailAddress" type="text" :state="formState.EmailAddress" placeholder="Enter your email address" />
							</b-form-group>

							<b-form-group id="groupMessage" label-for="txtMessage" label="Message:" :invalid-feedback="formErrors.Message" description="">
								<b-form-textarea id="txtMessage" v-model="form.Message" :state="formState.Message" placeholder="Enter a short message" rows="10" max-rows="16" />
							</b-form-group>

							<div>
								<b-form-checkbox id="chkAcceptTerms" switch v-model="form.AcceptTerms" :state="formState.AcceptTerms"><content-db contentKey="contactusterms" :isCache="true"></content-db></b-form-checkbox>
							</div>

							<div class="text-right"><b-button type="submit" variant="primary">Send Message</b-button></div>
						</b-form>

					</div>
				</section>
				<section v-else class="card card-default">
					<div class="card-header">
						<h4>Contact Us</h4>
						<div><span ID="ContentDb2" ContentKey="topmsgcontactus" runat="server" /></div>
					</div>
					<div class="card-body">
						<b-alert show variant="success">Message Successfully Sent</b-alert>

						<h6 class="card-subtitle text-muted">Contact Name</h6>
						<div class="mb-3">{{form.ContactName}}</div>

						<h6 class="card-subtitle text-muted">Email Address</h6>
						<div class="mb-3">{{form.EmailAddress}}</div>

						<h6 class="card-subtitle text-muted">Message</h6>
						<div class="preline">{{form.Message}}</div>
						<hr />
						<div class="text-right"><a class="btn btn-secondary mr-3" href="/contactus">Send Another Message</a><a class="btn btn-primary" href="/">Home</a></div>

					</div>
				</section>

			</div>

		</div>

		

	</div>

</template>

<script>



	// @ is an alias to /src
	//import HelloWorld from '@/components/HelloWorld.vue'
	//import SitecodeImage from '@/components/SitecodeImage';
	import axios from 'axios'
	import ContentDb from '@/components/ContentDb';

	export default {
		name: 'ContactUs',
		components: { ContentDb },
		data() {
			return {

				contacts: [
					//{ cluster: 'Sanlam Corporate', name: 'Amo Mauwane', title: 'Rewards Administrator', phone: '012 683 3419', email: 'Amo.Mauwane@sanlam.co.za' },
					{ cluster: 'Sanlam Corporate', name: 'Mariam Toffey', title: 'Rewards Administrator', phone: '021 947 2135', email: 'Mariam.Toffey@sanlam.co.za' },
                    { cluster: 'Sanlam Corporate', name: 'Nikki Wilks', title: 'Rewards Administrator', phone: '021 947 1813', email: 'nicolette.wilks@sanlam.co.za' },

                    { cluster: 'Group Office', name: 'Karen Sakko', title: 'Rewards Administrator', phone: '021 947 6940', email: 'Karen.Sakko@sanlam.co.za' },
					/*{ cluster: 'Group Office', name: 'Adon Zwartz', title: 'Rewards Administrator', phone: '021 947 2699', email: 'Adon.Zwartz@sanlam.co.za' },*/
					{ cluster: 'Group Office', name: 'Anthea Prins', title: 'Rewards Administrator', phone: '021 947 7502', email: 'Anthea.Prins@sanlam.co.za' },
					/*{ cluster: 'Life & Savings', name: 'Celeste Vermaak', title: 'HR Business Partner', phone: '021 947 2551', email: 'Celeste.Vermaak@sanlam.co.za' },*/
					{ cluster: 'Santam', name: 'Maryke Kotze', title: 'Rewards Administrator', phone: '021 915 7173', email: 'Maryke.Kotze@santam.co.za' },
                    { cluster: 'Sanlam Investment Group', name: 'Michelle Solomon', title: 'Rewards Administrator', phone: '061 956 9268', email: 'michelleso@sanlaminvestments.co.za' },
                    { cluster: 'Retail Affluent', name: 'Claudine Jacobs', title: 'Rewards Administrator', phone: '021 947 3204', email: 'Claudine.Jacobs@sanlam.co.za' },
                    { cluster: 'Retail Affluent', name: 'Sandy Andrews', title: 'Rewards Administrator', phone: '021 947 4968', email: 'Sandy.Andrews@sanlam.co.za' },
					/*{ cluster: 'Retail Mass', name: 'Malikah Ramlah', title: 'Rewards Manager', phone: '011 359 7805', email: 'MalikahR@sanlamsky.co.za' },*/
					{ cluster: 'Retail Mass', name: 'Rosemary Davis', title: 'Rewards Administrator', phone: '082 650 1187', email: 'RosemaryD@sanlamsky.co.za' },
                    { cluster: 'Sanlam Fintech', name: 'Petro Jacobs', title: 'Rewards Administrator', phone: '021 947 5495', email: 'Petro.Jacobs@sanlam.co.za' },

				],

				form: { ContactName: '', EmailAddress: '', Message: '', AcceptTerms: false },
				formErrors: {},
				formState: {},
				isValidated: true,
				alertMessageVisible: false,
				alertMessage: '',
				alertVariant: 'success', //'danger', 'warning'
				nullValue: null,
				falseValue: false,
				isDone: false
			}
		},
		computed: {
			isLoggedIn() {
				return this.$store.state.isLoggedIn
			},
			isValid() {
				//if (w == '') return false;
				return true;
			}
		},
		mounted() {
			this.formStateUpdate(true);
		},
		methods: {
			formStateUpdate(init) {

				var fields = Object.getOwnPropertyNames(this.form);
				for (let i = 0; i < fields.length; i++) {
					let field = fields[i];
					if (init) {
						this.formState[field] = null;
						this.formErrors = {}; //reset formErrors object
					} else if (typeof this.formErrors[field] === 'undefined' || this.formErrors[field] === '') {
						this.formState[field] = true;
						console.log("field: " + field + " " + JSON.stringify(this.formState[field]));
					} else {
						this.formState[field] = false;
						console.log("field: " + field + " false");
					}
				}
			},

			onSubmit() {
				this.alertMessageVisible = false;


				console.log("Form: " + JSON.stringify(this.form));
				this.formStateUpdate(true);

				return axios
					.post(
						'/api/site/contactussend',
						this.form
					)
					.then(({ data }) => {
						console.log('Successful: ' + data);
						this.isDone = true;
						//this.formStateUpdate(false);
					})
					.catch((error) => {
						this.alertMessage = error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data));
						this.alertMessageVisible = true;
						this.alertVariant = 'warning';
						if (error.response.data.formErrors) {
							this.formErrors = error.response.data.formErrors;
							this.formStateUpdate(false);
						}
						//this.isValidated = true;
					})
					;
			}
		}

	}
</script>
