<template>
    <div class="card card-default">
        <div class="card-header">
            <h5>{{ title }}</h5>
        </div>
        <div class="card-body">
            <div class="form-group row">
                <label class="col-lg-1 col-form-label" for="country">Cluster:</label>
                <div class="col-lg-5"><b-form-select v-model="selectedCountryCode" :options="countryList" value-field="countryCode" text-field="country" v-on:change="resetDivision($event)"></b-form-select></div>
                <label class="col-lg-1 col-form-label" for="division">Division:</label>
                <div class="col-lg-5"><b-form-select v-model="selectedDivisionCode" :options="divisionList" value-field="divisionCode" text-field="division"></b-form-select></div>
            </div>
            <div class="form-group row">
                <label class="col-lg-1 col-form-label" for="txtSearch">Search:</label>
                <div class="col-sm-4" style="padding-bottom:5px"><b-form-input name="txtSearch" v-model="search" placeholder="" maxlength="20" /></div>
                <div class="col-sm-1"><input type="button" id="searchButton" value="Search" class="btn btn-primary" v-on:click="onSearchButtonClick" /> </div>
            </div>
            <div class="scrollBox" style="padding: 15px 0; border-top: #eee solid 1px; border-bottom: #eee solid 1px;">
                <div v-if="selectedDivisionCode==null && search==''"><i>Please select a division or search all divisions</i></div>
                <template v-else>
                    <div class="row" style="">
                        <div class="col-1 text-right"><b-icon icon="collection" style="color:#9f34a0"></b-icon></div>
                        <div class="col-11">
                            <a v-if="isAllowWholeDivision" href="#" v-on:click.prevent="onAddRecipient(-1)" v-b-tooltip.rightbottom.hover title="Add entire division" style="color:#9f34a0">Entire division: {{ this.selectedDivisionName }}</a>
                            <span v-else style="color:#9f34a0">{{ this.selectedDivisionName }}</span>
                        </div>
                    </div>

                    <div v-for="r in memberList" :key="r._rowNum" class="row">
                        <template v-if="r.memberId.substring(0, 2) === '0.'">
                            <div class="col-1 text-right"><b-icon icon="exclamation-circle" style="color:#efe700"></b-icon></div>
                            <div class="col"><span style="color:red" v-b-tooltip.rightbottom.hover.v-danger title="This user has not registered">{{ r.firstname + ' ' + r.surname }}</span></div>
                            <div class="col" style="color:red">{{ r.division }}</div>
                        </template>
                        <template v-else>
                            <div class="col-1 text-right"><b-icon icon="person" style="color:#ccc"></b-icon></div>
                            <div class="col"><a href="#" v-on:click.prevent="onAddRecipient(r._rowNum)">{{ r.firstname + ' ' + r.surname }}</a></div>
                            <div class="col">{{ r.division }}</div>
                        </template>
                    </div>
                </template>
            </div>
            <div :style="{minHeight: isSingle ? '' : '8rem'}">
                <div><label for="divSelectedRecipientNames" style="font-weight:bold; margin-top:5px;" id="divRecipientCount">{{ selectedTitle }}:</label></div>

                <div v-if="selectedMembers.length === 0" class="alert alert-warning">None Selected.</div>
                <template v-else>
                    <div v-for="r in selectedMembers" :key="r._rowNum" class="row">
                        <template v-if="r.isEntireDivision">
                            <div class="col-1 text-right"><b-icon icon="collection" style="color:#9f34a0"></b-icon></div>
                            <div class="col" style="color:#9f34a0">Entire division: {{ r.division }}</div>
                            <div class="col-1"><a href="#" v-b-tooltip.rightbottom.hover title="Remove" v-on:click.prevent="onRemoveRecipient(r._serialNo)" style="font-size:larger"><b-icon icon="x"></b-icon></a></div>
                        </template>
                        <template v-else>
                            <div class="col-1 text-right"><b-icon icon="person" style="color:#ccc"></b-icon></div>
                            <div class="col">{{ r.firstname + ' ' + r.surname }}</div>
                            <div class="col">{{ r.division }}</div>
                            <div class="col-1"><a href="#" v-b-tooltip.rightbottom.hover title="Remove" v-on:click.prevent="onRemoveRecipient(r._serialNo)" style="font-size:larger"><b-icon icon="x"></b-icon></a></div>
                        </template>
                    </div>
                </template>
            </div>
        </div>
        <div v-if="showContinue" class="card-footer">
            <button type="button" class="btn btn-primary" v-on:click="onContinue" :disabled="selectedMembers.length==0">Continue</button>
            <!--<button type="button" class="btn btn-primary" v-on:click="onCancel" >Cancel</button>-->
        </div>
    </div>
</template>

<script>

    import sjData from '@/sjUtilData'
    import axios from 'axios'
    import { sjDebounce } from '@/sjDebounce'


    export default {
        name: 'MemberSelect',
        components: {
        },
        data() {
            return {
                selectedCountryCode: null,
                selectedDivisionCode: null,
                cachedMemberList: {
                    //sanlamcorporate: [...],
                    //safrican: [...]
                },
                currentMemberList: [],
                search: '',

                selectedMembersSerialNo: 0
            }
        },
        props: {
            title: { type: String, required: false, default: 'Choose Recipients' },
            selectedTitle: { type: String, required: false, default: 'Selected recipients' },
            includeSelf: {
                type: Boolean, required: false, default: false
            },
            defaultCountryCode: {
                type: String, required: false, default: null
            },
            defaultDivisionCode: {
                type: String, required: false, default: null
            },
            isCountryFixed: {
                type: Boolean, required: false, default: false
            },
            isAllowWholeDivision: {
                type: Boolean, required: false, default: true
            },
            isSingle: {
                type: Boolean, required: false, default: false
            },
            showContinue: {
                type: Boolean, required: false, default: true
            },
            selectedMems: {
                type: Array, required: true, default() { return [] }
            },
            excludeCountryCode: {
                type: String, required: false, default: ''
            },
            excludeDivisionCode: {
                type: String, required: false, default: ''
            },
            memberType: {
                type: String, required: false, default: '' //Used to pull only a special member type - i.e. 'human-capital'
            },
        },
        computed: {
            selectedMembers() { // we do this to prevent mutating selectedMems
                return this.selectedMems
            },
            alltext() {
                return this.title + "..." + this.msg;
            },

            countryList() {
                if (this.isCountryFixed) { //only show the default cluster.
                    return this.$store.state.countryList.filter(country => country.countryCode === this.defaultCountryCode);
                } else {
                    return [{ countryCode: null, country: '-- Please select a Cluster--', disabled: true }]
                        .concat(this.$store.state.countryList);
                }
            },

            divisionList() {
                return [{ divisionCode: null, division: '-- Please select an Division--', disabled: false }]
                    .concat(this.$store.state.divisionList.filter(division => division.countryCode === this.selectedCountryCode && this.excludeCountryCode !== this.selectedCountryCode));
            },

            memberList() {
                if (this.excludeCountryCode === this.selectedCountryCode) {
                    return null;
                }
                //console.log('excludeDivisionCode:' + this.excludeDivisionCode);
				//console.log('selectedDivisionCode:' + this.selectedDivisionCode);

                if (this.excludeDivisionCode.includes(this.selectedDivisionCode)) {
                    return null;
                }

                let isDivisionSelected = this.selectedDivisionCode != null;
                if (isDivisionSelected) {
                    if (this.search == '') {
                        return this.currentMemberList; //return full list for this division
                    } else {
                        //debugger;
                        return this.currentMemberList.filter(mem => (mem.firstname + ' ' + mem.surname).toUpperCase().indexOf(this.search.trim().toUpperCase()) !== -1); //search entered, so return filtered results on search
                    }
                } else {
                    return this.currentMemberList; //no division selected, so if a search is entered, then return search results on all division's members.
                }
            },

            selectedDivisionName() {
                if (typeof this === undefined) return '';
                return this.selectedDivisionCode == null ? '' : this.$store.state.divisionList.filter(division => division.divisionCode === this.selectedDivisionCode)[0].division;
            }
        },

        watch: {
            selectedDivisionCode(divisionCode) {  //newDivisionCode, oldDivisionCode - called when divisionCode changes
                this.search = ''; //divisionCode changed, so reset search
                this.doGetMemberData(divisionCode);
            },
            search: sjDebounce(function () {
                if (this.selectedDivisionCode == null) {
                    this.doGetMemberData(null);
                }
            }, 800)
        },
        methods: {
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },
            resetDivision() {
                this.selectedDivisionCode = null;
            },
            doGetMemberData(divisionCode) { //this is called from divsionCode changed, and search changed
                //console.log((new Date).getTime() + ' doGetMemberData: ' + this.search);
                this.currentMemberList = [];
                let axiosParams = null;
                if (divisionCode == null) { //no division selected
                    if (this.search === '') {
                        //this.currentMemberList = [];
                        return;
                    } else {
                        axiosParams = { countryCode: this.selectedCountryCode, divisionCode: '', search: this.search, includeSelf: this.includeSelf, excludeCountryCode: this.excludeCountryCode, memberType: this.memberType }; //search all divisions in cluster
                    }
                } else { //division has been selected
                    let isMemberListCached = typeof this.cachedMemberList[this.selectedDivisionCode] !== 'undefined';
                    if (isMemberListCached) {
                        this.currentMemberList = this.cachedMemberList[this.selectedDivisionCode];
                        return;
                    } else {
                        axiosParams = { countryCode: this.selectedCountryCode, divisionCode: divisionCode, search: '', includeSelf: this.includeSelf, excludeCountryCode: this.excludeCountryCode, memberType: this.memberType }; //return all member data for current division
                    }
                }

                axios.post('/api/site/getmemberlist', axiosParams)
                    .then(({ data }) => {
                        this.currentMemberList = new sjData(data.memberList).data;
                        if (this.selectedDivisionCode !== null) {
                            let selectedDivision = this.$store.state.divisionList.filter(division => division.divisionCode === this.selectedDivisionCode)[0]
                            this.currentMemberList.forEach(item => {
                                item.division = selectedDivision.division
                            });
                            this.cachedMemberList[divisionCode] = this.currentMemberList
                        }
                    });
            },

            onSearchButtonClick() {
                if (this.selectedDivisionCode == null) {
                    this.doGetMemberData(this.selectedDivisionCode); // No division is selected, so perform a search on all divisions
                }
            },

            onAddRecipient(rownum) {
                if (rownum == -1) {
                    //check if this division exists, or if any members of this division exist
                    if (!this.isAllowWholeDivision) {
                        this.msgBox('Cannot add ' + this.selectedDivisionName + '. Only individuals can be selected', 'Cannot add entire division');
                        return;
                    }
                    if (this.selectedMembers.some((e) => e.isEntireDivision && e.entireDivisionCode === this.selectedDivisionCode)) {
                        this.msgBox('Entire division ' + this.selectedDivisionName + ' has already been added', 'Cannot add entire division');
                        return;
                    }
                    if (this.selectedMembers.some((e) => !e.isEntireDivision && e.division === this.selectedDivisionName)) {
                        this.msgBox('Cannot add ' + this.selectedDivisionName + ', as members from this division already added. Remove these members first.', 'Cannot add entire division');
                        return;
                    }

                    this.selectedMembers.push({
                        _serialNo: this.selectedMembersSerialNo++,
                        division: this.selectedDivisionName,
                        firstname: '',
                        surname: '',
                        isManager: false,
                        memberId: '',
                        memberNumber: '',
                        isEntireDivision: true,
                        entireDivisionCode: this.selectedDivisionCode
                    });

                } else {
                    if (this.isSingle && this.selectedMembers.length != 0) {
                        this.msgBox('A member has already been selected. Only one member may be chosen. Please remove the existing member first to select a different member.', 'Cannot add member');
                        return;
                    }
                    let selMember = this.currentMemberList[rownum];

                    if (this.selectedMembers.some((e) => e.isEntireDivision && e.entireDivisionCode === this.selectedDivisionCode)) {
                        this.msgBox('Cannot add an individual member from ' + this.selectedDivisionName + ', as the entire division already added.', 'Cannot add member');
                        return;
                    }
                    if (this.selectedMembers.some((e) => !e.isEntireDivision && e.memberNumber === selMember.memberNumber)) {
                        this.msgBox(selMember.firstname + ' ' + selMember.surname + ' has already been added.', 'Cannot add member');
                        return;
                    }

                    this.selectedMembers.push({
                        _serialNo: this.selectedMembersSerialNo++,
                        division: selMember.division,
                        firstname: selMember.firstname,
                        surname: selMember.surname,
                        isManager: selMember.isManager,
                        memberId: selMember.memberId,
                        memberNumber: selMember.memberNumber,
                        isEntireDivision: false,
                        entireDivisionCode: ''
                    });
                }
            },

            onRemoveRecipient(serialNo) {
                this.selectedMembers.splice(this.selectedMembers.findIndex((e) => e._serialNo === serialNo), 1);
            },

            onContinue() {
                this.$emit('continue', this.selectedMembers);
            },

            onCancel() {
                this.$emit('cancel');
            }
        },

        created() {

            this.selectedCountryCode = this.defaultCountryCode;
            this.selectedDivisionCode = this.defaultDivisionCode;
        }
    }
</script>

