<template v-if="isLoggedIn">
    <div class="container-fluid page">

        <div class="card card-default" style="min-width:420px">
            <div class="card-header">
                <div class="row">
                    <div class="col-sm-6 font-weight-bold">
                        <h5>Santam Broker Solutions Award Endorsements</h5>
                    </div>

                    <div class="col-sm-3">

                    </div>
                    <b-button @click="loadReport()" variant="primary">Refresh</b-button>
                    <!--<div class="col-sm-3 text-right">
                    <b-button class="d-print-none mr-3" size="sm" @click.prevent="windowPrint" :disabled="report.length==0">Print</b-button>
                    <b-dropdown class="d-print-none mr-3" text="Export to CSV">
                        <b-dropdown-item href="#" @click.prevent="exportToCSV('utf', false)">Standard</b-dropdown-item>
                        <b-dropdown-item href="#" @click.prevent="exportToCSV('utf', true)">Alternate</b-dropdown-item>
                        <b-dropdown-item href="#" @click.prevent="exportToCSV('ascii', false)">ASCII</b-dropdown-item>
                        <b-dropdown-item href="#" @click.prevent="exportToCSV('ascii', true)">ASCII Alternate</b-dropdown-item>
                        <b-dropdown-item href="#" @click.prevent="exportToCSV('compat', false)">Compatibility</b-dropdown-item>
                        <b-dropdown-item href="#" @click.prevent="exportToCSV('compat', true)">Compatibility Alternate</b-dropdown-item>
                    </b-dropdown>
                </div>-->
                </div>

            </div>
            <div class="card-body d-print-none" v-if="hasRunOnce && !isLoading">
                <div v-if="report.length==0"><i>You have no nominations that require endoresement</i></div>
                <div v-else>You have been requested to confirm if you endorse the following nomination{{report.length==1?"":"s"}} for the Santam Broker Solutions Awards.</div>
            </div>
        </div>

        <div v-if="hasRunOnce && !isLoading" style="font-size:0.8rem">
            <div v-for="f in report" :key="f._rowNum" class="mb-2 mt-3" style="page-break-inside:avoid;page-break-before:always;display:block">
                <div class="card card-default">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-6" style="font-size:1.2rem">Nomination for: <b>{{ f.awardCat }}</b></div>
                            <div class="col-md-6 text-right" style=""><b>Ref:</b> {{ f.awardId.sjRemoveIntHash() }}</div>
                        </div>
                    </div>
                    <div class="card-body">
                        <!--<div class="row mb-3">
                        <div class="col-md-2 fldNameC">Status:</div>
                        <div class="col-md-10">{{ f.awardStatusDesc}}<br />{{ f.refInfo }}</div>
                    </div>-->

                        <div class="row mb-1">
                            <div class="col-md-2 fldNameC">Nominated By:</div>
                            <div class="col-md-10">{{ f.nominatedByMember }}</div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-2 fldNameC">Date:</div>
                            <div class="col-md-10 fldValueC">{{ f.nominatedDate.sjToDate('d MMM yyyy') }} </div>
                        </div>

                        <div class="mb-3">
                            <div class="fldNameC">Nominees:</div>
                            <div class="fldValueC">{{ f.nominees.sjReplaceAll(';','\r\n') }}</div>
                        </div>
                        <div class="mb-3">
                            <div class="fldNameC">Motivation:</div>
                            <div class="fldValueC">{{ f.motivation }}</div>
                        </div>
                        <div class="mb-3">
                            <div class="fldNameC">Actions Taken:</div>
                            <div class="fldValueC">{{ f.action }}</div>
                        </div>
                        <div class="mb-3">
                            <div class="fldNameC">Impact</div>
                            <div class="fldValueC">{{ f.impact }}</div>
                        </div>
                        <hr />
                        <div class="mb-3">
                            <div style="font-weight:bold; font-size:larger">Do you endorse this nomination?</div>
                            <div class="fldValue">
                                <div>
                                    <b-button @click.prevent="showEndorse(true, f)">Yes</b-button>
                                    <b-button @click.prevent="showEndorse(false, f)">No</b-button>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>


        </div>

      
        <div v-if="isLoading"><i>Loading...</i></div>
        


        <!-- Modal - Add new rating -->
        <b-modal id="modalEndorseComment" title="Confirm Endorsement and Comment" hide-footer>
            <b-form @submit.prevent="endorementSubmit" novalidate>
                <div class="mb-3" v-if="fEndorse.form.isEndorsed">Please comment on why you <b>endorse</b> this nomination</div>
                <div class="mb-3" v-else>Please comment on why you <b>do not endorse</b> this nomination</div>

                <b-form-group id="groupEndorseComment" label-for="txtEndorseComment" :invalid-feedback="fEndorse.errors.comment" :description="1500-fEndorse.form.comment.length + ' characters remaining'">
                    <b-form-textarea id="txtEndorseComment" v-model="fEndorse.form.comment" :state="fEndorse.state.comment" placeholder="" rows="3" maxlength="1500" />
                </b-form-group>

                <div class="text-right"><b-button @click="$bvModal.hide('modalEndorseComment')">Cancel</b-button> <b-button type="submit" variant="primary">Submit</b-button></div>

            </b-form>
        </b-modal>


    </div>

   
</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    import sjData from '@/sjUtilData'


    export default {
        name: 'ReportSanlamAward',
        data() {
            return {
                monthList: [],
                selMonthFrom: null,
                selMonthTo: null,
                awardStatus: 'W',
                report: [],
                isLoading: false,
                hasRunOnce: false,
                selRec: null,
                fEndorse: {
                    form: { awardId: null, isEndorsed: null, comment: '' }
                },
            }
        },
        computed: {
            member() {
                return this.$store.state.memberStore.member
            },

            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
        },

        created() {
            this.formSetState(this.fEndorse, true, true) //formObj, resetState, resetForm

            //let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            //const result = [];
            //let curYear = new Date().getFullYear();
            //let curMonth = new Date().getMonth();

            //for (let y = curYear; y >= curYear - 3; y--) {
            //    let mStart = y == curYear ? curMonth : 11;
            //    for (let m = mStart; m >= 0; m--) {
            //        let t = months[m] + ' ' + y;
            //        if (result.length === 0) this.selMonthFrom = t;
            //        if (result.length === 0) this.selMonthTo = t;
            //        result.push({ value: t, text: t });
            //    }
            //}
            //this.monthList = result;
            this.loadReport();
        },
        mounted() {
            //axios.post('/api/member/memberget', {})
            //	.then(({ data }) => {
            //		//this.roles = data.roles;
            //		//this.noms = new sjData(data.noms).data;
            //		//this.displayPart = 1;
            //	})
            //	.catch((error) => {
            //		if (error) {
            //			this.msgBox(error.response.data.message);
            //		}
            //	})
        },
        watch: {
            //selMonth: function () {
            //    if (this.selMonth !== null) this.loadReport();
            //},
            //awardStatus: function () {
            //    if (this.awardStatus !== null) this.loadReport();
            //}
        },

        methods: {
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },
            loadReport() {
                this.isLoading = true;
                this.report = [];
                axios.post('/api/sanlamaward/endorserlistnoms', { awardType:"santam-broker-solutions" })
                    .then(({ data }) => {
                        this.isLoading = false;
                        this.hasRunOnce = true;
                        this.report = new sjData(data.report).data;

                    })
                    .catch((error) => {
                        this.isLoading = false;
                        if (error) {
                            this.msgBox(error.response.data.message);
                        }
                    })
            },
            showEndorse(isEndorse, rec) {
                this.formSetState(this.fEndorse, true, true) //formObj, resetState, resetForm
                this.fEndorse.form.isEndorsed = isEndorse;
                this.selRec = rec;
                this.$bvModal.show('modalEndorseComment');
            },
            endorementSubmit() {
                this.formSetState(this.fEndorse, true);//formObj, resetState, resetForm
                this.fEndorse.form.awardId = this.selRec.awardId;
                return axios
                    .post(
                        '/api/sanlamaward/endorsementsubmit',
                        this.fEndorse.form
                    )
                    .then(() => {
                        this.$bvModal.hide('modalEndorseComment');
                        this.msgBox('Successfully submitted your comment', 'Submit Endorsement and Comment');
                        this.loadReport();
                    })
                    .catch((error) => {
                        if (error) {
                            this.msgBox(error.response.data.message);
                            if (error.response.data.formErrors) {
                                this.fEndorse.errors = error.response.data.formErrors;
                                this.formSetState(this.fEndorse);
                            }
                        }
                    })
                    ;
            },
			exportToCSV(mode, isAlt) {
				//All: line breaks in cell are only \n 0A

				//isAlt False (Windows) - end of row: \r\n 0D 0A
				//isAlt True (Mac) - end of row: \r 0D

				//mode: utf - file prefix: EF BB BF
				//mode: ascii - file prefix: none
				//mode: compat - replace all line breaks in cell with - (dash)

				const cellLineBreak = mode === "compat" ? "-" : "\n";
				const rowLineBreak = isAlt ? "\r" : "\r\n";
				const filePrefix = mode === "utf" ? "\uFEFF" : "";  // nb: "\uFEFF" generates byte order mark "\xEF\xBB\xBF"
				const isAscii = mode === "ascii";
				const asciiReplaceChar = " "; //"\u00B7";

				let fx = function (val) {
					try {
						let s = (val + "").trim(); //https://blog.zsec.uk/csv-dangers-mitigations/
						while (s.length > 1 && s.indexOf("=") == 0 || s.indexOf("+") == 0 || s.indexOf("@") == 0 || (!isNaN(s) && s.indexOf("-") == 0)) {
							s = s.substr(1).trim();
						}

						if (s.indexOf("@") > -1 || s.indexOf("+") > -1 || s.indexOf("-") > -1 || s.indexOf("=") > -1 || s.indexOf("|") > -1 || s.indexOf("%") > -1) {
							s = "'" + s.sjReplaceAll("|", "\\|") + "'";
						}

						if (s.indexOf("\"") > -1 || s.indexOf("\r") > -1 || s.indexOf("\n") > -1 || s.indexOf(",") > -1) {
							if (isAscii) s = s
								.sjReplaceAll("U+201C", '"')
								.sjReplaceAll("U+201D", '"')
								.sjReplaceAll("U+2018", "'").sjReplaceAll("�", "'")
								.sjReplaceAll("U+2019", "'")
								.replace(/[\u{00FF}-\u{FFFF}]/gu, asciiReplaceChar);
							s = "\"" + s.sjReplaceAll("\r\n", cellLineBreak).sjReplaceAll("\r", cellLineBreak).sjReplaceAll("\n", cellLineBreak) + "\"";

						}
						return s;
					} catch {
						return 'Error';
					}
				}

				let csv = filePrefix + 'Ref,Date,Award Category,Motivation,Action,Impact,Nominated By,EndorsementStatus,EndorsedBy,Status,Nominees\r\n';

                for (var n = 0; n < this.report.length; n++) {
                    let f = this.report[n];
                    //debugger;
                    csv += `${f.awardId},${fx(f.nominatedDate.sjToDate('d MMM yyyy HH:mm'))},${fx(f.awardCat)},${fx(f.motivation)},${fx(f.action)},${fx(f.impact)},${fx(f.nominatedByMember)},${fx(f.endorserStatusDesc)},${fx(f.endorserMember)},${fx(f.awardStatusDesc)},${fx(f.nominees)}${rowLineBreak}`;
                }


                var pom = document.createElement('a');
                var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                var url = URL.createObjectURL(blob);
                pom.href = url;
                pom.setAttribute('download', 'report.csv');
                pom.click();
            },
            windowPrint() {
                window.print();
            },
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            }


        }

    }
</script>
