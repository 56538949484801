<template>
    <!--<div class="container page">-->
    <div v-if="isLoggedIn" class="container page">
        <!--<h1>{{contentTitle}}</h1>-->
        <content-db :contentKey="theKey" :isCache="true" v-on:title-onset="contentTitle=$event"></content-db>
    </div>
</template>


<script>
    // import axios from 'axios'
    import ContentDb from '@/components/ContentDb';
    export default {
        name: 'ContentPage',
        components: { ContentDb },
        watch: {
            ////    $route(to) { //, from
            ////        this.theKey = to.params.contentKey;
            ////    },
            //contentKey(toKey) { //, from
            //    alert('watch');
            //    this.theKey = toKey;
            //}
        },
        beforeRouteUpdate(to, from, next) {
            console.log('ContentPage.vue beforeRouteUpdate ' + this.theKey);
            this.theKey = to.params.contentKey;
            next();
        },
        props: {
            contentKey: String
        },
        data: function () {
            return {
                contentTitle: '',
                theKey: ''
            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.state.isLoggedIn
            }
        },
        created() {
            console.log('ContentPage.vue created ' + this.theKey);
            this.theKey = this.$route.params.contentKey;
        }
    }
</script>
