<template>
    <div v-if="isLoggedIn" class="container page">

        <b-alert v-if="!isAllowAccess" show>Only authorised personnel may access this module.</b-alert>

        <section v-if="displayPart==0 && isAllowAccess" class="card card-default">
            <div class="card-header">
                <div class="row">
                    <div class="col-sm-6 font-weight-bold">
                        <h5>Group CEO Award Administration</h5>
                    </div>

                    <div class="col-sm-3">

                    </div>
                    <div class="col-sm-3 text-right">
                        <router-link to="/reportgroupceoaward" class="btn btn-secondary">Reports</router-link>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div v-if="nominations.length===0">
                    <b-alert show>No Nominations found!</b-alert>
                </div>

                <div v-for="r in nominations" :key="r._rowNum">
                    <section class="card card-default mb-5">
                        <div class="card-header">
                            <div class="row">

                                <div class="col-sm-6 font-weight-bold">
                                    {{ r.nominatedByMember }}
                                </div>
                                <!--<div class="col-sm-4">
         r.cluster + ' / ' + r.division
    </div>-->
                                <div class="col-sm-3 text-right">
                                    {{ r.nominatedDate.sjToDate('d MMM yyyy HH:mm')}}
                                </div>
                                <div class="col-sm-3 text-right">
                                    Ref: {{ r.ceoawardId.sjRemoveIntHash() }}
                                </div>

                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-lg-2 fldNameB">Nominees:</div>
                                <div class="col-lg-10 fldValueB">
                                    <div v-for="nominee in r.nominees" :key="nominee._rowNum">
                                        {{ nominee.isEntireDivision ? ('Division: ' + nominee.division) : (nominee.fullname + ' (' + nominee.division + ')') }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2" v-if="r.refType !== ''">
                                <div class="col-lg-2 fldNameB">{{ r.refType == 'awardNom' ? 'Award Nomination' : 'Innovation Award' }}:</div>
                                <div class="col-lg-10 fldValueB">{{ r.refId.sjRemoveIntHash() + ': ' + r.refDescription }} </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-lg-2 fldNameB">Award Category:</div>
                                <div class="col-lg-10 fldValueB">{{ r.awardCat}} </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-lg-2 fldNameB">Motivation:</div>
                                <div class="col-lg-10 fldValueB">{{ r.motivation }} </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-lg-2 fldNameB">Actions:</div>
                                <div class="col-lg-10 fldValueB">{{ r.actions }}</div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-lg-2 fldNameB">Measures:</div>
                                <div class="col-lg-10 fldValueB">{{ r.measures}}</div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-lg-2 fldNameB">Achievements:</div>
                                <div class="col-lg-10 fldValueB">{{ r.achievements}}</div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-lg-2 fldNameB">Nominators:</div>
                                <div class="col-lg-10 fldValueB">{{ r.nominatorMember1 + '\r\n' + r.nominatorMember2 }}</div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-lg-2 fldNameB">Submitter:</div>
                                <div class="col-lg-10 fldValueB">{{ r.nominatedByMember }}</div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-lg-2 fldNameB">Status:</div>
                                <div class="col-lg-4 fldValueB">{{ r.awardStatusDesc }}</div>
                                <div class="col-lg-6 text-right">
                                    <template>
                                        <b-button @click="onStatusChange(r._rowNum, 'W')" class="mr-1" size="sm" variant="primary">
                                            Reward as winner
                                        </b-button>
                                        <b-button @click="onStatusChange(r._rowNum, 'R')" class="mr-1" size="sm" variant="primary">
                                            Runner Up
                                        </b-button>
                                        <b-button @click="onStatusChange(r._rowNum, 'C')" class="mr-1" size="sm" variant="primary">
                                            Conclude
                                        </b-button>
                                    </template>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
            </div>
        </section>

        <section v-if="displayPart==1" class="card card-default">
            <div class="card-header">
                <h5>Group CEO Award</h5>
            </div>
            <div class="card-body">
            </div>
        </section>

        <section v-if="displayPart==2" class="card card-default">
            <div class="card-header">
                <div class="row">
                    <div class="col-sm-6 font-weight-bold">
                        <h5>Group CEO Award Reports</h5>
                    </div>

                    <div class="col-sm-3">

                    </div>
                    <div class="col-sm-3 text-right">
                        <router-link to="/reportceoaward" class="btn btn-sm">Reports</router-link>
                    </div>
                </div>

            </div>
            <div class="card-body">
                
                <div class="row">
                    <div class="col-lg-3 font-weight-bold text-lg-center">
                        <b-input-group size="sm">
                            <b-input-group-prepend is-text>
                                <b-icon icon="calendar"></b-icon>
                            </b-input-group-prepend>
                            <b-form-select :options="reportMonths" v-model="filterMonth">
                                <template v-slot:first>
                                    <b-form-select-option :value="null">-- All Periods --</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-input-group>
                    </div>
                    <div class="col-lg-3">
                        <b-input-group size="sm" v-if="roles.cc || roles.ca">
                            <b-input-group-prepend is-text>
                                <b-icon icon="card-checklist"></b-icon>
                            </b-input-group-prepend>
                            <b-form-select v-model="filterStatus">
                                <b-form-select-option :value="null">-- All Statuses --</b-form-select-option>
                                <b-form-select-option value="W">Winners</b-form-select-option>
                                <b-form-select-option value="R">Runner Ups</b-form-select-option>
                                <b-form-select-option value="WR">Winner and Runner Ups</b-form-select-option>
                                <b-form-select-option value="C">Concluded</b-form-select-option>
                            </b-form-select>
                        </b-input-group>
                    </div>
                </div>

            </div>
        </section>

        <!--
    **************************************************************************************************************************************************
                                                                M O D A L S
    **************************************************************************************************************************************************
    -->

    </div>

</template>

<script>
    // @ is an alias to /src
    //import HelloWorld from '@/components/HelloWorld.vue'
    //import SitecodeImage from '@/components/SitecodeImage';
    import axios from 'axios'
    import sjData from '@/sjUtilData'
    //import MemberSelect from '@/components/MemberSelect';


    export default {
        name: 'GroupCEOawardAdmin',
        //components: { MemberSelect },
        data() {
            return {
                displayPart: 0,
                roles: { ca: false, cc: false, idea: false, inv: false },
				nominations: []
            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
			isAllowAccess() {
				let m = this.$store.state.memberStore.member;
				return m.role_ceo && (m.role_ca || m.role_cc)
			},
            countryList() {
                if (this.isCountryFixed) { //only show the default cluster.
                    return this.$store.state.countryList.filter(country => country.countryCode === this.defaultCountryCode);
                } else {
                    return [{ countryCode: null, country: '-- Please select a Cluster--', disabled: true }]
                        .concat(this.$store.state.countryList);
                }
            },
            memberClusterCode() {
                return this.$store.state.memberStore.member.clusterCode
            },
            memberDivisionCode() {
                return this.$store.state.memberStore.member.divisionCode
            },

            
        },
        created() {
            //formSetState must be called on creation, to ensure all fields are setup
            this.loadNominations();

        },
        mounted() {

        },
        methods: {

            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },

            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },

			loadNominations() {
				
				axios.post('/api/groupceoaward/nomlist', { })
					.then(({ data }) => {
						this.roles = data.roles;
                        this.nominationsLoaded(data);
					})
					.catch((error) => {
						if (error) {
							this.msgBox(error.response.data.message);
						}
					})
			},
            nominationsLoaded(data) {
				let nominations = new sjData(data.noms).data;
				let nominees = new sjData(data.nominees).data;

				for (const nom of nominations) {
					nom.nominees = nominees.filter(rec => rec.ceoawardId == nom.ceoawardId); //append owners array to each idea
				}
				this.nominations = nominations;
			},
            onStatusChange(row, status) { //row, status
                let statusDesc = '';
                if (status === 'W') statusDesc = 'a Winner';
                else if (status === 'R') statusDesc = 'a Runner-Up';
                else if (status === 'C') statusDesc = 'Concluded (No-Winner)';
				else statusDesc = '(Not decided as yet)';


                this.$bvModal.msgBoxConfirm(`Confirm you wish to allocate this nomination as ${statusDesc}?`, { title: 'Change Nomination Status' })
					.then(value => {
                        if (value) {
                            this.nominations[row].awardStatus = status;
                            this.nominations[row].awardStatusDesc = statusDesc;

							axios.post('/api/groupceoaward/setstatus', { ceoawardId: this.nominations[row].ceoawardId, awardStatus: status  })
								.then(({ data }) => {
                                    this.nominationsLoaded(data);
								})
								.catch((error) => {
									if (error) {
										this.msgBox(error.response.data.message);
									}
								})
						}
					})
                    .catch(err => {
                        console.log(err);
						// An error occurred
					})
			},

            onSubmitCEOaward() {
                this.formSetState(this.fNomination, true);//formObj, resetState, resetForm
                this.fNomination.form.nominees = this.selectedNominees.map(item => item.isEntireDivision ? 'div:' + item.entireDivisionCode : item.memberId);
                let nominators = this.selectedNominators.filter(item => !item.isEntireDivision);
				if (this.selectedNominees.length === 0) {
					this.msgBox('Please select at least one nominee.','Nominees are required');
					return;
				} 
                if (nominators.length !== 3) {
                    this.msgBox('Please select three nominators.', 'Three nominators are required');
                    return;
				} 
				this.fNomination.form.nominatorMemberId1 = nominators[0].memberId;
				this.fNomination.form.nominatorMemberId2 = nominators[1].memberId;
				this.fNomination.form.nominatorMemberId3 = nominators[2].memberId;

                return axios
                    .post(
                        '/api/groupceoaward/newnomination',
                        this.fNomination.form
                    )
                    .then(({ data }) => {
                        console.log(data);
                        this.displayPart = 1;
                        //this.msgBox('Successfully submitted nomination');
                    })
                    .catch((error) => {
                        this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
                        if (error.response.data.formErrors) {
                            this.fNomination.errors = error.response.data.formErrors;
                            this.formSetState(this.fNomination);
                        }
                    })
                    ;
            },

        }




    }
</script>
