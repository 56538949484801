<template>
    <section v-if="isLoggedIn" class="container homePg">
        <div class="my-3">
            <content-db contentKey="homebanner" :isCache="true"></content-db>
            <!--<router-link to="/groupceoaward" class="hvrlinkbutton">
            <img src="https://cdn.rewardedco.com/content/sanlam/homebanner.gif" class="img-fluid" />

        </router-link>-->
            <!--<section v-if="false" class=" mt-3 homeBannerTop">
            <div class="">
                <div style="font-size:larger" class="text-center">
                    <router-link to="/groupceoaward" class="hvrlinkbutton">
                        Nominate your Group CEO Awards candidates now! Have the chance to choose who will be worthy of being awarded for; Everyday hero, innovation, young achiever, team award, individual excellence.
                    </router-link>
                </div>
            </div>
        </section>-->
        </div>
        <div v-if="memberClusterCode=='stm'" class="mb-3">
            <img src="https://cdn.rewardedco.com/content/sanlam/homebannersantam.png" class="img-fluid" />
        </div>

        <div class="row">
            <div class="col-lg-8">
                <div class="row">
                    <div class="col-lg-6 mb-4">
                        <template v-if="memberDivisionCode != 'sra_2104'">
                            <!-- No Access to SanPay-->
                            <router-link to="/groupceoaward" class="hvrlinkbutton"><span ref="homeicon1"><sitecode-image src="homeIcon1.jpg" class="img-fluid homeIcon1" /></span></router-link>
                        </template>
                        <template v-else>
                            <span ref="homeicon1"><sitecode-image src="homeIcon1.jpg" title="Service not available for your profile" v-b-tooltip.hover.left class="img-fluid homeIcon1" /></span>
                        </template>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <div class="row no-gutters" style="max-width:460px;">
                            <div class="col">
                                <sitecode-image src="homeIcon2L.jpg" class="img-fluid" />
                            </div>
                            <div class="col" style="line-height:0">
                                <div><sitecode-image src="homeIcon2r1.jpg" class="img-fluid" /></div>
                                <div>
                                    <router-link v-if="isManager && memberDivisionCode != 'sra_2104'" to="/reward"><sitecode-image src="homeIcon2r2.jpg" class="img-fluid" /></router-link>
                                    <sitecode-image v-else src="homeIcon2r2blank.jpg" class="img-fluid" />
                                </div>
                                <div><sitecode-image src="homeIcon2r3.jpg" class="img-fluid" /></div>


                                <div v-if="memberClusterCode!='sky' && memberDivisionCode != 'sra_2104' && memberDivisionCode != 'sra_4181'">
                                    <router-link to="/rewardpeertopeer" class="Xhvrlinkbutton"><sitecode-image src="homeIcon2r4.jpg" class="img-fluid" /></router-link>
                                </div>
                                <div v-else><sitecode-image src="homeIcon2r4.jpg" class="img-fluid" title="Service not available for your profile" v-b-tooltip.hover.left /></div>

                                <div><sitecode-image src="homeIcon2r5.jpg" class="img-fluid" /></div>
                            </div>
                        </div>
                    </div>

                    <div v-if="memberClusterCode!='sky' && memberDivisionCode != 'sra_2104' && memberDivisionCode != 'sra_4181' " class="col-lg-6 mb-4" id="divLinkRewards">
                        <sitecode-image src="homeIcon3top.jpg" class="img-fluid d-block" />
                        <router-link to="/rewards" class="d-block"><sitecode-image src="homeIcon3redeem.jpg" class="img-fluid" /></router-link>
                        <sitecode-image src="homeIcon3sp1.jpg" class="img-fluid d-block" />
                        <router-link to="/rewardpayitforward" class="d-block"><sitecode-image src="homeIcon3PIF.jpg" class="img-fluid" /></router-link>
                        <sitecode-image src="homeIcon3sp2.jpg" class="img-fluid d-block" />
                    </div>
                    <div v-else class="col-lg-6 mb-4" id="divLinkRewards">
                        <sitecode-image src="homeIcon3top.jpg" class="img-fluid d-block" />
                        <sitecode-image src="homeIcon3redeem.jpg" class="img-fluid d-block" title="Service not available for your profile" v-b-tooltip.hover.left  />
                        <sitecode-image src="homeIcon3sp1.jpg" class="img-fluid d-block" />
                        <sitecode-image src="homeIcon3PIF.jpg" class="img-fluid" title="Service not available for your profile" v-b-tooltip.hover.left   />
                        <sitecode-image src="homeIcon3sp2.jpg" class="img-fluid d-block" />
                    </div>
                    <div class="col-lg-6 mb-4">
                        <router-link to="/recognise" class="hvrlinkbutton"><sitecode-image src="homeIcon4.jpg" class="img-fluid" /></router-link>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <!-- runat="server" id="divLinkInnov"-->
                        <!-- innovation idea is disabled -->
                        <template v-if="true">
                            <router-link to="/innovationidea" class="hvrlinkbutton"><sitecode-image src="homeIcon5.jpg" class="img-fluid" /></router-link>
                        </template>
                        <template v-else>
                            <a href="#" @click.prevent="msgBox('Innovative Ideas under construction and will be enhanced.  Watch this space.','Innovative Ideas')"><sitecode-image src="homeIcon5.jpg" class="img-fluid" title="Innovative Ideas is not available" /></a>
                        </template>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <template v-if="memberDivisionCode != 'sra_2104'">
                            <router-link to="/awardnomination" class="hvrlinkbutton"><sitecode-image src="homeIcon6.jpg" class="img-fluid" /></router-link>
                        </template>
                        <template v-else>
                            <sitecode-image src="homeIcon6.jpg" title="Service not available for your profile" v-b-tooltip.hover.left class="img-fluid" />
                        </template>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="homeShoutout d-flex flex-column mb-4" ref="homeShoutout">
                    <div class="homeRightTitle mb-1"><router-link to="/recentrecognition">Recent Recognition</router-link></div>
                    <div class="shoutouts align-self-stretch" id="divShoutOutData">
                        <div v-for="item in recentRecognition" :key="item._rowNum" class="shoutoutitem">
                            <div class="shoutto">{{ item.memberName }}</div>
                            <div>{{ item.value }}</div>
                        </div>
                    </div>
                    <div v-if="memberClusterCode!=='stm'" class="text-center my-3"><router-link to="/recentrecognition" class="btn btn-secondary btn">View More</router-link></div>
                    <div v-else class="text-center my-3"><router-link to="/recentrecognition"><sitecode-image style="max-width:250px" src="homeViewMore.png" class="img-fluid" /></router-link></div>
                </div>
                <div class="homeGetToKnow" ref="homeGetToKnow">
                    <div class="homeRightTitle" ref="homeRightTitle"><router-link to="/gettoknow">Get to know</router-link></div>

                    <!-- <div id="homeGtkCarousel" class="carousel slide" data-ride="carousel" data-interval="3700">
                        <div class="carousel-inner" role="listbox" id="homeGtkCarouselSlides">  -->
                    <b-carousel id="homeGtkCarousel" :interval="3700" controls
                                img-width="1024"
                                img-height="480">
                        <!--
                    controls
                    indicators
                    v-model="slide"
                    :interval="3700"
                    background="#ababab"
                    img-width="1024"
                    img-height="480"
                    style="text-shadow: 1px 1px 2px #333;"
                    @sliding-start="onSlideStart"
                    @sliding-end="onSlideEnd"
                    -->
                        <template>
                            <b-carousel-slide v-for="item in getToKnow" :key="item._rowNum">
                                <template v-slot:img>
                                    <!-- + (isActiveDone ? " : " active")     class="homeGtkSlide carousel-item" -->
                                    <div class="row no-gutters align-items-center homeGtkSlide" :style="{height: homeGtkSlideHeight}">
                                        <div class="col-4 homeGtkPic">
                                            <div class="ml-3 mr-2">
                                                <img class="w-100" :src="('/profilepic/' + item.memberId)" /><!-- + profileNum >-->
                                            </div>
                                        </div>
                                        <div class="col-8">
                                            <div class="ml-1 mr-2">
                                                <div class="homeGtkName">{{ item.memberName }}</div>
                                                <div class="homeGtkPos">{{ item.natureOfBusiness }}</div>
                                                <div class="homeGtkData"><b>Likes:</b> {{ item.interestOtherDesc }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </b-carousel-slide>
                        </template>
                    </b-carousel>
                    <!-- <div class="text-right homeGtkLink"><a href="gettoknow">MORE</a></div>-->
                </div>

            </div>
        </div>

        <section class="row hellostats">
            <div class="col-4 col-lg-2 hellostatslg" id="divRewardsRedeemed">{{ rewardsRedeemed }}&nbsp;</div>
            <div class="col-8 col-lg-2 hellostats">Rewards <span class="statsbr"><br /></span>Redeemed</div>
            <div class="clearfix visible-sm-block"></div>
            <div class="col-4 col-lg-2 hellostatslg" id="divEcardsSent">{{ messagesSent }}&nbsp;</div>
            <div class="col-8 col-lg-2 hellostats">eCards <span class="statsbr"><br /></span>Sent</div>
            <div class="clearfix visible-sm-block"></div>
            <div class="col-4 col-lg-2 hellostatslg" id="divRewardsIssued">{{ rewardsIssued }}&nbsp;</div>
            <div class="col-8 col-lg-2 hellostats">Rewards <span class="statsbr"><br /></span>Issued</div>
        </section>
    </section>



        <!--<HelloWorld msg="Welcome to Your Vue.js App"/>-->
</template>

<script>
    // @ is an alias to /src
    //import HelloWorld from '@/components/HelloWorld.vue'
    import SitecodeImage from '@/components/SitecodeImage';
	import ContentDb from '@/components/ContentDb';

    export default {
        name: 'Home',
        components: { SitecodeImage, ContentDb },
        data() {
            return {
				homeGtkSlideHeight: 0, //10

            }
        },
        computed: {
            isPPE() {
                return this.$store.state.memberStore.isPPE;
            },
            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
            memberClusterCode() {
                return this.$store.state.memberStore.member.clusterCode
            },
            memberDivisionCode() {
                return this.$store.state.memberStore.member.divisionCode
            },
            isManager() {
                return this.$store.state.memberStore.member.isManager
            },
            recentRecognition() {
                return this.$store.state.memberStore.homeRecentRecognition
            },
            getToKnow() {
                return this.$store.state.memberStore.homeGetToKnow
            },
            messagesSent() {
                return this.$store.state.memberStore.homeStats.messagesSent
            },
            rewardsIssued() {
                return this.$store.state.memberStore.homeStats.rewardsIssued
            },
            rewardsRedeemed() {
                return this.$store.state.memberStore.homeStats.rewardsRedeemed
            },
            windowWidth() {
                return this.$store.state.windowWidth
            },
            isPhase2() {
                return true;
                //let clusterCode = this.$store.state.memberStore.member.clusterCode;
                //return clusterCode != 'saf' && clusterCode != 'sky' && clusterCode != 'srm' && clusterCode !== 'lsm';
                //return clusterCode != 'saf' && clusterCode != 'sky' && clusterCode != 'sra' && clusterCode != 'srm' && clusterCode !== 'lsm';
            }

        },
        mounted() {
            this.$nextTick(() => {
                //console.log('home.vue next tick mounted');
                this.handleResize();
                //setTimeout(this.handleResize, 5000);
            });
        },
        updated() {
            this.$nextTick(() => {
                //console.log('home.vue next tick updated');
                this.handleResize();
                //setTimeout(this.handleResize, 5000);
            });
        },
        watch: {
            windowWidth() { //newWidth, oldWidth
                //console.log(`width changed to ${newWidth} from ${oldWidth}`);
                this.handleResize();
            }
        },
        methods: {
			msgBox(msg, title) {
				this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
			},
            handleResize: function () {
                if (!this.$refs.homeicon1 || !this.$refs.homeicon1.firstElementChild) {
                    //console.log('handleResize: cannot find homeicon1');
                    setTimeout(this.handleResize, 250)
                    return;
                }

                let h = this.$refs.homeicon1.firstElementChild.offsetHeight; //$('.homeicon1').innerHeight();
                let w = this.$refs.homeicon1.firstElementChild.offsetWidth; //$('.homeicon1').innerWidth();
                if (w < 50 || h < 50) {
                    //console.log('handleResize: homeicon1 w or h = 0');
                    setTimeout(this.handleResize, 250)
                    return;
                }
                //console.log('handleResize: homeicon1 w: ' + w + ', h: ' + h);

                this.$refs.homeShoutout.style.height = (h * 2 + 24) + "px";
                this.$refs.homeShoutout.style.width = w + "px";
                this.$refs.homeGetToKnow.style.height = h + "px";
                this.$refs.homeGetToKnow.style.width = w + "px";

                let titleHeight = this.$refs.homeRightTitle.offsetHeight;

                this.homeGtkSlideHeight = (h - titleHeight) + "px";


            }
        }
    }
</script>
