<template>
    <div class="about">
        <h1>This is an about page</h1>
        <router-link to="/about">Go to About</router-link><br />
        <router-link to="/about/bob">Go to About/Bob</router-link><br />
        <router-link to="/about/joe">Go to About/Joe</router-link><br />
        <!--
    <b-carousel id="carousel-1"
                v-model="slide"
                :interval="2000"
                controls
                indicators
                background="#ababab"
                style="text-shadow: 1px 1px 2px #333;">



        <b-carousel-slide caption="Blank Image" img-blank img-alt="Blank image" v-slot:img>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eros felis, tincidunt
                a tincidunt eget, convallis vel est. Ut pellentesque ut lacus vel interdum.
            </p>
        </b-carousel-slide>
        <b-carousel-slide caption="Blank Image2" img-blank img-alt="Blank image" v-slot:img>
            <p>
                Blah
            </p>
        </b-carousel-slide>
    </b-carousel>
        -->
    </div>
</template>


<script>
    // import axios from 'axios'

    export default {
        name: 'About',
        data: function () {
            return {

            }
        },
        created() {
            console.log('*created - ' + this.$route.params.urlParam);
        },
        beforeRouteEnter(to, from, next) {
            console.log('*beforeRouteEnter - ' + to.params.urlParam);

            next(vm => { // access to component instance via `vm` - i.e. use 'vm' instead of 'this'
                if (vm) {
                    console.log('*beforeRouteEnter_Nexted - ' + vm.$route.params.urlParam);
                }

            });
        },
        // when route changes and this component is already rendered, the logic will be slightly different.
        beforeRouteUpdate(to, from, next) {
			console.log('*beforeRouteUpdate(to) - ' + to.params.urlParam);
            next()
        },
    }
</script>