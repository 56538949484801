<template>
    <div>
        <router-view class="page" />
    </div>
</template>

<script>
    export default {
        data() {
            return {
            }
        },
        created() {
            console.log('Login.vue - redirecting to SSO login');
			window.location.href = "/sso/idplogin";
            return;
        },
        methods: {
        }
    }
</script>
