<template>
    <!--<div v-if="memberClusterCode==='sem'" class="container page">Innovative Ideas is not available for your cluster ({{memberClusterCode}})</div>-->
      <div v-if="isLoggedIn" class="container page">

          <template v-if="displayPart==0">
              <h3>INNOVATIVE IDEAS</h3>
              <!-- IdeaStatus: 0: Submitted (Anyone can like and comment)    1:under review (with administrator)    2:under review (with panel)    9. Concluded (Idea not accepted)   10. Concluded (Idea accepted) -->

              <div v-if="isCanReview || isSpecialistIdeasWaiting || adminPanelEvalCount!=0 || panelistCount!=0" class="alert alert-info" role="alert">
                  <div v-if="panelistCount!=0 && memberClusterCode!=='sig' && memberClusterCode!=='sc'"><b>Panellist:</b> Your division has <b>{{panelistCount}} ideas</b> to be reviewed (incl Specialist Responded).</div>
                  <div v-if="adminPanelEvalCount!=0"><b>Admin:</b> Your cluster has <b>{{adminPanelEvalCount}} ideas</b> to be discussed at Panel Evaluation.</div>
                  <div v-if="isSpecialistIdeasWaiting"><b>Specialist:</b> You have outstanding ideas to comment on as a specialist. Click the Specialist Feedback tab.</div>
                  <div v-if="isCanReview"><i>Panelist Review and Administrator functions are specific to your cluster only.</i></div>
              </div>

              <div class="text-right"><a href="#" @click.prevent="onShowNewIdea">Create new idea</a></div>

              <div class="card">
                  <div class="card-header">
                      <b-nav card-header tabs class="">
                          <b-nav-item :active="seltab==='ideas'" @click.prevent="seltab='ideas';loadIdeas()">Ideas</b-nav-item>
                          <b-nav-item :active="seltab==='my_ideas'" @click.prevent="seltab='my_ideas';loadIdeas()">My Ideas</b-nav-item>
                          <b-nav-item v-if="isSpecialistIdeasWaiting" :active="seltab==='specialist'" @click.prevent="seltab='specialist';loadIdeas()">Specialist Feedback</b-nav-item>
                          <b-nav-item v-if="isCanReview && (roles.cc) && memberClusterCode!=='sig' && memberClusterCode!=='sc'" :active="seltab==='panelist'" @click.prevent="seltab='panelist';selpaneltab='';filterDivision = $store.state.memberStore.member.division;loadIdeas()">Panelist Review</b-nav-item>
                          <b-nav-item v-if="isCanReview && (roles.ca)" :active="seltab==='admin'" @click.prevent="seltab='admin';filterDivision = '';selpaneltab='';loadIdeas()">Administrator</b-nav-item>

                          <!--
                        <b-nav-item-dropdown v-if="isCanReview && (roles.ca || roles.cc)" id="navManagement"
                                         text="Administration"
                                         toggle-class="nav-link-custom"
                                         right>
                            <b-dropdown-item :active="what===1 && roles.ca" @click.prevent="loadIdeas(1)">Under Review (Admin)</b-dropdown-item>
                            <b-dropdown-item :active="what===2" @click.prevent="loadIdeas(2)">Panel Evaluation</b-dropdown-item>
                            <b-dropdown-item :active="what===201" @click.prevent="loadIdeas(201)">Accepted Ideas (for Innovation Award)</b-dropdown-item>
                        </b-nav-item-dropdown>
                    -->
                      </b-nav>
                  </div>
                  <div class="card-body">


                      <!--<h3>{{ reportTitle }}</h3>-->

                      <div class="row mb-3">
                          <div class="col-lg-6 font-weight-bold">
                              <b-input-group size="sm">
                                  <b-input-group-prepend is-text>
                                      <b-icon icon="award"></b-icon>
                                  </b-input-group-prepend>
                                  <b-form-select v-if="seltab!='panelist' && seltab!='admin'" v-model="filterCluster" :options="ideaClusters" @change="filterDivision=''">
                                      <template v-slot:first>
                                          <b-form-select-option :value="null" disabled>-- Please Select a Cluster --</b-form-select-option>
                                          <b-form-select-option :value="''">-- All Clusters --</b-form-select-option>
                                      </template>
                                  </b-form-select>

                                  <b-form-select v-model="filterDivision" :options="ideaDivisions">
                                      <template v-slot:first>
                                          <b-form-select-option :value="null" disabled>-- Please Select a Division --</b-form-select-option>
                                          <b-form-select-option :value="''">-- All Divisions --</b-form-select-option>
                                      </template>
                                  </b-form-select>
                              </b-input-group>
                          </div>
                          <div class="col-lg-3 font-weight-bold text-lg-center">
                              <b-input-group size="sm">
                                  <b-input-group-prepend is-text>
                                      <b-icon icon="calendar"></b-icon>
                                  </b-input-group-prepend>
                                  <b-form-select :options="ideaMonths" v-model="filterMonth">
                                      <template v-slot:first>
                                          <b-form-select-option :value="null">-- All Periods --</b-form-select-option>
                                      </template>
                                  </b-form-select>
                              </b-input-group>
                          </div>

                          <!--<div class="col-lg-3">
                        <b-input-group size="sm" v-if="roles.cc || roles.ca">
                            <b-input-group-prepend is-text>
                                <b-icon icon="card-checklist"></b-icon>
                            </b-input-group-prepend>
                            <b-form-select v-model="filterStatus">
                                <b-form-select-option :value="null">-- All Statuses --</b-form-select-option>
                                <b-form-select-option value="0">Submitted</b-form-select-option>
                                <b-form-select-option value="1">Under Review</b-form-select-option>
                                <b-form-select-option value="2">Evaluation Panel</b-form-select-option>
                                <b-form-select-option value="9">Concluded (Not Accepted)</b-form-select-option>
                                <b-form-select-option value="10">Idea Accepted</b-form-select-option>
                            </b-form-select>
                        </b-input-group>
                    </div>-->
                      </div>
                      <div class="card" v-if="seltab==='panelist'">
                          <div class="card-body py-0">
                              <b-nav class="ideasubmenu">
                                  <!--//PANELISTS: not_reviewed, panel_evaluation, awaiting_specialistfeedback, received_specialistfeedback, reviewed(concluded, deleted)-->
                                  <b-nav-item :active="selpaneltab==='not_reviewed'" @click.prevent="selpaneltab='not_reviewed';loadIdeas()">Under Review <span class="badge badge-pill badge-secondary" v-if="selpaneltab!=='concluded' && selpaneltab!=='deleted'">{{ panelRecs.not_reviewed.length }}</span></b-nav-item>
                                  <b-nav-item :active="selpaneltab==='awaiting_specialistfeedback'" @click.prevent="selpaneltab='awaiting_specialistfeedback';loadIdeas()">Awaiting Specialist <span v-if="selpaneltab!=='concluded' && selpaneltab!=='deleted'" class="badge badge-pill badge-secondary">{{ panelRecs.awaiting_specialistfeedback.length }}</span></b-nav-item>
                                  <b-nav-item :active="selpaneltab==='received_specialistfeedback'" @click.prevent="selpaneltab='received_specialistfeedback';loadIdeas()">Specialist Responded <span v-if="selpaneltab!=='concluded' && selpaneltab!=='deleted'" class="badge badge-pill badge-secondary">{{ panelRecs.received_specialistfeedback.length }}</span></b-nav-item>

                                  <b-nav-item-dropdown text="Reports" toggle-class="nav-link-custom" right>
                                      <b-dropdown-item :active="selpaneltab==='evaluation'" @click.prevent="selpaneltab='evaluation';loadIdeas()">Sent for Panel Evaluation <span v-if="selpaneltab!=='concluded' && selpaneltab!=='deleted'" class="badge badge-pill badge-secondary">{{ panelRecs.evaluation.length }}</span></b-dropdown-item>
                                      <b-dropdown-item :active="selpaneltab==='concluded'" @click.prevent="selpaneltab='concluded';loadIdeas()">Concluded</b-dropdown-item>
                                      <b-dropdown-item :active="selpaneltab==='deleted'" @click.prevent="selpaneltab='deleted';loadIdeas()">Deleted</b-dropdown-item>
                                  </b-nav-item-dropdown>
                              </b-nav>
                          </div>
                      </div>

                      <div class="card" v-if="seltab==='admin'">
                          <div class="card-body py-0">
                              <b-nav class="ideasubmenu">
                                  <b-nav-item :active="selpaneltab==='panel_evaluation'" @click.prevent="selpaneltab='panel_evaluation';loadIdeas()">Panel Evaluation <span v-if="selpaneltab!=='concluded' && selpaneltab!=='deleted'" class="badge badge-pill badge-secondary">{{ panelRecs.panel_evaluation.length }}</span></b-nav-item>

                                  <b-nav-item :active="selpaneltab==='concluded'" @click.prevent="selpaneltab='concluded';loadIdeas()">Concluded</b-nav-item>

                                  <b-nav-item-dropdown text="Reports" toggle-class="nav-link-custom" right>
                                      <b-dropdown-item :active="selpaneltab==='not_reviewed'" @click.prevent="selpaneltab='not_reviewed';loadIdeas()">Under Review (Panelist) <span v-if="selpaneltab!=='concluded' && selpaneltab!=='deleted'" class="badge badge-pill badge-secondary">{{ panelRecs.not_reviewed.length }}</span></b-dropdown-item>
                                      <b-dropdown-item :active="selpaneltab==='awaiting_specialistfeedback'" @click.prevent="selpaneltab='awaiting_specialistfeedback';loadIdeas()">Awaiting Specialist (Panelist) <span v-if="selpaneltab!=='concluded' && selpaneltab!=='deleted'" class="badge badge-pill badge-secondary">{{ panelRecs.awaiting_specialistfeedback.length }}</span></b-dropdown-item>
                                      <b-dropdown-item :active="selpaneltab==='received_specialistfeedback'" @click.prevent="selpaneltab='received_specialistfeedback';loadIdeas()">Specialist Responded (Panelist) <span v-if="selpaneltab!=='concluded' && selpaneltab!=='deleted'" class="badge badge-pill badge-secondary">{{ panelRecs.received_specialistfeedback.length }}</span></b-dropdown-item>
                                      <b-dropdown-item :active="selpaneltab==='deleted'" @click.prevent="selpaneltab='deleted';loadIdeas()">Deleted</b-dropdown-item>
                                  </b-nav-item-dropdown>
                              </b-nav>
                          </div>
                      </div>


                  </div>
              </div>
              <h3 class="mt-3">{{ reportTitle }}</h3>
              <div v-if="recsFilteredList.length===0">
                  <b-alert show>No Ideas found!</b-alert>
              </div>

              <div v-for="r in recsFilteredList" :key="r._rowNum">
                  <section class="card card-default">
                      <!--v-if="(what===0 && r.ideaStatus===0) || what >= 1"-->
                      <div class="card-header" v-b-toggle="`collapse-${r._rowNum}`">
                          <div class="row">
                              <div class="col-sm-1 text-left">
                                    {{r.ideaId.sjRemoveIntHash()}}
                              </div>
                              <div class="col-sm-5 font-weight-bold">
                                  {{ r.ideaTitle }}
                              </div>
                              <div class="col-sm-4">
                                  {{ r.cluster + ' / ' + r.division }}
                              </div>
                              <div class="col-sm-2 text-right">
                                  {{ r.dateSubmitted.sjToDate('d MMM yyyy HH:mm')}}
                                  <div style="font-size:smaller; font-weight:bold">{{ r.ideaStatusDesc }}</div>
                                  <div v-if="r.isSparkIdea" style="font-size:smaller; font-weight:bold">Sparked</div>
                              </div>
                          </div>
                          <div class="row">

                          </div>

                      </div>
                      <b-collapse :id="`collapse-${r._rowNum}`">
                          <div class="card-body mb-5">
                              <div class="row mb-2">
                                  <div class="col-lg-2 fldNameB">Title:</div>
                                  <div class="col-lg-10 fldValueB">{{ r.ideaTitle }} </div>
                              </div>
                              <div class="row mb-2">
                                  <div class="col-lg-2 fldNameB">Applies to:</div>
                                  <div class="col-lg-8 fldValueB">{{ r.cluster + ' / ' + r.division }} </div>
                                  <div class="col-lg-2 fldNameB"><a v-if="!r.isReviewComplete && (seltab=='panelist' || seltab=='admin')" style="" href="#" @click.prevent="onShowModalEditDivision(r._rowNum)">Edit</a></div>
                              </div>

                              <div class="row mb-2">
                                  <div class="col-lg-2 fldNameB">Description:</div>
                                  <div class="col-lg-10 fldValueB">{{ r.ideaDescription }} </div>
                              </div>
                              <div class="row mb-2">
                                  <div class="col-lg-2 fldNameB">Value &amp; business benefit:</div>
                                  <div class="col-lg-10 fldValueB">{{ r.ideaBenefit }}</div>
                              </div>
                              <div class="row mb-4">
                                  <div class="col-lg-2 fldNameB">Example:</div>
                                  <div class="col-lg-10 fldValueB">{{ r.ideaExample }}</div>
                              </div>
                              <div class="row mb-2">
                                  <div class="col-lg-2 fldNameB">Owners:</div>
                                  <div class="col-lg-10 fldValueB">
                                      <div v-for="owner in r.owners" :key="owner._rowNum">
                                          {{ owner.isEntireDivision ? ('Division: ' + owner.division) : (owner.fullname + ' (' + owner.division + ')') }}
                                      </div>
                                  </div>
                              </div>
                              <div class="row mb-4">
                                  <div class="col-lg-2 fldNameB">Submitter:</div>
                                  <div class="col-lg-10 fldValueB">{{ r.byMemberName }}</div>
                              </div>
                              <div class="row mb-2">
                                  <div class="col-lg-2 fldNameB">Status:</div>
                                  <div class="col-lg-4 fldValueB">{{ r.ideaStatusDesc }}</div>
                                  <div class="col-lg-6 text-right">
                                      <!--<template v-if="isCanReview && what==='not_reviewed' && !r.isReviewComplete && !r.isFwdToPanel">

                                    <b-button v-if="memberClusterCode!=='sig' && memberClusterCode!=='sc'" @click="onSendToPanel(r._rowNum)" v-b-tooltip.hover title="This will send the idea to the Evaluation Panel" class="mr-1" size="sm" variant="primary">
                                        Panel Evaluation
                                    </b-button>
                                </template>-->

                                  </div>
                              </div>

                              <hr />
                              <div class="row mb-2">
                                  <div class="col-lg-6"><b-icon icon="hand-thumbs-up"></b-icon> <b>{{ r.likeCount }}</b> likes</div>
                                  <div class="col-lg-6">
                                      <div class="text-right" v-if="what==='ideas'">
                                          <b-button v-if="!r.memberOwnIdea" pill variant="primary" class="mr-5" size="sm" @click="onLike(r._rowNum)" :disabled="r.memberAlreadyLiked" :title="r.memberAlreadyLiked?'You liked this idea':''">
                                              <b-icon icon="hand-thumbs-up" aria-hidden="true" class="mr-1"></b-icon> Like
                                          </b-button>
                                          <b-button pill variant="primary" class="mr-5" size="sm" @click="onShowModalMemberComment(r._rowNum)">
                                              <b-icon icon="chat-square" aria-hidden="true" class="mr-1"></b-icon> Comment
                                          </b-button>
                                          <b-button pill variant="primary" class="mr-5" size="sm" @click="onShowModalRequestShare(r._rowNum)">
                                              <b-icon icon="envelope" aria-hidden="true" class="mr-1"></b-icon> Share
                                          </b-button>
                                      </div>
                                  </div>
                              </div>
                              <div v-for="comment in r.memberComments" :key="comment._rowNum">
                                  <div class="fldNameB mt-2">{{ comment.dateComment.sjToDate('d MMM yyyy HH:mm') + ' - ' + comment.fullname + ':' }}</div>
                                  <div class="fldValueB">{{ comment.comment }}</div>
                              </div>




                              <template v-if="r.isUseSpecialist && (seltab=='panelist' || seltab=='admin' || seltab=='specialist')">
                                  <hr />
                                  <div class="mb-2"><u>Specialist:</u></div>

                                  <div class="row mb-2">
                                      <div class="col-lg-2 fldNameB">To Specialist:</div>
                                      <div class="col-lg-10 fldValueB">{{ r.splstMemberName }}</div>
                                  </div>

                                  <div class="row mb-2">
                                      <div class="col-lg-2 fldNameB">From:</div>
                                      <div class="col-lg-10 fldValueB">
                                          {{ r.sentToSplstByMemberName }} - {{ r.dateSentToSplst.sjToDate('d MMM yyyy HH:mm') }}
                                      </div>
                                  </div>
                                  <div class="row mb-2">
                                      <div class="col-lg-2 fldNameB">Message:</div>
                                      <div class="col-lg-10 fldValueB">
                                          {{ r.msgToSplst }}
                                      </div>
                                  </div>

                                  <template v-if="!r.reviewed && !r.splstDone">
                                      <div v-if="seltab == 'specialist'" class="mb-2">
                                          <!-- Only Specialist Himself -->
                                          <b-button @click="onShowModalSpecialistFeedback(r._rowNum, false)" variant="primary" class="mr-1" size="sm">
                                              <b-icon icon="chat-quote" aria-hidden="true" class="mr-1"></b-icon> Provide Feedback
                                          </b-button>
                                      </div>
                                      <div v-else-if="seltab !== 'specialist'" class="mb-2">
                                          <b-button @click="onShowModalSpecialistFeedback(r._rowNum, true)" variant="primary" class="mr-1" size="sm">
                                              <b-icon icon="chat-quote" aria-hidden="true" class="mr-1"></b-icon> Respond on behalf of the Specialist
                                          </b-button>
                                      </div>
                                  </template>
                              </template>




                              <template v-if="seltab !== 'ideas' && seltab !== 'my_ideas'">
                                  <!-- Panel only -->

                                  <template v-if="r.isUseSpecialist && r.splstDone">
                                      <!-- Specialist is Done -->
                                      <div class="row mb-2">
                                          <div class="col-lg-2 fldNameB">Specialist Comment:</div>
                                          <div class="col-lg-10 fldValueB">{{ r.splstComment }}</div>
                                      </div>
                                      <div class="row mb-2">
                                          <div class="col-lg-2 fldNameB">Implement Idea?</div>
                                          <div class="col-lg-10 fldValueB">{{ r.splstImplement ? 'Yes' : 'No' }}</div>
                                      </div>
                                      <div class="row mb-2">
                                          <div class="col-lg-2 fldNameB">Response:</div>
                                          <div class="col-lg-10 fldValueB">{{ r.splstMsgToAdmin }}</div>
                                      </div>
                                  </template>


                                  <template v-if="seltab=='panelist' && !r.isFwdToPanel && !r.isReviewComplete">
                                      <hr />
                                      <b-button v-if="!r.isUseSpecialist || (r.isUseSpecialist && r.splstDone)" @click="onShowModalConclude(r._rowNum)" variant="primary" class="mr-1 float-right" size="sm">
                                          Conclude
                                      </b-button>
                                      <b-button v-if="!r.isUseSpecialist || (r.isUseSpecialist && r.splstDone)" @click="onShowModalDelete(r._rowNum)" variant="primary" class="mr-1 float-right" size="sm">
                                          Delete
                                      </b-button>
                                      <b-button v-if="!r.isUseSpecialist" @click="onShowModalRequestSpecialist(r._rowNum)" variant="primary" class="mr-5" size="sm">
                                          Request Specialist Feedback
                                      </b-button>
                                      <b-button v-if="!(r.isUseSpecialist && !r.splstDone) && (memberClusterCode!=='sig' && memberClusterCode!=='sc') && !r.isFwdToPanel" @click="onSendToPanel(r._rowNum)" class="mr-5" size="sm" variant="primary">
                                          Panel Evaluation
                                      </b-button>
                                  </template>
                                  <template v-if="seltab=='admin' && r.isFwdToPanel && !r.isReviewComplete">
                                      <hr />
                                      <b-button v-if="!r.isUseSpecialist || (r.isUseSpecialist && r.splstDone)" @click="onShowModalConclude(r._rowNum)" variant="primary" class="mr-3 float-right" size="sm">
                                          Conclude
                                      </b-button>
                                      <b-button v-if="!r.isUseSpecialist || (r.isUseSpecialist && r.splstDone)" @click="onShowModalDelete(r._rowNum)" variant="primary" class="mr-3 float-right" size="sm">
                                          Delete
                                      </b-button>
                                  </template>


                                  <template v-if="(r.isReviewComplete)">
                                      <!-- 9. Concluded (Idea not accepted)   10. Concluded (Idea accepted) -->
                                      <hr />
                                      <div class="mb-2"><u>Recommendation:</u></div>
                                      <div class="row mb-2">
                                          <div class="col-lg-2 fldNameB">Implement Idea?</div>
                                          <div class="col-lg-10 fldValueB">{{ r.implement }}</div>
                                      </div>
                                      <div class="row mb-2">
                                          <div class="col-lg-2 fldNameB">Comments:</div>
                                          <div class="col-lg-10 fldValueB">{{ r.comment }}</div>
                                      </div>
                                  </template>

                                  <template v-if="seltab=='admin' && r.isReviewComplete && !r.isReviewDeleted && r.rewardDecision !== 'R'">
                                      <hr />
                                      <b-button v-if="!r.isUseSpecialist || (r.isUseSpecialist && r.splstDone)" @click="onShowModalReward(r._rowNum)" variant="primary" class="mr-1 float-right" size="sm" title="" v-b-tooltip.hover.left>
                                          Reward
                                      </b-button>
                                      <!--<b-button v-if="!r.isUseSpecialist || (r.isUseSpecialist && r.splstDone)" @click="doLinkOut('/adhocrecognition', r.ideaId);" XXXXclick="onShowModalReward(r._rowNum)" variant="primary" class="mr-1 float-right" size="sm" title="This will transfer you to the 'Adhoc Recognition' page." v-b-tooltip.hover.left>Reward</b-button>-->
                                  </template>


                                  <!--
                            <template v-if="(r.ideaStatus==10)">
                                < !-- If idea accepted, && reward is N: Not decided/Not concluded yet -- >
                                <hr />
                                <div class="mb-2"><u>Innovation Award:</u></div>
                                <div class="row mb-2">
                                    <div class="col-lg-2 fldNameB">Reward Decision:</div>
                                    <div class="col-lg-10 fldValueB">{{ r.rewardDecisionDesc }}</div>
                                </div>

                                <div v-if="what===201 && r.ideaStatus==10 && roles.inv && r.rewardDecision=='N'">
                                    <b-button @click="onShowModalRewardDecision(r._rowNum)" variant="primary" class="mr-1" size="sm">Set Reward Decision</b-button>
                                </div>
                            </template>
                            -->
                              </template>
                              <template v-else-if="seltab === 'my_ideas' && r.isReviewComplete">
                                  <div class="row mb-2">
                                      <div class="col-lg-2 fldNameB">Panellist Comments:</div>
                                      <div class="col-lg-10 fldValueB">{{ r.comment }}</div>
                                  </div>
                              </template>
                          </div>
                      </b-collapse>
                  </section>
              </div>


          </template>


          <!--
    **************************************************************************************************************************************************
                                                                N E W     I D E A
    **************************************************************************************************************************************************
    -->
          <!--<template v-if="displayPart==1">
    <div class="text-right"><a href="#" @click.prevent="displayPart=0">Back</a></div>
    <member-select title="Choose the idea owner(s)"
                   selectedTitle="Selected idea owners"
                   :defaultCountryCode="memberClusterCode"
                   :defaultDivisionCode="memberDivisionCode"
                   includeSelf
                   v-bind:selectedMems="selectedIdeaOwners"
                   @continue="ownersSelected" />-->
          <!-- isCountryFixed -->
          <!--</template>-->


          <section v-if="displayPart==2" class="card card-default">
              <div class="card-header">
                  <div class="row">
                      <div class="col-sm-8"><h5>Innovation Idea</h5></div>
                      <div class="col-sm-4 text-right">
                          <a href="#" @click.prevent="displayPart=0" class="btn btn-secondary">Back</a>
                      </div>
                  </div>
              </div>
              <div class="card-body">
                  <div><strong>Owners:</strong></div>
                  <div v-for="r in selectedIdeaOwners" :key="r._rowNum" class="row">
                      <template v-if="r.isEntireDivision">
                          <div class="col-1 text-right"><b-icon icon="collection" style="color:#9f34a0"></b-icon></div>
                          <div class="col" style="color:#9f34a0">Entire division: {{ r.division }}</div>
                      </template>
                      <template v-else>
                          <div class="col-1 text-right"><b-icon icon="person" style="color:#ccc"></b-icon></div>
                          <div class="col">{{ r.firstname + ' ' + r.surname }}</div>
                          <div class="col">{{ r.division }}</div>
                      </template>
                  </div>
                  <!--<div class="text-right"><a href="#" @click.prevent="displayPart=1">Change</a></div>-->
                  <div v-if="selectedIdeaOwners.length===0" class="text-danger">Choose Idea Owners.</div>
                  <div class="mt-1">
                      <b-button type="button" pill @click="$bvModal.show('modalChooseIdeaOwners')" variant="primary" size="sm" class="mt-2">{{selectedIdeaOwners.length===0 ? 'Select' : 'Change'}} Idea Owners</b-button>
                  </div>

                  <hr />
                  <b-form @submit.prevent="onSubmitNewIdea" novalidate>

                      <b-form-group id="groupTheme" label-for="selectThemeCode" label="Idea Theme:" :invalid-feedback="fIdea.errors.theme" description="">
                          <b-form-select id="selectTheme" v-model="fIdea.form.theme" :state="fIdea.state.theme" :options="themeList" value-field="themeValue" text-field="theme" :disabled="this.themeList.length<=1" />
                      </b-form-group>


                      <label for="selectedClusterCode" class="formfieldName d-block">Where does this idea apply?</label>
                      <div class="row">
                          <div class="col-lg-6">
                              <b-form-group id="groupCluster" label-for="selectClusterCode" label="" :invalid-feedback="fIdea.errors.clusterCode" description="">
                                  <b-form-select id="selectClusterCode" v-model="fIdea.form.clusterCode" :state="fIdea.state.clusterCode" :options="countryList" value-field="countryCode" text-field="country" v-on:change="fIdea.form.divisionCode=null" />
                              </b-form-group>
                          </div>
                          <div class="col-lg-6">
                              <b-form-group id="groupDivision" label-for="selectDivisionCode" label="" :invalid-feedback="fIdea.errors.divisionCode" description="">
                                  <b-form-select id="selectDivisionCode" v-model="fIdea.form.divisionCode" :state="fIdea.state.divisionCode" :options="divisionList" value-field="divisionCode" text-field="division" />
                              </b-form-group>
                          </div>
                      </div>

                      <!--<b-form-group id="groupEmail" label-for="txtEmail" label="Email Address:" :invalid-feedback="fIdea.errors.EmailAddress" description="Your email address">
                    <b-form-input id="txtEmail" v-model="fIdea.form.EmailAddress" type="text" :state="fIdea.state.EmailAddress" placeholder="Enter your email address" />
                </b-form-group>-->

                      <b-form-group id="groupIdeaTitle" label-for="txtIdeaTitle" label="Title:" :invalid-feedback="fIdea.errors.motivation" :description="80-fIdea.form.ideaTitle.length + ' characters remaining'">
                          <b-form-input type="text" id="txtIdeaTitle" v-model="fIdea.form.ideaTitle" :state="fIdea.state.ideaTitle" placeholder="Title for your idea" maxlength="80" />
                      </b-form-group>

                      <b-form-group id="groupIdeaDescription" label-for="txtIdeaDescription" label="Description:" :invalid-feedback="fIdea.errors.ideaDescription" :description="500-fIdea.form.ideaDescription.length + ' characters remaining'">
                          <b-form-textarea id="txtIdeaDescription" v-model="fIdea.form.ideaDescription" :state="fIdea.state.ideaDescription" placeholder="Describe your idea" rows="3" max-rows="5" maxlength="500" />
                      </b-form-group>

                      <b-form-group id="groupIdeaBenefit" label-for="txtIdeaBenefit" label="Value and business benefit:" :invalid-feedback="fIdea.errors.ideaBenefit" :description="500-fIdea.form.ideaBenefit.length + ' characters remaining'">
                          <b-form-textarea id="txtIdeaBenefit" v-model="fIdea.form.ideaBenefit" :state="fIdea.state.ideaBenefit" placeholder="Describe how this idea adds value and business benefit" rows="3" max-rows="5" maxlength="500" />
                      </b-form-group>

                      <b-form-group id="groupIdeaExample" label-for="txtIdeaExample" label="Example:" :invalid-feedback="fIdea.errors.ideaExample" :description="500-fIdea.form.ideaExample.length + ' characters remaining'">
                          <b-form-textarea id="txtIdeaExample" v-model="fIdea.form.ideaExample" :state="fIdea.state.ideaExample" placeholder="Provide an example of your idea" rows="3" max-rows="5" maxlength="500" />
                      </b-form-group>

                      <div class="text-right"><b-button type="submit" variant="primary">Submit</b-button></div>
                  </b-form>
              </div>
          </section>

          <section v-if="displayPart==3" class="">
              <div class="card card-default">
                  <div class="card-header">
                      <div class="row">
                          <div class="col-sm-8"><h5>Innovation Idea</h5></div>
                          <div class="col-sm-4 text-right">
                              <!--<a href="#" @click.prevent="displayPart=0" class="btn btn-secondary">Back</a>-->
                          </div>
                      </div>
                  </div>
                  <div class="card-body">
                      <b>Thank you for submission. </b><br />
                      <br />
                      Your idea reference is: <b>{{ideaId.sjRemoveIntHash()}}.</b><br />
                      The Innovative idea submitted for consideration will be evaluated and feedback will be provided to you.

                  </div>
              </div>

              <div class="card" v-if="false"><!-- To Enable:   "Spark your idea", set to true -->
                  <div class="card-body">
                      <b-form-group id="groupSplstImplement" label-for="splstImplement" label="Do you want to Spark Your Idea?" description="">
                          <b-form-radio-group id="radiogroupSplstImplement" v-model="isSparkIdea" name="splstImplement">
                              <b-form-radio value="true">Yes</b-form-radio>
                              <b-form-radio value="false">No</b-form-radio>
                          </b-form-radio-group>
                      </b-form-group>

                      <hr />
                      <div class="text-right">
                          <b-button type="button" variant="primary" @click="onSubmitSparkIdea()">Submit</b-button>
                      </div>
                      <hr />
                      <div class="alert alert-info">
                          <div><b>What is “Spark your idea”?</b></div>
                          An elevated pitch to your innovative idea. This is an extension to the current quarterly award nomination process on Applause. You will have an option to submit your idea as an elevated pitch, which you will have an opportunity to convince a “sponsor” to the idea you are presenting.
                      </div>
                  </div>
              </div>

              <div v-else class="mt-3 text-right"><!-- Spark your idea - disabled...-->
                  <b-button type="button" variant="primary" @click="what='';loadIdeas();displayPart=0;">Back</b-button>
              </div>
          </section>

          <!--
    **************************************************************************************************************************************************
                                                                M O D A L S
    **************************************************************************************************************************************************
    -->
                          <!-- Modal - Nominees -->
                          <b-modal id="modalChooseIdeaOwners" title="Award Nomination" hide-footer size="xl" no-close-on-backdrop>
                              <member-select title="Choose the idea owner(s)"
                                             selectedTitle="Selected idea owners"
                                             :defaultCountryCode="memberClusterCode"
                                             :defaultDivisionCode="memberDivisionCode"
                                             includeSelf
                                             :selectedMems="selectedIdeaOwners"
                                             :showContinue="false"
                                             class="mb-3" />

                              <hr />
                              <div class="text-right"><b-button type="submit" variant="primary" @click="$bvModal.hide('modalChooseIdeaOwners')">Done</b-button></div>
                          </b-modal>

                          <!-- Modal - Add new member comment -->
                          <b-modal id="modalMemberComment" title="ADD A NEW COMMENT" hide-footer no-close-on-backdrop>
                              <b-form @submit.prevent="onSubmitMemberComment" novalidate>

                                  <b-form-group id="groupMemberComment" label-for="txtMemberComment" label="Enter Comment:" :invalid-feedback="fMemberComment.errors.comment" :description="500-fMemberComment.form.comment.length + ' characters remaining'">
                                      <b-form-textarea id="txtMemberComment" v-model="fMemberComment.form.comment" :state="fMemberComment.state.comment" placeholder="" rows="3" maxlength="500" />
                                  </b-form-group>

                                  <div class="text-right"><b-button @click="$bvModal.hide('modalMemberComment')">Cancel</b-button> <b-button type="submit" variant="primary">Submit</b-button></div>

                              </b-form>
                          </b-modal>

                          <!-- Modal - Request Specialist -->
                          <b-modal id="modalRequestShare" title="SHARE IDEA" hide-footer size="xl" no-close-on-backdrop>


                              <b-form @submit.prevent="onSubmitRequestShare" novalidate>
                                  <member-select title="Select recipients to share idea with."
                                                 selectedTitle="Selected Recipient(s)"
                                                 :defaultCountryCode="memberClusterCode"
                                                 :defaultDivisionCode="memberDivisionCode"
                                                 :selectedMems="fRequestShare.selectedRecipients"
                                                 :isAllowWholeDivision="false"
                                                 :isSingle="false"
                                                 :showContinue="false"
                                                 class="mb-3" />

                                  <b-form-group id="groupMessage" label-for="txtMessage" label="Message to Recipient:" :invalid-feedback="fRequestShare.errors.message" :description="2000-fRequestShare.form.message.length + ' characters remaining'" class="mb-3">
                                      <b-form-textarea id="txtMessage" v-model="fRequestShare.form.message" :state="fRequestShare.state.message" placeholder="" rows="3" maxlength="2000" />
                                  </b-form-group>
                                  <hr />

                                  <div class="text-right"><div class="float-left">An email with the idea details will be sent to the selected recipients.</div> <b-button @click="$bvModal.hide('modalRequestShare')" class="mr-2">Cancel</b-button> <b-button type="submit" variant="primary">Send</b-button></div>

                              </b-form>
                          </b-modal>

                          <!-- Modal - Request Specialist -->
                          <b-modal id="modalRequestSpecialist" title="REQUEST SPECIALIST FEEDBACK" hide-footer size="xl" no-close-on-backdrop>
                              <div class="mb-3">Request a specialist to provide feedback to this idea.</div>

                              <b-form @submit.prevent="onSubmitRequestSpecialist" novalidate>
                                  <member-select title="Choose a Specialist"
                                                 selectedTitle="Selected Specialist"
                                                 :defaultCountryCode="memberClusterCode"
                                                 :defaultDivisionCode="memberDivisionCode"
                                                 :selectedMems="fRequestSpecialist.selectedSpecialist"
                                                 :isAllowWholeDivision="false"
                                                 :isSingle="true"
                                                 :showContinue="false"
                                                 class="mb-3" />

                                  <b-form-group id="groupMsgToSplst" label-for="txtMsgToSplst" label="Message to Specialist:" :invalid-feedback="fRequestSpecialist.errors.msgToSplst" :description="2000-fRequestSpecialist.form.msgToSplst.length + ' characters remaining'" class="mb-3">
                                      <b-form-textarea id="txtMsgToSplst" v-model="fRequestSpecialist.form.msgToSplst" :state="fRequestSpecialist.state.msgToSplst" placeholder="" rows="3" maxlength="2000" />
                                  </b-form-group>
                                  <hr />

                                  <div class="text-right"><b-button @click="$bvModal.hide('modalRequestSpecialist')" class="mr-2">Cancel</b-button> <b-button type="submit" variant="primary">Send to Specialist</b-button></div>

                              </b-form>
                          </b-modal>

                          <!-- Modal - Specialist Response -->
                          <b-modal id="modalSpecialistFeedback" title="SPECIALIST FEEDBACK" hide-footer size="xl" no-close-on-backdrop>
                              <div class="mb-3">Provide specialist feedback for this idea: <span v-if="fSpecialistFeedback.onBehalfOfSpecialist">(on behalf of the specialist)</span> </div>

                              <b-form @submit.prevent="onSubmitSpecialistFeedback" novalidate>

                                  <b-form-group id="groupSplstComment" label-for="txtSplstComment" label="Comment on the idea:" :invalid-feedback="fSpecialistFeedback.errors.splstComment" :description="2000-fSpecialistFeedback.form.splstComment.length + ' characters remaining'" class="mb-3">
                                      <b-form-textarea id="txtSplstComment" v-model="fSpecialistFeedback.form.splstComment" :state="fSpecialistFeedback.state.splstComment" placeholder="" rows="3" maxlength="2000" />
                                  </b-form-group>

                                  <b-form-group id="groupSplstImplement" label-for="splstImplement" label="Do you think this idea can possibly be implemented?" description="" :invalid-feedback="fSpecialistFeedback.errors.splstImplement" :state="fSpecialistFeedback.state.splstImplement">
                                      <b-form-radio-group id="radiogroupSplstImplement" v-model="fSpecialistFeedback.form.splstImplement" name="splstImplement" :state="fSpecialistFeedback.state.splstImplement">
                                          <b-form-radio value="true">Yes</b-form-radio>
                                          <b-form-radio value="false">No</b-form-radio>
                                      </b-form-radio-group>
                                  </b-form-group>
                                  <hr />

                                  <template v-if="!fSpecialistFeedback.onBehalfOfSpecialist">
                                      <b-form-group id="groupSplstMsgToAdmin" label-for="txtSplstMsgToAdmin" label="Message to Panellist:" :invalid-feedback="fSpecialistFeedback.errors.splstMsgToAdmin" :description="2000-fSpecialistFeedback.form.splstMsgToAdmin.length + ' characters remaining'" class="mb-3">
                                          <b-form-textarea id="txtSplstMsgToAdmin" v-model="fSpecialistFeedback.form.splstMsgToAdmin" :state="fSpecialistFeedback.state.splstMsgToAdmin" placeholder="" rows="3" maxlength="2000" />
                                      </b-form-group>
                                      <hr />
                                  </template>

                                  <div class="text-right">
                                      <b-button @click="$bvModal.hide('modalSpecialistFeedback')" class="mr-2">Cancel</b-button>
                                      <b-button type="submit" variant="primary">Submit Feedback</b-button>
                                  </div>

                              </b-form>
                          </b-modal>

                          <!-- Modal - Conclude -->
                          <b-modal id="modalConclude" title="CONCLUDE" hide-footer size="xl" no-close-on-backdrop>
                              <div class="mb-3">Conclude this idea.</div>

                              <b-form @submit.prevent="onSubmitConclude('')" novalidate>

                                  <b-form-group id="groupImplement" label-for="Implement" label="Do you think this idea can possibly be implemented?" description="" :invalid-feedback="fConclude.errors.implement" :state="fConclude.state.implement">
                                      <b-form-radio-group id="radiogroupImplement" v-model="fConclude.form.implement" name="implement" :state="fConclude.state.implement">
                                          <b-form-radio value="1">Yes</b-form-radio>
                                          <b-form-radio value="0">No</b-form-radio>
                                          <b-form-radio value="">Unsure</b-form-radio>
                                      </b-form-radio-group>
                                  </b-form-group>

                                  <b-form-group id="groupComment" label-for="txtComment" label="Comment on the idea:" :invalid-feedback="fConclude.errors.comment" :description="2000-fConclude.form.comment.length + ' characters remaining'" class="mb-3">
                                      <b-form-textarea id="txtComment" v-model="fConclude.form.comment" :state="fConclude.state.comment" placeholder="" rows="3" maxlength="2000" />
                                  </b-form-group>

                                  <hr />

                                  <!--<b-form-group id="groupAcceptIdea" label-for="AcceptIdea" label="Accept this Idea? (Idea will be available for Innovation Award)" description="" :invalid-feedback="fConclude.errors.acceptIdea" :state="fConclude.state.acceptIdea">
                <b-form-radio-group id="radiogroupAcceptIdea" v-model="fConclude.form.acceptIdea" name="acceptIdea" :state="fConclude.state.acceptIdea">
                    <b-form-radio value="true">Yes</b-form-radio>
                    <b-form-radio value="false">No</b-form-radio>
                </b-form-radio-group>
            </b-form-group>-->

                                  <hr />

                                  <div class="text-right">
                                      <b-button @click="$bvModal.hide('modalConclude')" class="mr-2">Cancel</b-button>
                                      <b-button type="submit" variant="primary" class="mr-2">Conclude this Idea</b-button>
                                      <b-button v-if="seltab==='admin'" @click="onSubmitConclude('reward')" type="button" variant="primary" class="mr-2" title="" v-b-tooltip.hover.left>Conclude and Reward</b-button>
                                  </div>

                              </b-form>
                          </b-modal>

                          <!-- Modal - Reward -->
                          <b-modal id="modalReward" title="Reward Concluded Idea" hide-footer size="xl" no-close-on-backdrop>
                              <section v-if="adhocDisplayPart==0">
                                  <div class="row">
                                      <div class="col-sm-6"><strong>Recipient(s):</strong></div>
                                      <div class="col-sm-6 text-right"></div>
                                  </div>
                                  <div v-for="r in adhocMems" :key="r._rowNum" class="row">
                                      <div class="col-1 text-right">
                                          <b-form-checkbox name="adhocIsSelected" :value="r.memberId" v-model="adhocSelectedMems"></b-form-checkbox>
                                      </div>
                                      <div class="col-1 text-right"><b-icon icon="person" style="color:#ccc"></b-icon></div>
                                      <div class="col">
                                          {{ r.firstName + ' ' + r.surname }}
                                      </div>
                                      <div class="col">{{ r.division }}</div>
                                  </div>

                                  <hr />
                                  <b-form @submit.prevent="onSubmitAdhoc" novalidate>
                                      <b-form-group id="groupReason" label-for="selectreason" label="Value:" :invalid-feedback="fAdhoc.errors.reason" description="">
                                          <b-form-select id="selectreason" v-model="fAdhoc.form.reason" :state="fAdhoc.state.reason" :options="valueOptions"></b-form-select>
                                      </b-form-group>

                                      <b-form-group id="groupPoints" label-for="selectPoints" label="Points Amount:" :invalid-feedback="fAdhoc.errors.points" description="" :state="fAdhoc.state.points">
                                          <b-form-select id="selectPoints" v-model="fAdhoc.form.points" :state="fAdhoc.state.points" :options="pointsOptions"></b-form-select>
                                      </b-form-group>



                                      <b-form-group id="groupSendBy" label-for="sendBy" description="" :invalid-feedback="fAdhoc.errors.sendBy" :state="fAdhoc.state.sendBy" class="mt-4">
                                          <b-form-radio-group id="radiogroupSendBy" v-model="fAdhoc.form.sendBy" name="sendBy" :state="fAdhoc.state.sendBy">
                                              <div class="row">
                                                  <div class="col-sm-3 col-lg-2">
                                                      <b-form-radio value="email">Email</b-form-radio>
                                                  </div>
                                                  <div class="col-sm-3 col-lg-2">
                                                      <b-form-radio value="sms">SMS</b-form-radio>
                                                  </div>
                                              </div>
                                          </b-form-radio-group>
                                      </b-form-group>

                                      <b-form-group id="groupAdhocMessage" label-for="txtAdhocMessage" label="Message to Recipients" :invalid-feedback="fAdhoc.errors.message" :description="(isSendBySMS?150:350)-fAdhoc.form.message.length + ' characters remaining'">
                                          <b-form-textarea id="txtAdhocMessage" :disabled="fAdhoc.form.sendBy===null" v-model="fAdhoc.form.message" :state="fAdhoc.state.message" :placeholder="isSendBySMS ? 'Short SMS message to recipients up to 150 characters' : 'Short email message to recipients up to 350 characters'" rows="3" max-rows="5" :maxlength="(isSendBySMS?150:350)" />
                                      </b-form-group>

                                      <hr />

                                      <div class="text-right">
                                          <div class="float-left">This idea will be flagged as <i>Rewarded</i>.</div>
                                          <b-button @click="$bvModal.hide('modalReward')" class="mr-2">Cancel</b-button>
                                          <b-button type="submit" variant="primary">Submit</b-button>
                                      </div>
                                  </b-form>
                              </section>

                              <section v-if="adhocDisplayPart==1">
                                  <b-alert variant="success" show>
                                      <h4 class="alert-heading">Idea Reward</h4>
                                      <p>
                                          Successfully submitted Idea Reward.
                                      </p>
                                  </b-alert>
                                  <hr />
                                  <div class="text-right">
                                      <b-button @click="$bvModal.hide('modalReward')" class="mr-2">Close</b-button>
                                      <!--<button type="button" @click="adhocDisplayPart=0" class="btn btn-primary">Send Another</button>\-->
                                  </div>
                              </section>

                          </b-modal>

                          <!-- Modal - Delete -->
                          <b-modal id="modalDelete" title="DELETE" hide-footer size="xl" no-close-on-backdrop>
                              <div class="mb-3">Delete this idea.</div>

                              <b-form @submit.prevent="onSubmitConclude" novalidate>

                                  <b-form-group id="groupComment" label-for="txtComment" label="Comment on the idea:" :invalid-feedback="fConclude.errors.comment" :description="2000-fConclude.form.comment.length + ' characters remaining'" class="mb-3">
                                      <b-form-textarea id="txtComment" v-model="fConclude.form.comment" :state="fConclude.state.comment" placeholder="" rows="3" maxlength="2000" />
                                  </b-form-group>

                                  <hr />

                                  <div class="text-right">
                                      <b-button @click="$bvModal.hide('modalDelete')" class="mr-2">Cancel</b-button>
                                      <b-button type="submit" variant="primary">Delete this Idea</b-button>
                                  </div>

                              </b-form>
                          </b-modal>

                          <!-- Modal - Reward Decision -->
                          <b-modal id="modalRewardDecision" title="REWARD DECISION" hide-footer size="xl" no-close-on-backdrop>
                              <div class="mb-3">Select a reward decision, and if this should be included in the Group CEO award:</div>

                              <b-form @submit.prevent="onSubmitRewardDecision" novalidate>

                                  <b-form-group id="groupRewardDecision" label-for="RewardDecision" label="Reward Decision:" description="" :invalid-feedback="fRewardDecision.errors.rewardDecision" :state="fRewardDecision.state.rewardDecision">
                                      <b-form-radio-group id="radiogroupRewardDecision" v-model="fRewardDecision.form.rewardDecision" name="rewardDecision" :state="fRewardDecision.state.rewardDecision">
                                          <b-form-radio value="W">Winner</b-form-radio>
                                          <b-form-radio value="R">Runner-up</b-form-radio>
                                          <b-form-radio value="C">Concluded (No Winner)</b-form-radio>
                                      </b-form-radio-group>
                                  </b-form-group>

                                  <b-form-group id="groupIsCEOaward" label-for="IsCEOaward" label="Include in Group CEO award?" description="" :invalid-feedback="fRewardDecision.errors.isCEOaward" :state="fRewardDecision.state.isCEOaward">
                                      <b-form-radio-group id="radiogroupIsCEOaward" v-model="fRewardDecision.form.isCEOaward" name="isCEOaward" :state="fRewardDecision.state.isCEOaward">
                                          <b-form-radio :value="true">Yes</b-form-radio>
                                          <b-form-radio :value="false">No</b-form-radio>
                                      </b-form-radio-group>
                                  </b-form-group>
                                  <hr />
                                  <div class="card card-default mb-3" v-if="fRewardDecision.form.isCEOaward">
                                      <div class="card-header">
                                          <div class="row">
                                              <div class="col-sm-3 font-weight-bold">
                                                  Group CEO award
                                              </div>
                                          </div>
                                      </div>
                                      <div class="card-body">
                                          <b-form-group id="groupAwardCat" label-for="selectAwardCat" label="Award Category:" :invalid-feedback="fRewardDecision.errors.awardCat" description="">
                                              <b-form-select id="selectAwardCat" v-model="fRewardDecision.form.awardCat" :options="awardCatList" value-field="value" text-field="text" :state="fRewardDecision.state.awardCat" />
                                          </b-form-group>

                                          <b-form-group id="groupMotivation" label-for="txtMotivation" label="Motivation for the nomination:" :invalid-feedback="fRewardDecision.errors.motivation" :description="500-fRewardDecision.form.motivation.length + ' characters remaining'">
                                              <b-form-textarea id="txtMotivation" v-model="fRewardDecision.form.motivation" :state="fRewardDecision.state.motivation" placeholder="" rows="3" max-rows="5" maxlength="500" />
                                          </b-form-group>

                                          <b-form-group id="groupActions" label-for="txtActions" label="Actions for the advantage or adverted loss for the company:" :invalid-feedback="fRewardDecision.errors.actions" :description="500-fRewardDecision.form.actions.length + ' characters remaining'">
                                              <b-form-textarea id="txtActions" v-model="fRewardDecision.form.actions" :state="fRewardDecision.state.actions" placeholder="" rows="3" max-rows="5" maxlength="500" />
                                          </b-form-group>

                                          <b-form-group id="groupMeasures" label-for="txtMeasures" label="Measures of achievement and results:" :invalid-feedback="fRewardDecision.errors.measures" :description="500-fRewardDecision.form.measures.length + ' characters remaining'">
                                              <b-form-textarea id="txtMeasures" v-model="fRewardDecision.form.measures" :state="fRewardDecision.state.measures" placeholder="" rows="3" max-rows="5" maxlength="500" />
                                          </b-form-group>

                                          <b-form-group id="groupAchievements" label-for="txtAchievements" label="Achievements to company strategy:" :invalid-feedback="fRewardDecision.errors.achievements" :description="500-fRewardDecision.form.achievements.length + ' characters remaining'">
                                              <b-form-textarea id="txtAchievements" v-model="fRewardDecision.form.achievements" :state="fRewardDecision.state.achievements" placeholder="" rows="3" max-rows="5" maxlength="500" />
                                          </b-form-group>
                                          <hr />
                                          <div class="row">
                                              <div class="col-sm-6"><strong>Nominators to support your nomination:</strong></div>
                                              <div class="col-sm-6 text-right"></div>
                                          </div>
                                          <div v-for="r in selectedNominators" :key="r._rowNum" class="row">
                                              <template v-if="r.isEntireDivision">
                                                  <div class="col-1 text-right"><b-icon icon="collection" style="color:#9f34a0"></b-icon></div>
                                                  <div class="col" style="color:#9f34a0">Entire division: {{ r.division }}</div>
                                              </template>
                                              <template v-else>
                                                  <div class="col-1 text-right"><b-icon icon="person" style="color:#ccc"></b-icon></div>
                                                  <div class="col">{{ r.firstname + ' ' + r.surname }}</div>
                                                  <div class="col">{{ r.division }}</div>
                                              </template>
                                          </div>
                                          <div v-if="selectedNominators.length!==2" class="text-danger">Please select 2 nominators to support your nomination.</div>
                                          <div class="mt-1"><b-button type="button" pill @click="$bvModal.show('modalChooseNominators')" variant="secondary" size="sm">{{selectedNominators.length===0 ? 'Select' : 'Update'}} Nominators</b-button></div>
                                          <!--<div class="text-center"><b-button type="button" @click="$bvModal.show('modalChooseNominators')" variant="primary">Select Nominators</b-button></div>-->
                                      </div>
                                  </div>
                                  <div class="text-right">
                                      <b-button @click="$bvModal.hide('modalRewardDecision')" class="mr-2">Cancel</b-button>
                                      <b-button type="submit" variant="primary">Submit</b-button>
                                  </div>

                              </b-form>
                          </b-modal>

                          <!-- Modal - Nominators -->
                          <b-modal id="modalChooseNominators" title="NOMINATORS" hide-footer size="xl" no-close-on-backdrop>
                              <member-select title="Select two other nominators to support your nomination"
                                             selectedTitle="Selected nominators to support your nomination"
                                             :defaultCountryCode="memberClusterCode"
                                             :defaultDivisionCode="memberDivisionCode"
                                             :selectedMems="selectedNominators"
                                             :isAllowWholeDivision="false"
                                             :showContinue="false"
                                             class="mb-3" />
                              <hr />
                              <div class="text-right"><b-button type="submit" variant="primary" @click="$bvModal.hide('modalChooseNominators')">Done</b-button></div>
                          </b-modal>


                          <!-- Modal - Change Cluster/Division -->
                          <b-modal id="modalEditDivision" title="UPDATE" hide-footer size="xl" no-close-on-backdrop>
                              <div class="mb-3">Move this Idea to a different business unit.</div>

                              <b-form @submit.prevent="onSubmitEditDivision" novalidate>
                                  <label for="selectedClusterCode" class="formfieldName d-block">Where does this idea apply?</label>
                                  <div class="row">
                                      <!--<div class="col-lg-6">
                    <b-form-group id="editGroupCluster" label-for="selectClusterCode" label="" :invalid-feedback="fIdea.errors.clusterCode" description="">
                        <b-form-select id="selectClusterCode" v-model="fIdea.form.clusterCode" :state="fIdea.state.clusterCode" :options="countryList" value-field="countryCode" text-field="country" v-on:change="fIdea.form.divisionCode=null" />
                    </b-form-group>
                </div>-->
                                      <div class="col-lg-6">
                                          <b-form-group id="edigGroupDivision" label-for="selectDivisionCode" label="" :invalid-feedback="fIdea.errors.divisionCode" description="">
                                              <b-form-select id="selectDivisionCode" v-model="fIdea.form.divisionCode" :state="fIdea.state.divisionCode" :options="divisionList" value-field="divisionCode" text-field="division" />
                                          </b-form-group>
                                      </div>
                                  </div>
                                  <hr />
                                  <div class="text-right"><b-button @click="$bvModal.hide('modalEditDivision')" class="mr-2">Cancel</b-button> <b-button type="submit" variant="primary">Update</b-button></div>
                              </b-form>
                          </b-modal>
                      </div>

</template>

<script>
    // @ is an alias to /src
    //import HelloWorld from '@/components/HelloWorld.vue'
    //import SitecodeImage from '@/components/SitecodeImage';
    import axios from 'axios'
    import sjData from '@/sjUtilData'
    import MemberSelect from '@/components/MemberSelect';


    export default {
        name: 'InnovationIdea',
        components: { MemberSelect },
        data() {
            return {
                displayPart: 0,
                isSpecialistIdeasWaiting: false,
                panelistCount: 0,
                adminPanelEvalCount: 0,
                ideaId: 0,
                isSparkIdea: null,

                fIdea: {
                    form: { theme: null, clusterCode: null, divisionCode: null, ideaTitle: '', ideaDescription: '', ideaBenefit: '', ideaExample: '' }
                },

                fMemberComment: {
                    form: { comment: '' }
                },

				fRequestShare: {
					form: { message: '' },
					selectedRecipients: [],
				},

                fRequestSpecialist: {
                    form: { msgToSplst: '' },
                    selectedSpecialist: [],
                },

                fSpecialistFeedback: {
                    form: { splstComment: '', splstImplement: null, splstMsgToAdmin: '' },
                    onBehalfOfSpecialist: false
                },

                fConclude: {
                    form: { implement: '', comment: '', isReviewDeleted: null }
                },

                fRewardDecision: {
                    form: { rewardDecision: null, isCEOaward: null, awardCat: '', achievements: '', motivation: '', actions: '', measures: '' }
                },

				fEditDivision: {
					form: { clusterCode: null, divisionCode: null }
				},

                selectedNominators: [],

                selectedIdeaOwners: [],

                seltab: 'ideas',
                what: '',  //USERS:  ideas, my_ideas, specialist
                //PANELISTS: not_reviewed, panel_evaluation, awaiting_specialistfeedback, received_specialistfeedback, reviewed, single
                selpaneltab: 'not_reviewed',
                


                roles: { ca: false, cc: false, idea: false, inv: false },

                filterCluster: '',
                filterDivision: '',
                filterStatus: null,
                filterMonth: null,
                isExpand: false,

                themeList: [],
                ideas: [],
                ideaOwners: [],

                reportTitle: '',
                currentIdeaRowNum: null,
                modalPart: 0,

                awardCatList: [
                    { value: '', text: '--- Please select an option ---' },
                    { value: 'Individual', text: 'Individual' },
                    { value: 'Team', text: 'Team' },
                    { value: 'Innovation', text: 'Innovation' },
                    { value: 'Young achiever', text: 'Young achiever < 35' },
                    { value: 'General employee', text: 'General employee (non-management)' },
                ],

                adhocDisplayPart: 0,
				fAdhoc: {
					form: {
						what: 'adhoc2', reason: 'Cultivates Innovation', points: null, message: '', sendBy: null, recipients: [] }
                },
                adhocMems: [],
                adhocSelectedMems: []
            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
			pointsOptions() {
				const pointsList = [5, 10, 20, 25, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 1000, 2000, 3000, 4000, 5000];
				let p = [{ text: '--- Select Amount to Award---', value: null, disabled: true }];
				pointsList.forEach(n => p.push({ text: n + '  e-points', value: n }));
				return p;
			},
            //filterClusterList() {
            //    if (this.isCountryFixed) { //only show the default cluster - i.e. When panelist tab selected
            //        return this.$store.state.countryList.filter(country => country.countryCode === this.defaultCountryCode);
            //    } else {
            //        return this.$store.state.countryList;
            //    }
            //},
            //filterDivisionList() {
            //    return this.$store.state.divisionList.filter(division => division.countryCode === this.filterClusterCode);
            //},

            countryList() { //used for new idea
                if (this.isCountryFixed) { //only show the default cluster.
                    return this.$store.state.countryList.filter(country => country.countryCode === this.defaultCountryCode);
                } else {
                    return [{ countryCode: null, country: '-- Please select a Cluster--', disabled: true }]
                        .concat(this.$store.state.countryList);
                }
            },
			divisionList() { //used for new idea
                return [{ divisionCode: null, division: '-- Please select an Division--', disabled: false }]
                    .concat(this.$store.state.divisionList.filter(division => division.countryCode === this.fIdea.form.clusterCode));
            },
            memberClusterCode() {
                return this.$store.state.memberStore.member.clusterCode
            },
            memberDivisionCode() {
                return this.$store.state.memberStore.member.divisionCode
            },
            isCanReview() {
                return this.roles.idea;// && this.memberClusterCode === 'sra' //Only SRA can review an idea
            },
            currentIdea() {
                if (this.currentIdeaRowNum === null) return null;
                return this.ideas[this.currentIdeaRowNum];
            },
            ideaClusters() {
                return [...new Set(this.ideas.map(n => n.cluster))].sort()
                //return [...new Set(this.ideas.map(n => { return { clusterCode: n.clusterCode, cluster: n.cluster } }))].sort()
            },
            ideasFilteredOnCluster() {
                return (this.filterCluster === '') ? this.ideas : this.ideas.filter(n => n.cluster == this.filterCluster);
            },
            ideaDivisions() {
                return (this.filterCluster === '') ? [] : [...new Set(this.ideasFilteredOnCluster.map(n => n.division))].sort()
                //return [...new Set(this.ideas.map(n => { return { clusterCode: n.clusterCode, cluster: n.cluster } }))].sort()
            },



            ideaMonths() {
                const result = [];
                const map = new Map();
                for (const n of this.ideas) {
                    let d = new Date(n.dateSubmitted + 'Z');
                    let v = { value: { m: d.getMonth(), y: d.getFullYear(), d }, text: d.sjFormat('MMMM yyyy') };
                    let z = v.value.y * 1000 + v.value.m;
                    if (!map.has(z)) {
                        map.set(z, true);    // set any value to Map
                        result.push(v);
                    }
                }
                return result.sort((a, b) => ((1000 * a.value.y + a.value.m) - (1000 * b.value.y + b.value.m)));
            },
            recsFiltered() {
                let fltdRecs = this.ideas;
                if (this.filterCluster !== '') fltdRecs = fltdRecs.filter(n => n.cluster == this.filterCluster);
                if (this.filterDivision !== '') fltdRecs = fltdRecs.filter(n => n.division == this.filterDivision);
               
                //var fltrStatus = this.filterStatus;
                //if (fltrStatus !== null) fltdRecs = fltdRecs.filter(n =>
                //	(fltrStatus === 'review' && !n.reviewIsDone)
                //	|| (fltrStatus === 'rate' && n.reviewIsDone && !n.ratingIsDone)
                //	|| (fltrStatus === 'rated' && n.reviewIsDone && n.ratingCount != 0)
                //); //review, rate, rated

                var fltrMonth = this.filterMonth;
                if (fltrMonth !== null) fltdRecs = fltdRecs.filter(n => {
                    let d = new Date(n.dateSubmitted + 'Z');
                    return d.getMonth() === fltrMonth.m && d.getFullYear() === fltrMonth.y;
                });

                return fltdRecs;
            },
            panelRecs() {
                /*
                            isUseSpecialist    splstDone    isFwdToPanel    isReviewComplete    isReviewDeleted
not_reviewed                        No                            No                No
awaiting_specialistfeedback         Yes             No            No                No
received_specialistfeedback         Yes             Yes           No                No
evaluation                                                        Yes               No
panel_evaluation                                                  Yes               No
Concluded                                                                           Yes               No
Deleted                                                                             Yes               No
                 */

                //PANELISTS: not_reviewed, panel_evaluation, awaiting_specialistfeedback, received_specialistfeedback, reviewed(concluded, deleted)
                let recs = this.recsFiltered;
                let cnts = {
                    not_reviewed: recs.filter(n => !n.isReviewComplete && !n.isUseSpecialist && !n.isFwdToPanel),
					awaiting_specialistfeedback: recs.filter(n => !n.isReviewComplete && n.isUseSpecialist && !n.splstDone && !n.isFwdToPanel),
					received_specialistfeedback: recs.filter(n => !n.isReviewComplete && n.isUseSpecialist && n.splstDone && !n.isFwdToPanel),
					evaluation: recs.filter(n => !n.isReviewComplete && n.isFwdToPanel),
					panel_evaluation: recs.filter(n => !n.isReviewComplete && n.isFwdToPanel),
                    concluded: recs.filter(n => n.isReviewComplete && !n.isReviewDeleted),
                    deleted: recs.filter(n => n.isReviewComplete && n.isReviewDeleted),
                }
                return cnts;
            },
            recsFilteredList() {
                if (this.selpaneltab === 'not_reviewed') return this.panelRecs.not_reviewed;
				else if (this.selpaneltab === 'evaluation') return this.panelRecs.evaluation;
                else if (this.selpaneltab === 'awaiting_specialistfeedback') return this.panelRecs.awaiting_specialistfeedback;
                else if (this.selpaneltab === 'received_specialistfeedback') return this.panelRecs.received_specialistfeedback;
				else if (this.selpaneltab === 'panel_evaluation') return this.panelRecs.panel_evaluation;
                else if (this.selpaneltab === 'concluded') return this.panelRecs.concluded;
                else if (this.selpaneltab === 'deleted') return this.panelRecs.deleted;
                return this.recsFiltered;
            },

            //ADHOC
			isSendBySMS() {
				return this.fAdhoc.form.sendBy === 'sms';
            },
            //ADHOC
			valueOptions() {
				const santamOptions = [
					//{ html: '<b>--- Santam Options ---</b>', value: null, disabled: true },
					//{ text: 'EXCELLENCE', value: 'EXCELLENCE' },
					//{ text: 'HUMANITY', value: 'HUMANITY' },
					//{ text: 'INTEGRITY', value: 'INTEGRITY' },
					//{ text: 'INNOVATION', value: 'INNOVATION' },
					//{ text: 'PASSION', value: 'PASSION' },
					//{ html: '<b>--- Group Options ---</b>', disabled: true }
				];

                const groupOptions = [
                    //{ text: 'Care', value: 'Care' },
                    //{ text: 'Collaboration', value: 'Collaboration' },
                    //{ text: 'Integrity', value: 'Integrity' },
                    { text: 'Innovation', value: 'Innovation' },
                ];
				return this.memberClusterCode === 'stm' ? [...santamOptions, ...groupOptions] : [{ text: '--- Select an Option ---', value: null }, ...groupOptions];
			},


        },
        created() {
            console.log('in created');

            //formSetState must be called on creation, to ensure all fields are setup
            this.formSetState(this.fIdea, true, true) //formObj, resetState, resetForm
            this.formSetState(this.fMemberComment, true, true) //formObj, resetState, resetForm
			this.formSetState(this.fRequestShare, true, true);//formObj, resetState, resetForm
            this.formSetState(this.fRequestSpecialist, true, true);//formObj, resetState, resetForm
            this.formSetState(this.fSpecialistFeedback, true, true);//formObj, resetState, resetForm
            this.formSetState(this.fConclude, true, true);//formObj, resetState, resetForm
			this.formSetState(this.fRewardDecision, true, true);//formObj, resetState, resetForm
			this.formSetState(this.fEditDivision, true, true);//formObj, resetState, resetForm

			this.formSetState(this.fAdhoc, true, true) //formObj, resetState, resetForm


            this.$store
                .dispatch('getDivisionList', {})
                .then(() => {

                })
                .catch((error) => {
                    this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error)));
                });

            this.filterCluster = this.$store.state.memberStore.member.cluster;
            this.filterDivision = this.$store.state.memberStore.member.division;
            this.displayPart = 0;
        },
        mounted() {
            console.log('in mounted');
            if (this.memberClusterCode) {
                this.filterCluster = this.$store.state.memberStore.member.cluster;
                this.filterDivision = this.$store.state.memberStore.member.division;
            }
            this.loadIdeas();
        },
        watch: {
            memberClusterCode(clusterCode) {
                if (clusterCode) {
                    console.log('watch memberClusterCode');
                    console.log(this.$store.state.memberStore.member.cluster);
                    console.log(this.$store.state.memberStore.member.division);
                    console.log(this.what);
                    this.filterCluster = this.$store.state.memberStore.member.cluster;
                    this.filterDivision = this.$store.state.memberStore.member.division;
                }
            },
            //memberDivisionCode(divisionCode) {
            //    if (divisionCode) {
            //        this.filterDivisionCode = divisionCode;
            //        this.loadIdeas(this.what);
            //    }
            //},
            //filterDivisionCode() {
            //    //;
            //},
        },
        methods: {

            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },

            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },

            onShowNewIdea() {
                this.formSetState(this.fIdea, true, true);
                const mem = this.$store.state.memberStore.member;
                this.fIdea.form.clusterCode = this.memberClusterCode;
                this.fIdea.form.divisionCode = this.memberDivisionCode;
                this.selectedIdeaOwners = [];

                this.selectedIdeaOwners.push({
                    _serialNo: -1,
                    division: mem.division,
                    firstname: mem.firstname,
                    surname: mem.surname,
                    isManager: mem.isManager,
                    memberId: mem.memberId,
                    memberNumber: mem.memberNumber,
                    isEntireDivision: false,
                    entireDivisionCode: ''
                });
                this.getThemeList();
            },

            getThemeList() {
                axios.post('/api/idea/getthemelist', { clusterCode: this.memberClusterCode })
                    .then(({ data }) => {
                        this.displayPart = 2;
                        //debugger;
                        let rs = new sjData(data.themeList).data;
                        if (rs.length === 0) { //No themes defined, so setup "General Idea" theme
                            this.themeList = [{ themeValue: 'General Idea', theme: 'General Idea', disabled: false }];
                            this.fIdea.form.theme = 'General Idea';
                        } else {
                            this.themeList = [
                                { themeValue: null, theme: '-- Please select a theme --', disabled: true },
                                { themeValue: 'General Idea', theme: 'General Idea', disabled: false }
                            ]
                                .concat(//NomCat does not have separate fields for name & value, so we need to make one...
                                    rs.map(e => { return { themeValue: e.theme, theme: e.theme }; })
                                );
                        }
                    })
                    .catch((error) => {
                        this.msgBox(error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error)));
                    })
            },

            onSubmitNewIdea() {
                this.formSetState(this.fIdea, true);//formObj, resetState, resetForm
                this.fIdea.form.owners = this.selectedIdeaOwners.map(item => item.isEntireDivision ? 'div:' + item.entireDivisionCode : item.memberId)

                return axios
                    .post(
                        '/api/idea/createnewidea',
                        this.fIdea.form
                    )
                    .then(({ data }) => {
                        this.ideaId = data.ideaIdHashed;
                        this.isSparkIdea = null;
                        this.displayPart = 3;
                    })
                    .catch((error) => {
                        this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
                        if (error.response.data.formErrors) {
                            this.fIdea.errors = error.response.data.formErrors;
                            this.formSetState(this.fIdea);
                        }
                    })
                    ;
            },

            onSubmitSparkIdea() {
                if (!this.ideaId) {
                    this.msgBox("Invalid Idea Id");
                    return;
                }
                if (!this.isSparkIdea) {
                    this.msgBox("Please select if you wish to \"Spark\" your idea");
                    return;
                }

                if (this.isSparkIdea === "true") {
                    return axios
                        .post(
                            '/api/idea/sparkidea',
                            { ideaId: this.ideaId, isSparkIdea: this.isSparkIdea }
                        )
                        .then(({ data }) => {
                            if (this.isSparkIdea) {
                                const msg = "Thank you, you have Sparked your Idea"
                                this.msgBox(msg);
                            }
                            this.what = '';
                            this.loadIdeas();
                            this.displayPart = 0;
                        })
                        .catch((error) => {
                            this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
                            if (error.response.data.formErrors) {
                                this.fIdea.errors = error.response.data.formErrors;
                                this.formSetState(this.fIdea);
                            }
                        })
                        ;
                } else {
                    this.what = '';
                    this.loadIdeas();
                    this.displayPart = 0;
                }

            },



            loadIdeas() {

                const seltab = this.seltab;
                let what = "";
                if (seltab == "ideas") {
                    what = seltab;
                    this.reportTitle = 'Ideas';
                    this.filterCluster = this.$store.state.memberStore.member.cluster;
                    this.filterDivision = ""; //this.$store.state.memberStore.member.division;
                    this.selpaneltab = "";

                } else if (seltab == "my_ideas") {
                    what = seltab;
                    this.reportTitle = 'My Ideas';
                    this.filterCluster = "";
                    this.filterDivision = "";
					this.selpaneltab = "";


                } else if (seltab == "specialist") {
                    what = seltab;
                    this.reportTitle = 'Feedback required from you (as a specialist):';
                    this.filterCluster = "";
                    this.filterDivision = "";
					this.selpaneltab = "";

				} else if (seltab == "panelist" || seltab == "admin") {
					
                    let selpaneltab = this.selpaneltab;
                    if (selpaneltab == "") {
						selpaneltab = seltab == "panelist" ? "not_reviewed" : "panel_evaluation"; ////not_reviewed, awaiting_specialistfeedback, received_specialistfeedback, panel_evaluation, concluded, deleted
						this.filterCluster = this.$store.state.memberStore.member.cluster;
                        
						
                    }
                    if (selpaneltab == "not_reviewed") {
                        what = "not_reviewed";
                        this.reportTitle = 'Awaiting Review';

                    } else if (selpaneltab == "awaiting_specialistfeedback") {
                        what = "not_reviewed";
                        this.reportTitle = 'Awaiting Specialist Feedback';

                    } else if (selpaneltab == "received_specialistfeedback") {
                        what = "not_reviewed";
                        this.reportTitle = 'Specialist Has Responded';

                    } else if (selpaneltab == "evaluation") {
						what = "not_reviewed";
                        this.reportTitle = 'Sent for Panel Evaluation';


                    } else if (selpaneltab == "panel_evaluation") {
                        what = "not_reviewed";
                        this.reportTitle = 'Sent for Panel Evaluation';

                    } else if (selpaneltab == "concluded") {
						what = "reviewed";
                        this.reportTitle = 'Concluded Ideas';

                    } else if (selpaneltab == "deleted") {
                        what = "deleted";
                        this.reportTitle = 'Deleted Ideas';

                    } else {
                        this.msgBox("Unknown selpaneltab: " + selpaneltab);
                    }
                    this.selpaneltab = selpaneltab;


                } 

                //PANELISTS: not_reviewed, panel_evaluation, awaiting_specialistfeedback, received_specialistfeedback, reviewed
               

                if (this.what === what) return;

                this.ideas = [];
                this.what = what;
                axios.post('/api/idea/idealist', { what: what })
                    .then(({ data }) => {
                        this.roles = data.roles;
                        this.isSpecialistIdeasWaiting = data.isSpecialistIdeasWaiting;
						this.panelistCount = data.panelistCount;
						this.adminPanelEvalCount = data.adminPanelEvalCount;


                        let ideas = new sjData(data.ideas).data;
                        let owners = new sjData(data.owners).data;
                        let comments = new sjData(data.comments).data;

                        for (const idea of ideas) {
                            idea.owners = owners.filter(rec => rec.ideaId == idea.ideaId); //append owners array to each idea
                            idea.memberComments = comments.filter(rec => rec.ideaId == idea.ideaId); //append memberComments array to each idea
                        }

                        this.ideas = ideas;
                    })
                    .catch((error) => {
                        if (error) {
                            this.msgBox(error.response.data.message);
                        }
                    })
            },
            setTemporaryIdeaStatus(rowNum, msg) {
                let idea = this.ideas[rowNum];
                idea.ideaStatusDesc = msg; //'(Refreshing - Please wait)';
            },

            refreshSingleIdea(rowNum, data) {
                let idea = this.ideas[rowNum];
                let ideasTmp = new sjData(data.ideas).data;
                let ownersTmp = new sjData(data.owners).data;
                let commentsTmp = new sjData(data.comments).data;
                let i = ideasTmp[0];
                i['_rowNum'] = rowNum; //!!! We must set this new record to the existing rowNum of the row we are replacing !
                i.owners = ownersTmp.filter(rec => rec.ideaId == idea.ideaId); //append owners array to each idea
                i.memberComments = commentsTmp.filter(rec => rec.ideaId == idea.ideaId); //append memberComments array to each idea
                this.$set(this.ideas, rowNum, i);   //use this.$set  to make item reactive  this.ideas[this.currentIdeaRowNum] = idea;
				this.isSpecialistIdeasWaiting = data.isSpecialistIdeasWaiting;
				this.panelistCount = data.panelistCount;
				this.adminPanelEvalCount = data.adminPanelEvalCount;

            },




            onLike(rowNum) {
                const idea = this.ideas[rowNum];
                let origStatusDesc = idea.ideaStatusDesc;
                this.setTemporaryIdeaStatus(rowNum, '(Updating...)');
                return axios
                    .post(
                        '/api/idea/likecommentsubmit', { ideaId: idea.ideaId, isLike: true, comment: '' }
                    )
                    .then(({ data }) => {
                        idea.memberAlreadyLiked = true;
                        idea.likeCount = data.likeCount;
                        this.setTemporaryIdeaStatus(rowNum, origStatusDesc);
                        //console.log(data);
                        //this.msgBox('Successfully Liked');
                    })
                    .catch((error) => {
                        this.setTemporaryIdeaStatus(this.currentIdeaRowNum, origStatusDesc);
                        this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
                    });
            },

            onShowModalMemberComment(rowNum) {
                this.formSetState(this.fMemberComment, true, true);//formObj, resetState, resetForm
                this.currentIdeaRowNum = rowNum;
                this.$bvModal.show('modalMemberComment')
            },

            onSubmitMemberComment() {
                this.formSetState(this.fMemberComment, true);//formObj, resetState, resetForm

                let idea = this.ideas[this.currentIdeaRowNum];
                this.fMemberComment.form.ideaId = idea.ideaId;
                this.fMemberComment.form.isLike = false;
                const origStatusDesc = idea.ideaStatusDesc;

                this.setTemporaryIdeaStatus(this.currentIdeaRowNum, '(Updating...)');
                return axios
                    .post(
                        '/api/idea/likecommentsubmit', this.fMemberComment.form
                    )
                    .then(({ data }) => {
                        this.$bvModal.hide('modalMemberComment');
                        this.refreshSingleIdea(this.currentIdeaRowNum, data);
                    })
                    .catch((error) => {
                        this.setTemporaryIdeaStatus(this.currentIdeaRowNum, origStatusDesc);
                        this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
                        if (error.response.data.formErrors) {
                            this.fMemberComment.errors = error.response.data.formErrors;
                            this.formSetState(this.fMemberComment);
                        }
                    });
            },

            //onSetAdminReview(rowNum) {
            //    this.currentIdeaRowNum = rowNum;
            //    const idea = this.ideas[rowNum];
            //    const origStatusDesc = idea.ideaStatusDesc;
            //    const h = this.$createElement;
            //    const msg = h('div', { domProps: { innerHTML: 'Confirm you wish to change the status to <b>Under Review</b>?' } });
            //    this.$bvModal.msgBoxConfirm(msg, {
            //        title: 'Change Status',
            //        centered: true,
            //        autoFocusButton: 'ok'
            //    }).then(value => {
            //        if (!value) return; //user push cancel
            //        this.setTemporaryIdeaStatus(this.currentIdeaRowNum, '(Updating...)');
            //        return axios
            //            .post(
            //                '/api/idea/setStatus', { ideaId: idea.ideaId, ideaStatus: 1 } //1:under review (with administrator)
            //            )
            //            .then(({ data }) => {
            //                this.refreshSingleIdea(this.currentIdeaRowNum, data);
            //            })
            //            .catch((error) => {
            //                this.setTemporaryIdeaStatus(this.currentIdeaRowNum, origStatusDesc);
            //                this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
            //            });
            //    }).catch(err => {
            //        console.log(err)
            //        // An error occurred
            //    })
            //},

            onSendToPanel(rowNum) {
                this.currentIdeaRowNum = rowNum;
                const idea = this.ideas[rowNum];
                const origStatusDesc = idea.ideaStatusDesc;
                const h = this.$createElement;
                const msg = h('div', { domProps: { innerHTML: 'Confirm you wish to hand this idea to the Evaluation Panel?' } })
                this.$bvModal.msgBoxConfirm(msg, {
                    title: 'Hand to Evaluation Panel',
                    centered: true,
                    autoFocusButton: 'ok'
                }).then(value => {
                    if (!value) return; //user push cancel
                    this.setTemporaryIdeaStatus(this.currentIdeaRowNum, '(Updating...)');
                    return axios
                        .post(
                            '/api/idea/setStatus', { ideaId: idea.ideaId, ideaStatus: 'panel_evaluation' } //1:under review (with panel)
                        )
                        .then(({ data }) => {
                            this.refreshSingleIdea(this.currentIdeaRowNum, data);
                        })
                        .catch((error) => {
                            this.setTemporaryIdeaStatus(this.currentIdeaRowNum, origStatusDesc);
                            this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
                        });
                }).catch(err => {
                    console.log(err)
                    // An error occurred
                })
            },


            onShowModalRequestShare(rowNum) {
                this.formSetState(this.fRequestShare, true, true);//formObj, resetState, resetForm
                this.currentIdeaRowNum = rowNum;
                this.$bvModal.show('modalRequestShare')
            },
            onSubmitRequestShare() {
				this.fRequestShare.form.selectedRecipients = this.fRequestShare.selectedRecipients.map(item => item.isEntireDivision ? 'div:' + item.entireDivisionCode : item.memberId)
                
				if (this.fRequestShare.form.selectedRecipients.length < 1) {
					this.msgBox('Please select a recipient', 'A recipient to share this idea is required');
					return;
				}

				this.formSetState(this.fRequestShare, true);//formObj, resetState, resetForm

                let idea = this.ideas[this.currentIdeaRowNum];
                this.fRequestShare.form.ideaId = idea.ideaId;
				return axios
					.post(
                        '/api/idea/requestshare', this.fRequestShare.form
					)
					.then(() => {
						this.$bvModal.hide('modalRequestShare');
						this.msgBox('The idea has been shared by email to the selected recipient(s).', 'Share Idea');

					})
					.catch((error) => {
						this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
						if (error.response.data.formErrors) {
							this.fRequestShare.errors = error.response.data.formErrors;
							this.formSetState(this.fRequestShare);
						}
					});
			},
			onShowModalRequestSpecialist(rowNum) {
				this.formSetState(this.fRequestSpecialist, true, true);//formObj, resetState, resetForm
				this.currentIdeaRowNum = rowNum;
				this.$bvModal.show('modalRequestSpecialist')
			},
            onSubmitRequestSpecialist() {
                if (this.fRequestSpecialist.selectedSpecialist.length !== 1) {
                    this.msgBox('Please select a specialist', 'A Specialist is required');
                    return;
                }

                this.formSetState(this.fRequestSpecialist, true);//formObj, resetState, resetForm

                let idea = this.ideas[this.currentIdeaRowNum];
                this.fRequestSpecialist.form.ideaId = idea.ideaId;
                this.fRequestSpecialist.form.splstMemberId = this.fRequestSpecialist.selectedSpecialist[0].memberId;
                const origStatusDesc = idea.ideaStatusDesc;

                this.setTemporaryIdeaStatus(this.currentIdeaRowNum, '(Updating...)');
                return axios
                    .post(
                        '/api/idea/requestspecialist', this.fRequestSpecialist.form
                    )
                    .then(({ data }) => {
                        this.$bvModal.hide('modalRequestSpecialist');
                        this.refreshSingleIdea(this.currentIdeaRowNum, data);
                        this.msgBox('The request has been sent to the specialist.', 'Forward to Specialist');

                    })
                    .catch((error) => {
                        this.setTemporaryIdeaStatus(this.currentIdeaRowNum, origStatusDesc);
                        this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
                        if (error.response.data.formErrors) {
                            this.fRequestSpecialist.errors = error.response.data.formErrors;
                            this.formSetState(this.fRequestSpecialist);
                        }
                    });
            },
            onShowModalSpecialistFeedback(rowNum, onBehalfOfSpecialist) {
                this.formSetState(this.fSpecialistFeedback, true, true);//formObj, resetState, resetForm
                this.currentIdeaRowNum = rowNum;
                this.fSpecialistFeedback.onBehalfOfSpecialist = onBehalfOfSpecialist;
                this.$bvModal.show('modalSpecialistFeedback')
            },
            onSubmitSpecialistFeedback() {

                this.formSetState(this.fSpecialistFeedback, true);//formObj, resetState, resetForm

                let idea = this.ideas[this.currentIdeaRowNum];
                this.fSpecialistFeedback.form.ideaId = idea.ideaId;
                if (this.fSpecialistFeedback.onBehalfOfSpecialist) {
                    this.fSpecialistFeedback.form.splstMsgToAdmin = '(Submitted on behalf of the specialist)';
                }

                const origStatusDesc = idea.ideaStatusDesc;
                this.setTemporaryIdeaStatus(this.currentIdeaRowNum, '(Updating...)');
                return axios
                    .post(
                        '/api/idea/specialistfeedback', this.fSpecialistFeedback.form
                    )
                    .then(({ data }) => {
                        this.$bvModal.hide('modalSpecialistFeedback');
                        if (this.what === 'specialist') {
                            this.what = '';
                            this.seltab = 'ideas'
                            this.loadIdeas();
                        } else {
							this.refreshSingleIdea(this.currentIdeaRowNum, data);
						}
                    })
                    .catch((error) => {
                        this.setTemporaryIdeaStatus(this.currentIdeaRowNum, origStatusDesc);
                        this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
                        if (error.response.data.formErrors) {
                            this.fSpecialistFeedback.errors = error.response.data.formErrors;
                            this.formSetState(this.fSpecialistFeedback);
                        }
                    });
            },
            /*
			onShowModalReward(rowNum) {
				this.currentIdeaRowNum = rowNum;
				this.$bvModal.show('modalReward')
            },
			onSubmitReward() {
				const idea = this.ideas[this.currentIdeaRowNum];
				const origStatusDesc = idea.ideaStatusDesc;
				this.setTemporaryIdeaStatus(this.currentIdeaRowNum, '(Updating...)');
				return axios
					.post(
						'/api/idea/reward', { ideaId: idea.ideaId }
					)
					.then(() => { //{data}
						this.$bvModal.hide('modalReward');
                        //this.refreshSingleIdea(this.currentIdeaRowNum, data);
						this.doLinkOut('/adhocrecognition', idea.ideaId);
					})
					.catch((error) => {
						this.setTemporaryIdeaStatus(this.currentIdeaRowNum, origStatusDesc);
						this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
						if (error.response.data.formErrors) {
							this.fConclude.errors = error.response.data.formErrors;
							this.formSetState(this.fConclude);
						}
					});
            },*/
			doLinkOut(whereto, ideaId) {
				sessionStorage.setItem('tloidea', ideaId);//tloid - temp link out id;
				this.$router.push(whereto)
			},
			onShowModalDelete(rowNum) {
				this.formSetState(this.fConclude, true, true);//formObj, resetState, resetForm
				this.fConclude.form.isReviewDeleted = true; //Set IsDeleted
				this.currentIdeaRowNum = rowNum;
				this.$bvModal.show('modalDelete')
            },
            onShowModalEditDivision(rowNum) {
                this.currentIdeaRowNum = rowNum;
				let idea = this.ideas[this.currentIdeaRowNum];
				this.formSetState(this.fIdea, true, true);
				this.fIdea.form.clusterCode = idea.clusterCode;
				this.fIdea.form.divisionCode = idea.divisionCode;
				this.$bvModal.show('modalEditDivision')
			},
			onSubmitEditDivision() {
				this.formSetState(this.fIdea, true);//formObj, resetState, resetForm

				let idea = this.ideas[this.currentIdeaRowNum];
				this.fIdea.form.ideaId = idea.ideaId;

				const origStatusDesc = idea.ideaStatusDesc;
				this.setTemporaryIdeaStatus(this.currentIdeaRowNum, '(Updating...)');
				return axios
					.post(
						'/api/idea/editdivision', this.fIdea.form
					)
					.then(({ data }) => {
						this.$bvModal.hide('modalEditDivision');
						this.refreshSingleIdea(this.currentIdeaRowNum, data);
					})
					.catch((error) => {
						this.setTemporaryIdeaStatus(this.currentIdeaRowNum, origStatusDesc);
						this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
						if (error.response.data.formErrors) {
							this.fConclude.errors = error.response.data.formErrors;
							this.formSetState(this.fConclude);
						}
					});
			},

            onShowModalConclude(rowNum) {
                this.formSetState(this.fConclude, true, true);//formObj, resetState, resetForm
                this.fConclude.form.isReviewDeleted = false;
                this.currentIdeaRowNum = rowNum;
                this.$bvModal.show('modalConclude')
            },
            onSubmitConclude(action) {

                this.formSetState(this.fConclude, true);//formObj, resetState, resetForm

                let idea = this.ideas[this.currentIdeaRowNum];
                this.fConclude.form.ideaId = idea.ideaId;

                const origStatusDesc = idea.ideaStatusDesc;
                this.setTemporaryIdeaStatus(this.currentIdeaRowNum, '(Updating...)');
                return axios
                    .post(
                        '/api/idea/conclude', this.fConclude.form
                    )
                    .then(({ data }) => {
						this.$bvModal.hide(this.fConclude.form.isReviewDeleted ? 'modalDelete' : 'modalConclude');
						this.refreshSingleIdea(this.currentIdeaRowNum, data);

                        if (action == 'reward') {
							//this.doLinkOut('/adhocrecognition', idea.ideaId);
							this.onShowModalReward(this.currentIdeaRowNum);
						}

                    })
                    .catch((error) => {
                        this.setTemporaryIdeaStatus(this.currentIdeaRowNum, origStatusDesc);
                        this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
                        if (error.response.data.formErrors) {
                            this.fConclude.errors = error.response.data.formErrors;
                            this.formSetState(this.fConclude);
                        }
                    });
            },
            onShowModalRewardDecision(rowNum) {
                this.formSetState(this.fRewardDecision, true, true);//formObj, resetState, resetForm
                this.currentIdeaRowNum = rowNum;
                this.$bvModal.show('modalRewardDecision')
            },
            onSubmitRewardDecision() {

                this.formSetState(this.fRewardDecision, true);//formObj, resetState, resetForm

                let idea = this.ideas[this.currentIdeaRowNum];
                this.fRewardDecision.form.ideaId = idea.ideaId;

                if (this.fRewardDecision.form.isCEOaward) {
                    let nominators = this.selectedNominators.filter(item => !item.isEntireDivision);
                    if (nominators.length !== 2) {
                        this.msgBox('Please select two nominators to support your nomination.', 'Two nominators are required');
                        return;
                    }
                    this.fRewardDecision.form.nominatorMemberId1 = nominators[0].memberId;
                    this.fRewardDecision.form.nominatorMemberId2 = nominators[1].memberId;
                } else {
                    this.fRewardDecision.form.awardCat = '';
                    this.fRewardDecision.form.achievements = '';
                    this.fRewardDecision.form.actions = '';
                    this.fRewardDecision.form.measures = '';
                    this.fRewardDecision.form.motivation = '';
                    this.fRewardDecision.form.nominatorMemberId1 = null;
                    this.fRewardDecision.form.nominatorMemberId2 = null;
                }

                const origStatusDesc = idea.ideaStatusDesc;
                this.setTemporaryIdeaStatus(this.currentIdeaRowNum, '(Updating...)');

                return axios
                    .post(
                        '/api/idea/rewarddecision', this.fRewardDecision.form
                    )
                    .then(({ data }) => {
                        this.$bvModal.hide('modalRewardDecision');
                        this.refreshSingleIdea(this.currentIdeaRowNum, data);
                        this.msgBox('Innovative Award concluded successfully', 'Innovative Award Concluded');

                    })
                    .catch((error) => {
                        this.setTemporaryIdeaStatus(this.currentIdeaRowNum, origStatusDesc);
                        this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
                        if (error.response.data.formErrors) {
                            this.fRewardDecision.errors = error.response.data.formErrors;
                            this.formSetState(this.fRewardDecision);
                        }
                    });
            },

            onShowModalReward(rowNum) {
				this.adhocDisplayPart = 0;
                this.currentIdeaRowNum = rowNum;
               // debugger;
                let idea = this.ideas[this.currentIdeaRowNum];
                this.formSetState(this.fAdhoc, true, true) //formObj, resetState, resetForm
                this.adhocMems = [];
                this.$bvModal.show('modalReward');

				axios.post('/api/idea/getideaowners', { ideaId: idea.ideaId })
					.then(({ data }) => {
						let rsOwners = new sjData(data.owners).data;
                        this.adhocMems = rsOwners;
						this.adhocSelectedMems = rsOwners.map(item => item.memberId);
                        /*
                        var serialNo = 0;

						rsOwners.forEach(rec => {
							serialNo--;
                            this.adhocMems.push({
								_serialNo: serialNo,
								division: rec.companyName,
								firstname: rec.firstName,
								surname: rec.surname,
								isManager: false,
								memberId: rec.memberId,
								memberNumber: rec.memberNumber,
								isEntireDivision: false,
								entireDivisionCode: ''
							});
						})
                        */

					})
					.catch((error) => {
						if (error) {
							this.msgBox('Error retrieving member data');
						}
					})
            },

            onSubmitAdhoc() {
                // points: null, message: '', sendBy: null,
                this.formSetState(this.fAdhoc, true);//formObj, resetState, resetForm

                //
                this.fAdhoc.form.recipients = this.adhocSelectedMems;//this.adhocSelectedMems.map(item => item.memberId);
				if (this.fAdhoc.form.recipients.length === 0) {
					this.msgBox('Unexpected - Please select at least one recipient.', 'Recipients required');
					return;
				}
				const idea = this.ideas[this.currentIdeaRowNum];

				//this.displayPart = 1;
				if (this.adhocDisplayPart == 0) {
					return axios
						.post(
							'/api/reward/doreward',
							this.fAdhoc.form
						)
                        .then(() => {
							return axios
								.post(
									'/api/idea/setRewardStatus', { ideaId: idea.ideaId, points: this.fAdhoc.form.points }
								)
								.then(({ data }) => {
                                    this.refreshSingleIdea(this.currentIdeaRowNum, data);
                                    this.adhocDisplayPart = 1;
                                    this.adhocSelectedMems = [];
                                    this.formSetState(this.fAdhoc, true, true);
                                    this.$store.dispatch('memberStore/getMemberData');
									//this.msgBox('Successfully submitted nomination');
								})
								.catch((error) => {
									this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
									if (error.response.data.formErrors) {
										this.fAdhoc.errors = error.response.data.formErrors;
										this.formSetState(this.fAdhoc);
									}
								});

							//this.msgBox('Successfully submitted nomination');
						})
						.catch((error) => {
							this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
							if (error.response.data.formErrors) {
								this.fAdhoc.errors = error.response.data.formErrors;
								this.formSetState(this.fAdhoc);
							}
						})
						;
				}
			},

        },




    }
</script>
