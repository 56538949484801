<template>
    <div v-if="isLoggedIn && (memberClusterCode=='sra' || memberClusterCode=='sf')" class="container page">
        <!--REMOVE false above to renable this page -->


        <!--<b-alert v-if="!isAllowAccess" show>Only authorised personnel may access this module.</b-alert>-->
        <div class="text-right"><router-link to="/awardnomination" class="mr-1 btn btn-secondary">Back</router-link></div>

        <template v-if="displayPart==0">
            Please wait, loading nominations
        </template>

        <template v-if="displayPart==1">

            <section class="card card-default mb-1">
                <div class="card-header">
                    <div class="row">
                        <div class="col-sm-3 font-weight-bold">
                            AWARD NOMINATIONS
                        </div>

                        <div class="col-lg-1">

                        </div>
                        <!--<template v-if="roles.ca">-->
                        <!-- Only for Cluster Admin -->
                        <!--<div class="col-lg-2 text-right font-weight-bold" style="font-size:larger">
                            {{nomsSelected.length}} Selected
                        </div>
                        <div class="col-lg-2 text-right">
                            <b-form-checkbox name="check-button" button size="sm" v-model="filterShowSel" :disabled="!filterShowSel && nomsSelected.length==0">
                                Show {{ filterShowSel ? 'All' : 'Selected' }}
                            </b-form-checkbox>
                        </div>
                        <div class="col-sm-2 text-right">
                            <b-button size="sm" v-if="roles.ca" @click.prevent="showConclude" :disabled="nomsSelected.length==0">Conclude Selected</b-button>
                        </div>
                        <div class="col-sm-2 text-right">
                            <router-link to="/reportawardnominations" class="btn btn-secondary">Reports</router-link>
                        </div>
                    </template>-->
                    </div>
                </div>
                <div class="card-body">
                    <div style="padding-right: 30px; overflow-y:scroll; border-bottom: solid 2px #eee ">
                        <div class="row mb-1">
                            <div class="col-lg-1 text-left"><b-icon icon="trophy" style="color:#ffd800" class="pr-4"></b-icon>#</div>
                            <div class="col-lg-4 font-weight-bold">
                                <b-input-group size="sm">
                                    <b-input-group-prepend is-text>
                                        <b-icon icon="award"></b-icon>
                                    </b-input-group-prepend>
                                    <b-form-select :options="nomCats" v-model="filterNomCat" :disabled="filterShowSel">
                                        <template v-slot:first>
                                            <b-form-select-option :value="null">-- All Award Categories --</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-input-group>
                            </div>
                            <div class="col-lg-3 font-weight-bold text-lg-center">
                                <b-input-group size="sm">
                                    <b-input-group-prepend is-text>
                                        <b-icon icon="calendar"></b-icon>
                                    </b-input-group-prepend>
                                    <b-form-select :options="nomMonths" v-model="filterMonth" :disabled="filterShowSel">
                                        <template v-slot:first>
                                            <b-form-select-option :value="null">-- All Periods --</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-input-group>
                            </div>
                            <div class="col-lg-3">
                                <!--<b-input-group size="sm" v-if="roles.cc || roles.ca">
                                <b-input-group-prepend is-text>
                                    <b-icon icon="card-checklist"></b-icon>
                                </b-input-group-prepend>
                                <b-form-select v-model="filterStatus" :disabled="filterShowSel">
                                    <b-form-select-option :value="null">-- All Statuses --</b-form-select-option>
                                    <b-form-select-option value="review">To Review</b-form-select-option>
                                    <b-form-select-option value="rated">Ratings Submitted</b-form-select-option>
                                    <b-form-select-option :value="null" disabled>------------------</b-form-select-option>
                                    <b-form-select-option value="rate">You have not Rated</b-form-select-option>
                                </b-form-select>
                            </b-input-group>-->
                            </div>
                            <div class="col-lg-1"><!--<b-form-checkbox v-if="roles.ca" size="lg" class="" v-on:change="selectAll" title="Toggle selection of all nominations"></b-form-checkbox>--></div>
                        </div>
                    </div>

                    <div class="scrollBox">
                        <b-form-checkbox-group v-model="nomsSelected" size="lg">
                            <div v-for="n in nomsFiltered" :key="n._rowNum">
                                <div class="row pt-1" :style="(n.nomId === nom.nomId ? 'background-color:#fafafa;' : '' )">
                                    <div class="col-lg-1 text-left"><b-icon icon="trophy" style="color:#ffd800" class="pr-4"></b-icon>{{n.nomId.sjRemoveIntHash()}}</div>
                                    <div class="col-lg-4">
                                        <a href="#" @click.prevent="selectNom(n._rowNum)">{{n.nomCat}}</a>
                                    </div>
                                    <div class="col-lg-3 text-lg-right">
                                        {{ n.dateCreated.sjToDate('d MMM yyyy HH:mm') }}
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="row">
                                            <div class="col-6 text-left">
                                                <!--<b-badge v-if="!n.reviewIsDone" style="background-color:#23be2a">To Review</b-badge>-->
                                                <b-badge v-if="n.isConcluded" style="background-color:#23be2a">Concluded</b-badge>
                                                <b-badge v-else-if="n.ratingCount===0" style="background-color:#c73bc8" v-b-tooltip.hover title="No ratings entered">No ratings</b-badge>
                                                <b-badge v-else style="background-color:#0e0ec3" v-b-tooltip.hover :title="n.ratingCount + ' ratings entered'">{{n.ratingCount}} Ratings</b-badge>
                                            </div>
                                            <div class="col-3">
                                                <!--<b-badge v-if="n.reviewIsDone && !n.ratingIsDone" v-b-tooltip.hover title="You have not submitted a rating" style="background-color:#ff6a00">To Rate</b-badge>-->
                                            </div>
                                            <div class="col-3">
                                                <b-icon v-if="!n.isSingleIndividual" icon="people-fill" style="color: #0e0ec3"></b-icon>
                                                <b-icon v-else icon="person-fill" style="color:#0094ff"></b-icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-1">
                                        <!--<b-form-checkbox v-if="roles.ca" :value="n.nomId" class="" @change="selectNom(n._rowNum)"></b-form-checkbox>-->
                                    </div>
                                </div>
                                <div class="row pb-1" :style="(n.nomId === nom.nomId ? 'background-color:#fafafa;' : '' ) + 'border-bottom:solid 1px #f5f5f5'">
                                    <div class="col-lg-1 text-left"></div>
                                    <div class="col-lg-7" title="Nominee">
                                        <small><i>Nominee:</i> {{n.nominee}}</small>
                                    </div>
                                    <div class="col-lg-3" title="Nominated by">
                                        <small><i>By:</i> {{n.nominator}}</small>
                                    </div>
                                    <div class="col-lg-1">
                                    </div>
                                </div>
                            </div>
                        </b-form-checkbox-group>
                        <div v-if="nomsFiltered.length===0"><i>(No nominations found)</i></div>
                    </div>

                </div>
            </section>

            <!-- Nomination Card -->
            <section class="card card-default mt-3" v-if="nom.nomId != ''">
                <div class="card-header">
                    <div class="row">
                        <div class="col-5 font-weight-bold text-uppercase">{{ nom.nomCat }}</div>
                        <div class="col-3">
                        </div>
                        <div class="col-4 text-right"><strong></strong> #{{ nom.nomId.sjRemoveIntHash() }} <span class="pl-5"> {{ nom.dateCreated.sjToDate('d MMM yyyy HH:mm') }}</span></div>
                    </div>
                </div>
                <div class="card-body">
                    <!-- Nominee List -->
                    <div class="row mb-2">
                        <div class="col-lg-2 fldNameB">Nominated By:</div>
                        <div class="col-lg-4 fldValueB">{{ nom.byMemberName }}</div>
                        <!--<div class="col-lg-6 text-right font-weight-bold"><b-form-checkbox switch size="lg" v-model="nomsSelected" v-if="roles.ca" :value="nom.nomId"><span class=""></span>Select for Conclusion</b-form-checkbox></div>-->
                    </div>


                    <div class="row mb-2">
                        <div class="col-lg-2 fldNameB">Nominees:</div>
                        <div class="col-lg-10 fldValueB">
                            <div v-for="nominee in nomNominees" :key="nominee._rowNum" class="row">
                                <template v-if="!nominee.isEntireDivision">
                                    <div class="col"><b-icon icon="person" style="color:#ccc" class="mr-3"></b-icon> {{nominee.memberName }}</div>
                                    <div class="col font-italic">{{nominee.division}}</div>
                                </template>
                                <template v-else>
                                    <div class="col"><b-icon icon="collection" style="color:#9f34a0" class="mr-3"></b-icon> <a href="#" @click.prevent="showEntireDivisionModal(nominee)"><i>Entire Division:</i> {{nominee.division}}</a></div>
                                </template>
                            </div>
                        </div>
                    </div>


                    <hr />

                    <div class="row mb-2">
                        <div class="col-lg-2 fldNameB">Motivation:</div>
                        <div class="col-lg-10 fldValueB">{{ nom.motivation }}</div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-lg-2 fldNameB">Action:</div>
                        <div class="col-lg-10 fldValueB">{{ nom.action }}</div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-lg-2 fldNameB">Impact:</div>
                        <div class="col-lg-10 fldValueB">{{ nom.impact}}</div>
                    </div>
                    <hr />

                    <!-- Review Form -->
                    <!--<section v-if="!nom.reviewIsDone" class="card card-default mt-3">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-7 font-weight-bold text-uppercase">REVIEW THIS NOMINATION:</div>
                            <div class="col-5 text-right"></div>
                        </div>
                    </div>
                    <div class="card-body">
                        <b-form @submit.prevent="reviewSubmit" novalidate>
                            <b-form-group id="groupIsDisciplinary" label-for="reviewIsDisciplinary" label="Is there currently any disciplinary action against the individual/s?" description="" :invalid-feedback="fReview.errors.reviewIsDisciplinary" :state="fReview.state.reviewIsDisciplinary">
                                <b-form-radio-group id="radiogroupIsDisciplinary" v-model="fReview.form.reviewIsDisciplinary" name="reviewIsDisciplinary" :state="fReview.state.reviewIsDisciplinary">
                                    <b-form-radio value="y">Yes</b-form-radio>
                                    <b-form-radio value="n">No</b-form-radio>
                                    <b-form-radio value="0">Not Applicable</b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>

                            <b-form-group id="groupIsSupported" label-for="reviewIsSupported" label="Do you support the nomination submitted?" description="" :invalid-feedback="fReview.errors.reviewIsSupported" :state="fReview.state.reviewIsSupported">
                                <b-form-radio-group id="radiogroupIsSupported" v-model="fReview.form.reviewIsSupported" name="reviewIsSupported" :state="fReview.state.reviewIsSupported">
                                    <b-form-radio value="true">Yes</b-form-radio>
                                    <b-form-radio value="false">No</b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>

                            <b-form-group id="groupReviewComment" label-for="txtReviewComment" label="Please provide details:" :invalid-feedback="fReview.errors.reviewComment" :description="1500-fReview.form.reviewComment.length + ' characters remaining'">
                                <b-form-textarea id="txtReviewComment" v-model="fReview.form.reviewComment" :state="fReview.state.reviewComment" placeholder="" rows="3" maxlength="1500" />
                            </b-form-group>

                            <div class="text-right"><b-button type="submit" variant="primary">Submit</b-button></div>
                        </b-form>
                    </div>
                </section>-->


                    <template v-if="false && nom.reviewIsDone">
                        <!-- Display Review -->
                        <section class="card card-default mt-3">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-7">REVIEW - By {{nom.reviewByMemberName}}</div>
                                    <div class="col-5 text-right">{{ nom.reviewDate.sjToDate('d MMM yyyy HH:mm') }}</div>
                                </div>
                            </div>
                            <div class="card-body">

                                <div class="row mb-3">
                                    <div class="col-lg-2 fldNameB">Disciplinary?</div>
                                    <div class="col-lg-1 fldValueB">{{ nom.reviewIsDisciplinary === 'y' ? 'Yes' : (nom.reviewIsDisciplinary === 'n' ? 'No' : 'n/a') }}</div>
                                    <div class="col-lg-9 font-italic">{{ nom.reviewIsDisciplinary === 'y' ? 'Disciplinary action has been taken against the individual/s' : (nom.reviewIsDisciplinary === 'n' ? 'Discipliniary action has not been taken against the individual/s' : '') }}</div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col-lg-2 fldNameB">Is Supported?</div>
                                    <div class="col-lg-1 fldValueB">{{ nom.reviewIsSupported ? 'Yes' : 'No' }}</div>
                                    <div class="col-lg-9 font-italic">{{ nom.reviewIsSupported ? 'Nomination is Supported' : 'Nomination is not Supported' }}</div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-2 fldNameB">Comments:</div>
                                    <div class="col-lg-10 fldValueB">{{ nom.reviewComment }}</div>
                                </div>

                            </div>
                        </section>

                        <!-- CA Only - Display ratings -->
                        <!-- CC only - Add Rating Form - (Display only if rating not already submitted). CA will have link to modal version of this form  -->

                        <section class="card card-default mt-3">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-7">NOMINATION RATINGS</div>
                                    <div class="col-5 text-right">{{ nom.ratingCount }} ratings submitted</div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-2 fldNameB">Average Rating:</div>
                                    <div class="col-lg-4">
                                        <b-form-rating v-if="nom.ratingCount !== 0" name="bformratingreadonly" v-model="nom.ratingAvg" style="padding:0;height:unset" color="#00b" stars="10" inline no-border precision="1" readonly show-value show-value-max />
                                        {{ nom.ratingCount === 0 ? '(no ratings submitted)' : '' }}
                                    </div>
                                </div>
                                <hr />

                                <template>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item" v-for="r in nomRatings" :key="r._rowNum">
                                            <b-card-sub-title>
                                                <div class="row">
                                                    <div class="col-7">{{ r.memberName }}</div>
                                                    <div class="col-2 text-right"><!-- {{ r.rating }} / 10--></div>

                                                    <div class="col-3 text-right">{{ r.ratingDate.sjToDate('d MMM yyyy HH:mm') }}</div>
                                                </div>
                                            </b-card-sub-title>
                                            <div class="small preline">{{ r.comment }}</div>
                                        </li>
                                    </ul>
                                </template>
                            </div>
                        </section>

                    </template>

                </div>
            </section>
        </template>









        <!-- Modal - View entire division -->
        <b-modal id="modalEntireDivisionMemberList" scrollable ok-only :title="'Entire Division: ' + entireDivisionName">
            <div v-if="entireDivisionMemberList.length == 0">Loading...</div>
            <div class="row" v-for="member in entireDivisionMemberList" :key="member._rowNum">
                <div class="col-1 text-right"><b-icon icon="person" style="color:#ccc"></b-icon></div>
                <div class="col">{{ member.firstname + ' ' + member.surname }}</div>
            </div>
        </b-modal>

        <!-- Modal - Add new rating -->
        <!-- Modal - Nominators -->

    </div>

</template>

<script>
    // @ is an alias to /src
    //import HelloWorld from '@/components/HelloWorld.vue'
    //import SitecodeImage from '@/components/SitecodeImage';
    import axios from 'axios'
    import sjData from '@/sjUtilData'
    //import MemberSelect from '@/components/MemberSelect';


    export default {
        name: 'AwardNominations',
        components: { },
        data() {
            return {
                displayPart: 0,
                fReview: {
                    form: { reviewIsDisciplinary: null, reviewIsSupported: null, reviewComment: '' }
                },
                fRating: {
                    form: { rating: null, comment: '' }
                },
                fConclude: {
                    form: { concludeDecision: null, concludeIsIncludeGroupCEO: null, achievements: '', motivation: '', actions: '', measures: '' }
                },
                selectedNominators: [],
                noms: [],
                nom: { nomId: '', dateCreated: '' },
                nominees: [],
                nomNominees: [],
                nomRatings: [],
                cachedEntireDivMemberList: [], //for displaying entire division member modal
                entireDivisionMemberList: [],  //for displaying entire division member modal
                entireDivisionName: '', //for displaying entire division member modal

                roles: { ca: false, cc: false, mng: false },
                filterNomCat: null,
                filterStatus: null,
                filterMonth: null,
                filterShowSel: false,

                evalRating: null,
                nomsSelected: [],

            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
            isAllowAccess() {
                let m = this.$store.state.memberStore.member;
				return m.role_nom && (m.role_ca || m.role_cc)
			},
            memberClusterCode() {
                return this.$store.state.memberStore.member.clusterCode
            },
            memberDivisionCode() {
                return this.$store.state.memberStore.member.divisionCode
            },
            nomCats() {
                return [...new Set(this.noms.map(n => n.nomCat))].sort()
            },
            nomMonths() {
                const result = [];
                const map = new Map();
                for (const n of this.noms) {
                    let d = new Date(n.dateCreated + 'Z');
                    let v = { value: { m: d.getMonth(), y: d.getFullYear(), d }, text: d.sjFormat('MMMM yyyy') };
                    let z = v.value.y * 1000 + v.value.m;
                    if (!map.has(z)) {
                        map.set(z, true);    // set any value to Map
                        result.push(v);
                    }
                }
                return result.sort((a, b) => ((1000 * a.value.y + a.value.m) - (1000 * b.value.y + b.value.m)));
            },

            nomsFiltered() {
                let fltdNoms = this.noms;
                let nomsSel = this.nomsSelected;
                if (this.filterShowSel) {
                    return fltdNoms.filter(n => nomsSel.find(e => e == n.nomId));
                }

                var fltrStatus = this.filterStatus;
                var fltrMonth = this.filterMonth;
                if (this.filterNomCat !== null) fltdNoms = fltdNoms.filter(n => n.nomCat == this.filterNomCat);
                if (fltrStatus !== null) fltdNoms = fltdNoms.filter(n =>
                    (fltrStatus === 'review' && !n.reviewIsDone)
                    || (fltrStatus === 'rate' && n.reviewIsDone && !n.ratingIsDone)
                    || (fltrStatus === 'rated' && n.reviewIsDone && n.ratingCount != 0)
                ); //review, rate, rated
                if (fltrMonth !== null) fltdNoms = fltdNoms.filter(n => {
                    let d = new Date(n.dateCreated + 'Z');
                    return d.getMonth() === fltrMonth.m && d.getFullYear() === fltrMonth.y;
                });

                return fltdNoms;
            }

        },
        created() {
            this.formSetState(this.fReview, true, true) //formObj, resetState, resetForm
            this.formSetState(this.fRating, true, true) //formObj, resetState, resetForm
            this.formSetState(this.fConclude, true, true) //formObj, resetState, resetForm
            this.$store
                .dispatch('getDivisionList', {})
                .then(() => {
                    //
                })
                .catch((error) => {
                    this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error)));
                });
        },
        mounted() {
            //if (!this.isAllowAccess) return;
            axios.post('/api/nom/nomlist', { 'isPub': true })
                .then(({ data }) => {
                    this.roles = data.roles;
                    this.noms = new sjData(data.noms).data;
                    this.displayPart = 1;
                })
                .catch((error) => {
                    if (error) {
                        this.msgBox('An unexpected error occured');
                        //this.msgBox(error.response.data.message);
                    }
                })
        },
        methods: {
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },
            resetAll() {
                this.nomsSelected = [];
                this.nom = { nomId: '', dateCreated: '' };
                this.nominees = [];
                this.nomNominees = [];
                this.nomRatings = [];
            },

            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },

            //*** Displays a modal of all members in a division  ***
            showEntireDivisionModal(nominee) {
                this.entireDivisionName = nominee.division;
                let divisionCode = nominee.divisionCode;
                this.$bvModal.show('modalEntireDivisionMemberList');
                this.entireDivisionMemberList = [];
                let axiosParams = null;
                if (divisionCode == null) { //no division selected
                    return;
                }

                let isEntireDivMemberListCached = typeof this.cachedEntireDivMemberList[divisionCode] !== 'undefined';
                if (isEntireDivMemberListCached) {
                    this.entireDivisionMemberList = this.cachedEntireDivMemberList[divisionCode];
                    return;
                }

				axiosParams = { countryCode: '', divisionCode: divisionCode, search: '', excludeCountryCode: '' }; //return all member data for current division
                axios.post('/api/site/getmemberlist', axiosParams)
                    .then(({ data }) => {
                        this.entireDivisionMemberList = new sjData(data.memberList).data;
                        this.cachedEntireDivMemberList[divisionCode] = this.entireDivisionMemberList
                    }
                    );

            },


            selectNom(rowNum) {
                axios.post('/api/nom/nomgetpub', { nomId: this.noms[rowNum].nomId })
                    .then(({ data }) => {
                        this.selectNom_Loaded(data);
                    })
                    .catch((error) => {
                        if (error) {
                            this.msgBox(error.response.data.message);
                        }
                    })
            },
            selectNom_Loaded(data) {
                this.formSetState(this.fReview, true, true);//formObj, resetState, resetForm
                this.formSetState(this.fRating, true, true);//formObj, resetState, resetForm
                this.nom = new sjData(data.nom).data[0];
                this.nomNominees = new sjData(data.nominees).data;
                //this.nomRatings = new sjData(data.ratings).data;
                // this.nomNominees = this.nominees.filter(n => n.nomId == this.nomId);
            },

            reviewSubmit() {
                this.formSetState(this.fReview, true);//formObj, resetState, resetForm

                this.fReview.form.nomId = this.nom.nomId;
                return axios
                    .post(
                        '/api/nom/reviewsubmit',
                        this.fReview.form
                    )
                    .then(({ data }) => {
                        this.noms = new sjData(data.noms).data;
                        this.selectNom_Loaded(data);
                        this.msgBox('Your review has been successfully submitted', 'Submit Review');

                    })
                    .catch((error) => {
                        if (error) {
                            this.msgBox(error.response.data.message);
                            if (error.response.data.formErrors) {
                                this.fReview.errors = error.response.data.formErrors;
                                this.formSetState(this.fReview);//formObj, resetState, resetForm
                            }
                        }
                    })
                    ;
            },

            ratingSubmit(isModal) {
                this.formSetState(this.fRating, true);//formObj, resetState, resetForm
                this.fRating.form.nomId = this.nom.nomId;
                return axios
                    .post(
                        '/api/nom/ratingsubmit',
                        this.fRating.form
                    )
                    .then(({ data }) => {
                        if (isModal) this.$bvModal.hide('modalRatingFormCA');
                        this.noms = new sjData(data.noms).data;
                        this.selectNom_Loaded(data);
                        this.msgBox('Your rating has been successfully submitted', 'Submit Rating');
                    })
                    .catch((error) => {
                        if (error) {
                            this.msgBox(error.response.data.message);
                            if (error.response.data.formErrors) {
                                this.fRating.errors = error.response.data.formErrors;
                                this.formSetState(this.fRating);
                            }
                        }
                    })
                    ;
            },
            showConclude() {
                if (this.nomsSelected.length === 0) {
                    this.msgBox('Please select nominations to conclude', 'No nominations have been selected');
                    return;
                }
                this.formSetState(this.fConclude, true, true);//formObj, resetState, resetForm
                this.displayPart = 2;
            },

            concludeSubmit() {
                this.formSetState(this.fConclude, true); //formObj, resetState, resetForm
                this.fConclude.form.nominations = this.nomsSelected;

                if (this.fConclude.form.concludeIsIncludeGroupCEO) {
                    let nominators = this.selectedNominators.filter(item => !item.isEntireDivision);
                    if (nominators.length !== 2) {
                        this.msgBox('Please select two nominators to support your nomination.', 'Two nominators are required');
                        return;
                    }
                    this.fConclude.form.nominatorMemberId1 = nominators[0].memberId;
                    this.fConclude.form.nominatorMemberId2 = nominators[1].memberId;
                } else {
					this.fConclude.form.achievements = '';
					this.fConclude.form.actions = '';
					this.fConclude.form.measures = '';
					this.fConclude.form.motivation = '';
                    this.fConclude.form.nominatorMemberId1 = null;
                    this.fConclude.form.nominatorMemberId2 = null;
                }

                return axios
                    .post(
                        '/api/nom/concludesubmit',
                        this.fConclude.form
                    )
                    .then(({ data }) => {
                        //this.selectNom_Loaded(data);

                        this.resetAll();
                        this.noms = new sjData(data.noms).data;
                        this.displayPart = 1;
                        this.msgBox('Nominations concluded successfully', 'Nominations Concluded');
                    })
                    .catch((error) => {
                        if (error) {
                            console.log(error);
                            this.msgBox(error.response.data.message);

                            if (error.response.data.formErrors) {
                                this.fConclude.errors = error.response.data.formErrors;
                                this.formSetState(this.fConclude);//formObj, resetState, resetForm
                            }
                        }
                    })
                    ;
            },
            selectAll(isChecked) {
                //console.log(v);
                if (isChecked) {
                    let sel = [];
                    this.noms.forEach(n => sel.push(n.nomId));
                    this.nomsSelected = sel;
                } else {
                    this.nomsSelected = [];
                }
            }
            

        }

    }
</script>
