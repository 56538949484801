<template>
	<div id="nav" class="topHeadNav">
		<div class="container">
			<b-navbar toggleable="xl">
				<!-- <b-navbar-brand href="#">NavBar</b-navbar-brand> -->

				<b-navbar-toggle class="ml-auto my-1" target="nav-collapse"></b-navbar-toggle>

				<b-collapse id="nav-collapse" is-nav>
					<b-navbar-nav v-if="isLoggedIn">
						<b-nav-item to="/">Home</b-nav-item>
						<b-nav-item to="/myprofile">My Profile</b-nav-item>
						<b-nav-item to="/howitworks">How it Works</b-nav-item>
						<b-nav-item to="/rules">Rules</b-nav-item>

						<b-nav-item-dropdown v-if="isShowRedeem || isShowPeerToPeer" text="My Rewards" right>
							<b-dropdown-item v-if="isShowRedeem" to="/rewards/basket">My Basket</b-dropdown-item>
							<b-dropdown-item v-if="isShowRedeem" to="/orders">My Orders</b-dropdown-item>
							<b-dropdown-item v-if="isShowRedeem || isShowPeerToPeer" to="/statement">Statement</b-dropdown-item>
							<b-dropdown-item v-if="isShowPeerToPeer" to="/payitforwardstatement">Peer to Peer Statement</b-dropdown-item>
						</b-nav-item-dropdown>

						<b-nav-item-dropdown v-if="(isManager || isClusterAdmin || isClusterComm || isAdminReports || isRole_rep) && divisionCode !== 'sra_2104'" text="Management" right>
							<b-dropdown-item v-if="isManager || isClusterAdmin " to="/managementstatement">Management Statement</b-dropdown-item>
							<b-dropdown-item v-if="isRole_adh || isClusterAdmin " to="/managementstatementadhoc">Adhoc Statement</b-dropdown-item>

							<b-dropdown-item v-if="isManager || isAdminReports || isRole_rep" to="/reportmanagement">Management Report</b-dropdown-item>

							<b-dropdown-item v-if="isShowManagerReviewNom || isClusterAdmin || isClusterComm" to="/awardnominationsadmin">Review Nominations</b-dropdown-item>

							<b-dropdown-item v-if="isRole_ceo" to="/groupceoawardadmin">Group CEO Award Admin</b-dropdown-item>

							<b-dropdown-item v-if="isAdminReports && clusterCode=='go' " to="/reporthumancapitalaward">Human Capital Award Report</b-dropdown-item>
							<b-dropdown-item v-if="isAdminReports && clusterCode=='stm'" to="/reportsantamhumancapitalaward">Santam Human Capital Award Report</b-dropdown-item>

							<b-dropdown-item v-if="isRole_adh" to="/adhocrecognition">Adhoc Recognition</b-dropdown-item>

							<b-dropdown-item v-if="isAdminReports" to="/reportoffboardingbalances">Off Boarding Report</b-dropdown-item>

							<b-dropdown-item v-if="isClusterAdmin || isAdminReports || isRole_rep" to="/reportnoparticipationmanagers">No Participation Report (Managers)</b-dropdown-item>

							<b-dropdown-item v-if="isClusterAdmin && clusterCode=='stm'" to="/reportceoaward">Santam Cluster CEO Award Report</b-dropdown-item>
							<b-dropdown-item v-if="(isRole_inv || isClusterAdmin) && clusterCode=='stm' && (divisionCode == 'stm_3930' || divisionCode == 'stm_4185')" to="/reportsbsaward">Santam Broker Solutions Award Report</b-dropdown-item>
							<b-dropdown-item v-if="(isRole_inv || isClusterAdmin) && clusterCode=='stm' && divisionCode == 'stm_3869'" to="/reportsssaward">Santam Specialised Solutions Award Report</b-dropdown-item>

						</b-nav-item-dropdown>

						<b-nav-item to="/contactus">Contact Us</b-nav-item>

					</b-navbar-nav>

					<b-navbar-nav v-if="isLoggedIn" class="ml-auto">
						<b-nav-item to="/logout" class="navLogout">Log Out</b-nav-item>
					</b-navbar-nav>
					<b-navbar-nav v-if="false" class="ml-auto">
						<b-nav-item to="/login" class="navLogout">Log In</b-nav-item>
					</b-navbar-nav>

					<!-- Right aligned nav items -->
					<!--
			<b-navbar-nav class="ml-auto">
				<b-nav-form>
					<b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
					<b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
				</b-nav-form>


				<b-nav-item-dropdown right>
					!-- Using 'button-content' slot --
					<template v-slot:button-content>
						<em>User</em>
					</template>
					<b-dropdown-item href="#">Profile</b-dropdown-item>
					<b-dropdown-item href="#">Sign Out</b-dropdown-item>
				</b-nav-item-dropdown>
			</b-navbar-nav>
			-->
				</b-collapse>
			</b-navbar>
		</div>
	</div>
</template>

<script>
	export default {
		computed: {
			isLoggedIn() {
				return this.$store.state.isLoggedIn
			},
			isManager() {
				return this.$store.state.memberStore.member.isManager
			},
			
			clusterCode() {
				return this.$store.state.memberStore.member.clusterCode
			},
			divisionCode() {
				return this.$store.state.memberStore.member.divisionCode
			},
			isClusterAdmin() {
				return this.$store.state.memberStore.member.role_ca
			},
			isClusterComm() {
				return this.$store.state.memberStore.member.role_cc
			},
			isRole_rep() {
				return this.$store.state.memberStore.member.role_rep
			},
            isRole_inv() {
                return this.$store.state.memberStore.member.role_inv
            },
			isRole_ceo() {
				return this.$store.state.memberStore.member.role_ceo
			},
			isRole_adh() {
				return this.$store.state.memberStore.member.role_adh
			},
			isAdminReports() {
				return this.$store.state.memberStore.member.isAdminReports;
			},
            isShowRedeem() {
                let divisionCode = this.divisionCode;
                let clusterCode = this.clusterCode;
                if ((clusterCode === "sky" && this.isManager) || divisionCode === 'sra_2104' || divisionCode === 'sra_4181') {
                    return false; //All except Sky non managers, SanPay and Sanpay Managers
                }
                return true;
            },
            isShowPeerToPeer() {
                let divisionCode = this.divisionCode;
                let clusterCode = this.clusterCode;
                if ((clusterCode === "sky" && this.isManager) || divisionCode === 'sra_2104' || divisionCode === 'sra_4181') {
                    return false; //All except Sky non managers, SanPay and Sanpay Managers
                }
                return true;
            },
			isShowManagerReviewNom() {
				//Only these managers are able to review nominations
				let clusterCode = this.clusterCode;
				return this.isManager && (
					clusterCode == "sra"
						|| clusterCode == "sf"
						|| clusterCode == "srm"
						|| clusterCode == "saf"
						|| clusterCode == "go"
						|| clusterCode == "sig"
					);
			}
		}
	}
</script>