<template v-if="isLoggedIn">
    <div class="container-fluid page" v-if="member.clusterCode=='stm'">

        <div class="card card-default" style="min-width:420px">
            <div class="card-header">
                <div class="row">
                    <div class="col-sm-6 font-weight-bold">
                        <h5>CEO Award Report</h5>
                    </div>

                    <div class="col-sm-3">

                    </div>
                    <div class="col-sm-3 text-right">
                        <b-button class="d-print-none mr-3" size="sm" @click.prevent="windowPrint" :disabled="report.length==0">Print</b-button>
                        <b-dropdown class="d-print-none mr-3" text="Export to CSV">
                            <b-dropdown-item href="#" @click.prevent="exportToCSV('utf', false)">Standard</b-dropdown-item>
                            <b-dropdown-item href="#" @click.prevent="exportToCSV('utf', true)">Alternate</b-dropdown-item>
                            <b-dropdown-item href="#" @click.prevent="exportToCSV('ascii', false)">ASCII</b-dropdown-item>
                            <b-dropdown-item href="#" @click.prevent="exportToCSV('ascii', true)">ASCII Alternate</b-dropdown-item>
                            <b-dropdown-item href="#" @click.prevent="exportToCSV('compat', false)">Compatibility</b-dropdown-item>
                            <b-dropdown-item href="#" @click.prevent="exportToCSV('compat', true)">Compatibility Alternate</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>

            </div>
            <div class="card-body d-print-none">
                <div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-md-2 col-lg-1 control-label">From Date:</label>
                        <div class="col-sm-3 col-md-4 col-lg-3">
                            <b-input-group size="sm">
                                <b-input-group-prepend is-text>
                                    <b-icon icon="calendar"></b-icon>
                                </b-input-group-prepend>
                                <b-form-select :options="monthList" v-model="selMonthFrom">
                                    <!--<template v-slot:first>
                                    <b-form-select-option :value="null">-- All Periods --</b-form-select-option>
                                </template>-->
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <label class="col-sm-3 col-md-2 col-lg-1 control-label">To Date:</label>
                        <div class="col-sm-3 col-md-4 col-lg-3">
                            <b-input-group size="sm">
                                <b-input-group-prepend is-text>
                                    <b-icon icon="calendar"></b-icon>
                                </b-input-group-prepend>
                                <b-form-select :options="monthList" v-model="selMonthTo">
                                    <!--<template v-slot:first>
                                    <b-form-select-option :value="null">-- All Periods --</b-form-select-option>
                                </template>-->
                                </b-form-select>
                            </b-input-group>
                        </div>
                    </div>
                    <!--<div class="form-group row">
                        <label class="col-sm-3 col-md-2 col-lg-1 control-label">Status:</label>
                        <div class="col-sm-7 col-md-7">
                            <b-form-radio-group id="selAwardStatus" v-model="awardStatus">
                                <b-form-radio value="W">Winners</b-form-radio>
                                <b-form-radio value="R">Runner-Ups</b-form-radio>
                                <b-form-radio value="C">Concluded</b-form-radio>

                                <b-form-radio value="N">Awaiting Decision</b-form-radio>
                            </b-form-radio-group>
                        </div>
                        <div class="col-sm-2 col-md-3">

                        </div>
                    </div>-->
                    <div class="row d-print-none">
                        <div class="col-sm-3 col-md-2 col-lg-1"></div>
                        <div class="col-sm-7 col-md-7">
                            <b-button @click="loadReport()" variant="primary">Load Report</b-button>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div v-if="hasRunOnce && !isLoading" style="font-size:0.8rem">
            <div v-for="f in report" :key="f._rowNum" class="mb-2 mt-3" style="page-break-inside:avoid;page-break-before:always;display:block">
                <div class="card card-default">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-6" style="font-size:1.2rem">Nomination for: <b>{{ f.awardCat }}</b></div>
                            <div class="col-md-6 text-right" style=""><b>Ref:</b> {{ f.ceoawardId }}</div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row mb-3">
                            <div class="col-md-2 fldNameC">Status:</div>
                            <div class="col-md-10">{{ f.awardStatusDesc}}<br />{{ f.refInfo }}</div>
                        </div>

                        <div class="row mb-1">
                            <div class="col-md-2 fldNameC">Nominated By:</div>
                            <div class="col-md-10">{{ f.nominatedByMember }}</div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-2 fldNameC">Date:</div>
                            <div class="col-md-10 fldValueC">{{ (f.nominatedDate) ? f.nominatedDate.sjToDate('d MMM yyyy') : '' }} </div>
                        </div>

                        <div class="mb-3">
                            <div class="fldNameC">Nominees:</div>
                            <div class="fldValueC">{{ (f.nominees) ? f.nominees.sjReplaceAll(';','\r\n') : '' }}</div>
                        </div>
                        <div class="mb-3">
                            <div class="fldNameC">Motivation:</div>
                            <div class="fldValueC">{{ f.motivation }}</div>
                        </div>
                        <div class="mb-3">
                            <div class="fldNameC">Action:</div>
                            <div class="fldValueC">{{ f.actions }}</div>
                        </div>
                        <div class="mb-3">
                            <div class="fldNameC">Measures:</div>
                            <div class="fldValueC">{{ f.measures }}</div>
                        </div>
                        <!--<div class="mb-3">
                            <div class="fldNameC">How does the nominated individual (and/or team) achievement/s support the Group business strategy?</div>
                            <div class="fldValueC">{{ f.achievements }}</div>
                        </div>-->
                        <!--<div class="mb-3">
                            <div class="fldNameC">Seconded By:</div>
                            <div class="fldValueC">
                                <div>{{ f.nominatorMember1 }}</div>
                                <div>{{ f.nominatorMember2 }}</div>
                            </div>
                        </div>-->

                    </div>
                </div>
            </div>


        </div>

        <!--
    <table v-if="hasRunOnce && !isLoading" class="table table-hover table-condensed" style="font-size:0.8rem">
        <tr>
            <th>Nomination</th>
            <th>Motivation/Actions/Measures/Achievements</th>
            <th>Nominated By</th>
            <th>Nominees</th>
        </tr>

        <tr v-for="f in report" :key="f._rowNum">
            <td>
                <div><b>Ref: {{ f.ceoawardId }}</b></div>
                <div>{{ f.nominatedDate.sjToDate('d MMM yyyy HH:mm') }}</div>
                <div>{{ f.refInfo }}</div>
                <div class="mt-2"><b>{{ f.awardCat }}</b></div>
                <div class="mt-2"><b>{{ f.awardStatusDesc }}</b></div>

            </td>
            <td class="preline">
                <div><b>Motivation:</b></div>
                <div>{{ f.motivation }}</div>
                <div><b>Actions:</b></div>
                <div>{{ f.actions }}</div>
                <div><b>Measures:</b></div>
                <div>{{ f.measures}}</div>
                <div><b>Achievements:</b></div>
                <div>{{ f.achievements }}</div>
            </td>
            <td>
                <div><b>Nominated By:</b></div>
                <div>{{ f.nominatedByMember }}</div>
                <div><b>Seconded By:</b></div>
                <div>{{ f.nominatorMember1 }}</div>
                <div>{{ f.nominatorMember2 }}</div>
            </td>
            <td class="preline">{{ f.nominees.sjReplaceAll(';','\r\n') }}</td>
        </tr>
    </table>
    -->
        <div v-if="isLoading"><i>Loading...</i></div>
        <div v-if="hasRunOnce && !isLoading && report.length==0"><i>No Results</i></div>

    </div>

   
</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    import sjData from '@/sjUtilData'


    export default {
        name: 'ReportCEOAward',
        data() {
            return {
                monthList: [],
                selMonthFrom: null,
                selMonthTo: null,
                awardStatus: 'N',
                report: [],
                isLoading: false,
                hasRunOnce: false
            }
        },
        computed: {
            member() {
                return this.$store.state.memberStore.member
            },

            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
        },

        created() {
            let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const result = [];
            let curYear = new Date().getFullYear();
            let curMonth = new Date().getMonth();

            for (let y = curYear; y >= curYear - 3; y--) {
                let mStart = y == curYear ? curMonth : 11;
                for (let m = mStart; m >= 0; m--) {
                    let t = months[m] + ' ' + y;
                    if (result.length === 0) this.selMonthFrom = t;
                    if (result.length === 0) this.selMonthTo = t;
                    result.push({ value: t, text: t });
                }
            }
            this.monthList = result;
            //this.loadReport();
        },
        mounted() {
            //axios.post('/api/member/memberget', {})
            //	.then(({ data }) => {
            //		//this.roles = data.roles;
            //		//this.noms = new sjData(data.noms).data;
            //		//this.displayPart = 1;
            //	})
            //	.catch((error) => {
            //		if (error) {
            //			this.msgBox(error.response.data.message);
            //		}
            //	})
        },
        watch: {
            //selMonth: function () {
            //    if (this.selMonth !== null) this.loadReport();
            //},
            //awardStatus: function () {
            //    if (this.awardStatus !== null) this.loadReport();
            //}
        },

        methods: {
            loadReport() {
                this.isLoading = true;
                this.report = [];
                this.awardStatus = 'N';// Santam CEO Award - no system intervention
				axios.post('/api/groupceoaward/report', { isGroupCeoAward: false, selMonthFrom: this.selMonthFrom, selMonthTo: this.selMonthTo, awardStatus: this.awardStatus })
                    .then(({ data }) => {
                        this.isLoading = false;
                        this.hasRunOnce = true;
                        this.report = new sjData(data.report).data;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        if (error) {
                            this.msgBox(error.response.data.message);
                        }
                    })
            },
			exportToCSV(mode, isAlt) {
				//All: line breaks in cell are only \n 0A

				//isAlt False (Windows) - end of row: \r\n 0D 0A
				//isAlt True (Mac) - end of row: \r 0D

				//mode: utf - file prefix: EF BB BF
				//mode: ascii - file prefix: none
				//mode: compat - replace all line breaks in cell with - (dash)

				const cellLineBreak = mode === "compat" ? "-" : "\n";
				const rowLineBreak = isAlt ? "\r" : "\r\n";
				const filePrefix = mode === "utf" ? "\uFEFF" : "";  // nb: "\uFEFF" generates byte order mark "\xEF\xBB\xBF"
				const isAscii = mode === "ascii";
				const asciiReplaceChar = " "; //"\u00B7";

				let fx = function (val) {
					try {
						let s = (val + "").trim(); //https://blog.zsec.uk/csv-dangers-mitigations/
						while (s.length > 1 && s.indexOf("=") == 0 || s.indexOf("+") == 0 || s.indexOf("@") == 0 || (!isNaN(s) && s.indexOf("-") == 0)) {
							s = s.substr(1).trim();
						}

						if (s.indexOf("@") > -1 || s.indexOf("+") > -1 || s.indexOf("-") > -1 || s.indexOf("=") > -1 || s.indexOf("|") > -1 || s.indexOf("%") > -1) {
							s = "'" + s.sjReplaceAll("|", "\\|") + "'";
						}

						if (s.indexOf("\"") > -1 || s.indexOf("\r") > -1 || s.indexOf("\n") > -1 || s.indexOf(",") > -1) {
							if (isAscii) s = s
								.sjReplaceAll("U+201C", '"')
								.sjReplaceAll("U+201D", '"')
								.sjReplaceAll("U+2018", "'").sjReplaceAll("�", "'")
								.sjReplaceAll("U+2019", "'")
								.replace(/[\u{00FF}-\u{FFFF}]/gu, asciiReplaceChar);
							s = "\"" + s.sjReplaceAll("\r\n", cellLineBreak).sjReplaceAll("\r", cellLineBreak).sjReplaceAll("\n", cellLineBreak) + "\"";

						}
						return s;
					} catch {
						return 'Error';
					}
				}

				let csv = filePrefix + 'Ref,Date,Award Category,Motivation,Actions,Measures,Achievements,Nominated By,Status,Info,Nominees\r\n';

                for (var n = 0; n < this.report.length; n++) {
                    let f = this.report[n];
                    //debugger;
					csv += `${f.ceoawardId},${fx(f.nominatedDate.sjToDate('d MMM yyyy HH:mm'))},${fx(f.awardCat)},${fx(f.motivation)},${fx(f.actions)},${fx(f.measures)},${fx(f.achievements)},${fx(f.nominatedByMember)},${fx(f.awardStatusDesc)},${fx(f.refInfo)},${fx(f.nominees)}${rowLineBreak}`;
                }


                var pom = document.createElement('a');
                var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                var url = URL.createObjectURL(blob);
                pom.href = url;
                pom.setAttribute('download', 'report.csv');
                pom.click();
            },
            windowPrint() {
                window.print();
            },
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            }


        }

    }
</script>
